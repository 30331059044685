import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import feather from "feather-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFilter from "../../utils/Hooks/useFilter";
import {
  deleteExclusion,
  getExclusion,
  insertExclusion,
  updateExclusion,
} from "../../redux/actionCreator";
import {
  exclusion_data,
  exclusion_loading,
  exclusion_page_details,
} from "../../redux/commonReducer";
import { Modal } from "react-bootstrap";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Actions from "../../commonComponents/Actions/Actions";
import Table from "../../commonComponents/Table/Table";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

export default function ManageExclusions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector(exclusion_data);
  const pageData = useSelector(exclusion_page_details);
  const loading = useSelector(exclusion_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [open, setOpen] = useState(false);
  const [exclusionData, setExclusionData] = useState({
    exclusion_name: "",
    id: "",
  });
  const [err, setErr] = useState({});

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    getSearchDataHandler(initialFilter);
  }, []);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(getExclusion(formData));
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteExclusion(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getExclusion(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const onChangeHandler = (key, value) => {
    setExclusionData({
      ...exclusionData,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!exclusionData?.exclusion_name?.trim()?.length) {
      temp = {
        ...temp,
        exclusion_name: "Please enter exclusion name",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        exclusion_name: exclusionData?.exclusion_name?.trim(),
      };
      if (exclusionData?.id != "") {
        raw = {
          ...raw,
          id: exclusionData?.id,
        };
        dispatch(
          updateExclusion(raw, (res) => {
            if (res) {
              onCancelHandler();
              getSearchDataHandler(filterData);
            }
          })
        );
      } else {
        dispatch(
          insertExclusion(raw, (res) => {
            if (res) {
              onCancelHandler();
              getSearchDataHandler(filterData);
            }
          })
        );
      }
    }
  };

  const editHandler = (ele) => {
    setExclusionData({
      exclusion_name: ele?.exclusion_name,
      id: ele?._id,
    });
    setOpen(true);
  };

  const onCancelHandler = () => {
    setExclusionData({
      exclusion_name: "",
      id: "",
    });
    setOpen(false);
  };

  return (
    <div className="row">
      <div className="col">
        {open && (
          <div className="card">
            <div className="card-body">
              <div className="table__formAdd">
                <div className="col-md-12">
                  <label className="form-label">Exclusion Name <em>*</em></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("exclusion_name", e?.target?.value)
                    }
                    value={exclusionData?.exclusion_name}
                  />
                  <small id="error" className="error">
                    {err?.exclusion_name}
                  </small>
                </div>
                <div className="actions">
                  <button
                    className="btn btn-primary"
                    onClick={() => onSubmitHandler()}
                  >
                    {exclusionData?.id ? "Update" : "Add"}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title ">Manage Exclusion</h5>
              <button
                className="btn btn-primary"
                onClick={() => setOpen(!open)}
              >
                {open ? "Back To Manage" : "Add Exclusion"}
              </button>
            </div>
            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Here"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "50px",
                  },
                  {
                    title: "Exclusion Name",
                    key: "exclusion_name",
                    sort: true,
                    width: "750px",
                  },

                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  exclusion_name: item?.exclusion_name,
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() => editHandler(item)}
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Exclusions"}
      />
    </div>
  );
}
