import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateRedux } from "./commonReducer";

export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}
export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loading", value: true }));
  axios
    .post(`${API_BASE_URL}login`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      if (res.data.status === true) {
        if (res.data.customPermissions) {
          localStorage.setItem(
            "permission",
            JSON.stringify(res.data.customPermissions)
          );
        }
        if (res.data.profile_image) {
          localStorage.setItem("profile_image", res.data.profile_image);
        }
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("csrf", res.data.csrfToken);
        if (res.data.role) {
          localStorage.setItem("role", res.data.role.handle_key);
        }
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("user_id", res.data._id);

        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(
          res.data?.message ?? "Please check your email or password",
          {
            position: "bottom-center",
            autoClose: 3000,
          }
        );
        if (callback) {
          callback(res.data.status);
        }
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      console.log(err);
    });
};

export const logout = (callback) => (dispatch) => {
  dispatch(updateRedux({ key: "logout_loader", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}logout`)
    .then((res) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      if (res.data.status) {
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      console.log(err);
    });
};

export const insertSeason = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertSeason`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const getSeason = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "season_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getSeason`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "season_loading", value: false }));
        dispatch(updateRedux({ key: "season_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "season_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "season_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "season_loading", value: false }));
      console.log(err);
    });
};

export const deleteSeason = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteSeason/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const getSeasonDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_season_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getSeasonDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_season_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "season_details", value: res.data.data }));
        dispatch(updateRedux({ key: "season_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_season_loading", value: false }));
      console.log(err);
    });
};

export const updateSeason = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateSeason`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const insertAttraction = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertAttraction`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const getAttraction = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "attraction_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getAttraction`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "attraction_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "attraction_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "attraction_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "attraction_loading", value: false }));
      console.log(err);
    });
};

export const deleteAttraction = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteAttraction/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const getAttractionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_attraction_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getAttractionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_attraction_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "attraction_details", value: res.data.data })
        );
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_attraction_loading", value: false }));
      console.log(err);
    });
};

export const updateAttraction = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateAttraction`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const insertActivity = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertActivity`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const getActivity = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "activity_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getActivity`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "activity_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "activity_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "activity_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "activity_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "activity_loading", value: false }));
      console.log(err);
    });
};

export const deleteActivity = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteActivity/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const getActivityDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_activity_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getActivityDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_activity_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "activity_details", value: res.data.data })
        );
        dispatch(updateRedux({ key: "activity_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_activity_loading", value: false }));
      console.log(err);
    });
};

export const updateActivity = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateActivity`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const insertInclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertInclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const getInclusion = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "inclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getInclusion`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "inclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "inclusion_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "inclusion_page_details", value: res.data.page })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "inclusion_loading", value: false }));
      console.log(err);
    });
};

export const deleteInclusion = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteInclusion/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const getInclusionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_inclusion_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getInclusionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_inclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "inclusion_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const updateInclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateInclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const insertExclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertExclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const getExclusion = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "exclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getExclusion`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "exclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "exclusion_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "exclusion_page_details", value: res.data.page })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "exclusion_loading", value: false }));
      console.log(err);
    });
};

export const deleteExclusion = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteExclusion/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const getExclusionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_exclusion_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getExclusionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_exclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "exclusion_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const updateExclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateExclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const insertEssentialInfo = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertEssentialInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const getEssentialInfo = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getEssentialInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "faq_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "faq_page_details", value: res.data.page })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "faq_loading", value: false }));
      console.log(err);
    });
};

export const deleteEssentialInfo = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteEssentialInfo/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const getEssentialInfoDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_faq_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getEssentialInfoDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_details", value: res.data.data }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      console.log(err);
    });
};

export const updateEssentialInfo = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateEssentialInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const insertQuestionnaire = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertQuestionnaire`, data)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      console.log(err);
    });
};

export const getQuestionnaire = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "questionnaire_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getQuestionnaire`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "questionnaire_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "questionnaire_data", value: res.data.data })
        );
        dispatch(
          updateRedux({
            key: "questionnaire_page_details",
            value: res.data.page,
          })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "questionnaire_loading", value: false }));
      console.log(err);
    });
};

export const deleteQuestionnaire = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteQuestionnaire/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      console.log(err);
    });
};

export const getQuestionnaireDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_questionnaire_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getQuestionnaireDetail/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_questionnaire_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "questionnaire_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_questionnaire_loading", value: false })
      );
      console.log(err);
    });
};

export const updateQuestionnaire = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateQuestionnaire`, data)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_questionnaire_loading", value: false })
      );
      console.log(err);
    });
};

export const insertHotelExp = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelExp`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      console.log(err);
    });
};

export const getHotelExpDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_hotel_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getHotelExpDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_hotel_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "hotel_details", value: res.data.data }));
        dispatch(updateRedux({ key: "hotel_path", value: res.data.path }));
        dispatch(
          updateRedux({
            key: "hotel_partners_path",
            value: res.data.partnersPath,
          })
        );
        dispatch(
          updateRedux({
            key: "hotel_gallery_path",
            value: res.data.galleryPath,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_hotel_loading", value: false }));
      console.log(err);
    });
};

export const updateHotelExp = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateHotelExp`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      console.log(err);
    });
};

export const insertRoomCosting = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_room_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertRoomCosting`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_room_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_room_loading", value: false }));
      console.log(err);
    });
};

export const getRoomCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_room_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getRoomCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_room_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "room_details", value: res.data.data }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_room_loading", value: false }));
      console.log(err);
    });
};

export const insertVehicleCosting = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_costing_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertVehicleCosting`, data)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_vehicle_costing_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_vehicle_costing_loading", value: false })
      );
      console.log(err);
    });
};

export const getVehicleCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_vehicle_costing_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getVehicleCostDetail`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_vehicle_costing_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "vehicle_costing_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_vehicle_costing_loading", value: false })
      );
      console.log(err);
    });
};

export const insertChildCost = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_child_cost_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertChildCost`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_child_cost_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_child_cost_loading", value: false }));
      console.log(err);
    });
};

export const getChildCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_child_cost_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getChildCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_child_cost_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "child_cost_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_child_cost_loading", value: false }));
      console.log(err);
    });
};

export const insertFlightCost = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_cost_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertFlightCost`, data)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_flight_cost_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_flight_cost_loading", value: false })
      );
      console.log(err);
    });
};

export const getFlightCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_flight_cost_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getFlightCostDetail`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_flight_cost_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "flight_cost_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_flight_cost_loading", value: false })
      );
      console.log(err);
    });
};

export const insertMilestone = (formData, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_payment_milestone_loading", value: true })
  );
  axiosInstance
    .post(`${API_BASE_URL}insertMilestone`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      console.log(err);
    });
};

export const getMilestone = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "payment_milestone_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getMilestone`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "payment_milestone_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "payment_milestone_data", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "payment_milestone_total", value: res.data.total })
        );
        dispatch(
          updateRedux({
            key: "payment_milestone_page_details",
            value: res.data.page,
          })
        );
      } else {
        dispatch(updateRedux({ key: "payment_milestone_data", value: [] }));
        dispatch(
          updateRedux({ key: "payment_milestone_total", value: undefined })
        );
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "payment_milestone_loading", value: false }));
      console.log(err);
    });
};

export const deleteMilestone = (id, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_payment_milestone_loading", value: true })
  );
  axiosInstance
    .delete(`${API_BASE_URL}deleteMilestone/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      console.log(err);
    });
};

export const getMilestoneDetail = (id) => (dispatch) => {
  dispatch(
    updateRedux({ key: "detail_payment_milestone_loading", value: true })
  );
  axiosInstance
    .get(`${API_BASE_URL}getMilestoneDetail/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_payment_milestone_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({
            key: "payment_milestone_details",
            value: res.data.data,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_payment_milestone_loading", value: false })
      );
      console.log(err);
    });
};

export const updateMilestone = (formData, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_payment_milestone_loading", value: true })
  );
  axiosInstance
    .put(`${API_BASE_URL}updateMilestone`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_payment_milestone_loading", value: false })
      );
      console.log(err);
    });
};

export const insertCurrency = (formData, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_currencyConversion_loading", value: true })
  );
  axiosInstance
    .post(`${API_BASE_URL}insertCurrency`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      console.log(err);
    });
};

export const getCurrency = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "currency_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getCurrency`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "currency_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "currencyConversion_data", value: res.data.data })
        );
        dispatch(
          updateRedux({
            key: "currencyConversion_page_details",
            value: res.data.page,
          })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "currency_loading", value: false }));
      console.log(err);
    });
};

export const deleteCurrency = (id, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_currencyConversion_loading", value: true })
  );
  axiosInstance
    .delete(`${API_BASE_URL}deleteCurrency/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      console.log(err);
    });
};

export const getCurrencyDetail = (id) => (dispatch) => {
  dispatch(
    updateRedux({ key: "detail_currencyConversion_loading", value: true })
  );
  axiosInstance
    .get(`${API_BASE_URL}getCurrencyDetail/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_currencyConversion_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({
            key: "currencyConversion_details",
            value: res.data.data,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_currencyConversion_loading", value: false })
      );
      console.log(err);
    });
};

export const updateCurrency = (formData, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_currencyConversion_loading", value: true })
  );
  axiosInstance
    .put(`${API_BASE_URL}updateCurrency`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_currencyConversion_loading", value: false })
      );
      console.log(err);
    });
};

export const insertFlight = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertFlight`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const getFlight = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "flight_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getFlight`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "flight_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "flight_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "flight_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "flight_loading", value: false }));
      console.log(err);
    });
};

export const deleteFlight = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteFlight/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const getFlightDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_flight_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getFlightDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_flight_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_details", value: res.data.data }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_flight_loading", value: false }));
      console.log(err);
    });
};

export const updateFlight = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateFlight`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const uploadFile = (formData, callback, progress) => (dispatch) => {
  dispatch(updateRedux({ key: "fileUploadLoader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}uploadFile`, formData, {
      onUploadProgress: (e) =>
        progress && progress(Math.round(e.progress * 100)),
    })
    .then((res) => {
      dispatch(updateRedux({ key: "fileUploadLoader", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertVehicleInfo = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertVehicleInfo`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const getVehicleInfo = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "vehicle_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getVehicleInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "vehicle_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "vehicle_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "vehicle_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "vehicle_loading", value: false }));
      console.log(err);
    });
};

export const deleteVehicleInfo = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteVehicleInfo/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const getVehicleInfoDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_vehicle_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getVehicleInfoDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_vehicle_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_details", value: res.data.data }));
        dispatch(updateRedux({ key: "vehicle_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const updateVehicleInfo = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateVehicleInfo`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const insertVehiclePartners = (formData, callback) => (dispatch) => {
  dispatch(
    updateRedux({ key: "update_vehicle_partners_loading", value: true })
  );
  axiosInstance
    .post(`${API_BASE_URL}insertVehiclePartners`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_vehicle_partners_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_vehicle_partners_loading", value: false })
      );
      console.log(err);
    });
};

export const getPartnersDetail = () => (dispatch) => {
  dispatch(
    updateRedux({ key: "detail_vehicle_partners_loading", value: true })
  );
  axiosInstance
    .get(`${API_BASE_URL}getPartnersDetail`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_vehicle_partners_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "vehicle_partners_details", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "vehicle_partners_path", value: res.data.path })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_vehicle_partners_loading", value: false })
      );
      console.log(err);
    });
};

export const insertHotelPartners = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_partners_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelPartners`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_hotel_partners_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_hotel_partners_loading", value: false })
      );
      console.log(err);
    });
};

export const insertHotelGallery = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_gallery_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelGallery`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_hotel_gallery_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_hotel_gallery_loading", value: false })
      );
      console.log(err);
    });
};

export const insertCurated = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_curated_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertCurated`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      console.log(err);
    });
};

export const getCuratedDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_curated_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getCuratedDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_curated_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "curated_details", value: res.data.data }));
        dispatch(updateRedux({ key: "curated_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_curated_loading", value: false }));
      console.log(err);
    });
};

export const updateCurated = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_curated_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateCurated`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      console.log(err);
    });
};

export const insertAbout = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_about_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertAbout`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      console.log(err);
    });
};

export const getAboutDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_about_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getAboutDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_about_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "about_details", value: res.data.data }));
        dispatch(updateRedux({ key: "about_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_about_loading", value: false }));
      console.log(err);
    });
};

export const updateAbout = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_about_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateAbout`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      console.log(err);
    });
};

export const getAttractionsWithoutPagination = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getAttractionsWithoutPagination`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "full_attraction_list", value: res.data.data })
        );
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReviewsWithoutPagination = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getReviewsWithoutPagination`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "full_review_list", value: res.data.data })
        );
        dispatch(updateRedux({ key: "review_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertHome = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_home_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHome`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_home_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_home_loading", value: false }));
      console.log(err);
    });
};

export const getHomeDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_home_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getHomeDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_home_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "home_details", value: res.data.data }));
        dispatch(updateRedux({ key: "home_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_home_loading", value: false }));
      console.log(err);
    });
};

export const updateHome = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_home_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateHome`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_home_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_home_loading", value: false }));
      console.log(err);
    });
};

export const insertTerms = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_tc_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertTerms`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_tc_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_tc_loading", value: false }));
      console.log(err);
    });
};

export const getTerms = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_tc_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getTerms`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_tc_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "tc_details", value: res.data.data }));
        dispatch(updateRedux({ key: "tc_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_tc_loading", value: false }));
      console.log(err);
    });
};

export const insertBookingProcess = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_booking_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertBookingProcess`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_booking_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_booking_loading", value: false }));
      console.log(err);
    });
};

export const getBookingProcess = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_booking_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getBookingProcess`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_booking_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "booking_details", value: res.data.data }));
        dispatch(updateRedux({ key: "booking_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_booking_loading", value: false }));
      console.log(err);
    });
};

export const insertCancellation = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_cancellation_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertCancellation`, data)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_cancellation_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_cancellation_loading", value: false })
      );
      console.log(err);
    });
};

export const getCancellation = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_cancellation_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getCancellation`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "detail_cancellation_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "cancellation_details", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "cancellation_path", value: res.data.path })
        );
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "detail_cancellation_loading", value: false })
      );
      console.log(err);
    });
};

export const insertPrivacy = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_privacy_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertPrivacy`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_privacy_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_privacy_loading", value: false }));
      console.log(err);
    });
};

export const getPrivacy = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_privacy_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPrivacy`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_privacy_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "privacy_details", value: res.data.data }));
        dispatch(updateRedux({ key: "privacy_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_privacy_loading", value: false }));
      console.log(err);
    });
};

export const insertPackage = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_package_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertPackage`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      console.log(err);
    });
};

export const getPackageDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_package_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPackageDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_package_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_details", value: res.data.data }));
        dispatch(updateRedux({ key: "package_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_package_loading", value: false }));
      console.log(err);
    });
};

export const getPackage = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "package_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPackage`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "package_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "package_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "package_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "package_loading", value: false }));
      console.log(err);
    });
};

export const updatePackage = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_package_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updatePackage`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      console.log(err);
    });
};

export const deletePackage = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_package_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deletePackage/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      console.log(err);
    });
};

export const getEsseDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_einfo_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getEsseDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_einfo_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "essential_info_details", value: res.data.data })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_einfo_loading", value: false }));
      console.log(err);
    });
};

export const insertEssentialInfoDesc = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_einfo_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertEssentialInfoDesc`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_einfo_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_einfo_loading", value: false }));
      console.log(err);
    });
};

export const getBlog = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "blog_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getBlog`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "blog_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "blog_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "blog_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "blog_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "blog_loading", value: false }));
      console.log(err);
    });
};

export const deleteBlog = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_blog_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteBlog/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      console.log(err);
    });
};
export const getBlogDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_blog_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getBlogDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_blog_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "blog_details", value: res.data.data }));
        dispatch(updateRedux({ key: "blog_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_blog_loading", value: false }));
      console.log(err);
    });
};

export const insertBlog = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_blog_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertBlog`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      console.log(err);
    });
};

export const updateBlog = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_blog_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateBlog`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_blog_loading", value: false }));
      console.log(err);
    });
};

export const getPackageList = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getPackageList`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_list", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getEnquiries = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "enquiry_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getEnquiries`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "enquiry_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "enquiries_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "enquiries_page_details", value: res.data.page })
        );
        // dispatch(updateRedux({ key: "enquiries_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "enquiry_loading", value: false }));
      console.log(err);
    });
};

export const getEnquiryDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_enquiry_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getEnquiryDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_enquiry_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "enquiry_details", value: res.data.data }));
        dispatch(
          updateRedux({ key: "enquiry_details_path", value: res.data.path })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_enquiry_loading", value: false }));
      console.log(err);
    });
};

export const getStatusList = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getStatusList`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "status_list", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateEnquiry = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_enquiry_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateEnquiry`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_enquiry_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_enquiry_loading", value: false }));
      console.log(err);
    });
};

export const getContactDetails = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_contact_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getContact`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_contact_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "contact_details", value: res.data.data }));
        dispatch(updateRedux({ key: "contact_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_contact_loading", value: false }));
      console.log(err);
    });
};

export const insertContact = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_contact_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertContact`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_contact_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_contact_loading", value: false }));
      console.log(err);
    });
};

export const getManageBooking = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getBookingList`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "booking_data", value: res.data.data }));
        dispatch(updateRedux({ key: "booking_page", value: res.data.page }));
        // dispatch(updateRedux({ key: "enquiries_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBookingDetails = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "booking_detail_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getBookingDetails`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "booking_detail_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "details_booking", value: res?.data?.data })
        );
        dispatch(
          updateRedux({ key: "booking_path", value: res?.data?.bookingPath })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "booking_detail_loading", value: false }));
      console.log(err);
    });
};

export const updateBooking = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "booking_update_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateBooking`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "booking_update_loading", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_enquiry_loading", value: false }));
      console.log(err);
    });
};

export const insertGeneral = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_general_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertGeneral`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_general_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_general_loading", value: false }));
      console.log(err);
    });
};

export const getGeneralDetails = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_general_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getGeneral`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_general_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "general_details", value: res.data.data }));
        dispatch(updateRedux({ key: "general_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_general_loading", value: false }));
      console.log(err);
    });
};

export const getPackagePageDetails = () => (dispatch) => {
  dispatch(updateRedux({ key: "package_page_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPackagePage`)
    .then((res) => {
      dispatch(updateRedux({ key: "package_page_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "packagepage_details", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "package_page_path", value: res.data.path })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "package_page_loading", value: false }));
      console.log(err);
    });
};

export const insertPackagePage = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_package_page_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertPackagePage`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_package_page_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_package_page_loading", value: false })
      );
      console.log(err);
    });
};

export const getPromocodes = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "promocode_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPromotionCode`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "promocode_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "promocode_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "promocode_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "promocode_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "promocode_loading", value: false }));
      console.log(err);
    });
};

export const deletePromocode = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_promocode_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deletePromotionCode/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      console.log(err);
    });
};

export const updatePromoCodes = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_promocode_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updatePromotionCode`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      console.log(err);
    });
};

export const insertPromoCode = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_promocode_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertPromotionCode`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_promocode_loading", value: false }));
      console.log(err);
    });
};

export const getPromoCodeDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "details_promocode_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPromotionCodeDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "details_promocode_loading", value: false }));
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "promocode_details", value: res.data.data })
        );
        // dispatch(updateRedux({ key: "promocode_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "details_promocode_loading", value: false }));
      console.log(err);
    });
};

export const getPermission = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getUser`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "permission_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "permission_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "permission_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "permission_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "permission_loading", value: false }));
      console.log(err);
    });
};

export const deletePermission = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteUser/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const getModules = () => (dispatch) => {
  dispatch(updateRedux({ key: "modules_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getModules`)
    .then((res) => {
      dispatch(updateRedux({ key: "modules_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "modules_data", value: res.data.data }));
      } else {
        toast.error(res.message[0].message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "modules_loading", value: false }));
      console.log(err);
    });
};

export const updateUser = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateUser`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const insertUser = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertUser`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const getUserDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "details_permission_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getUserDetail/${id}`)
    .then((res) => {
      dispatch(
        updateRedux({ key: "details_permission_loading", value: false })
      );
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "permission_details", value: res.data.data })
        );
        dispatch(updateRedux({ key: "permission_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "details_promocode_loading", value: false }));
      console.log(err);
    });
};

export const createPassword = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "setpassword_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}setNewPassword`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "setpassword_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "setpassword_loading", value: false }));
      console.log(err);
    });
};

export const getCountry = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getCountry`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "country_list", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertAccountinginfo = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_accountInfo_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertAccountingInfo`, formData)
    .then((res) => {
      dispatch(
        updateRedux({ key: "update_accountInfo_loading", value: false })
      );
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(
        updateRedux({ key: "update_accountInfo_loading", value: false })
      );
      console.log(err);
    });
};

export const getAccountingInfo = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getAccountingInfo`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "account_info_data", value: res.data.data })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReviews = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "review_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getReview`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "review_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "review_data", value: res.data.data }));
        dispatch(
          updateRedux({ key: "review_page_details", value: res.data.page })
        );
        dispatch(updateRedux({ key: "reviews_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "review_loading", value: false }));
      console.log(err);
    });
};

export const deleteReview = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_review_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteReview/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      console.log(err);
    });
};
export const getReviewDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_review_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getReviewDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_review_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "review_details", value: res.data.data }));
        dispatch(updateRedux({ key: "review_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_review_loading", value: false }));
      console.log(err);
    });
};

export const insertReview = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_review_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertReview`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      console.log(err);
    });
};

export const updateReview = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_review_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateReview`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_review_loading", value: false }));
      console.log(err);
    });
};

export const updatePackageRank = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_package_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updatePackageRank`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_package_loading", value: false }));
      console.log(err);
    });
};

export const approveOrReject = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_status_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}approveOrReject`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_status_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_status_loading", value: false }));
      console.log(err);
    });
};

export const updateStatusBooking = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "booking_update_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateStatusBooking`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "booking_update_loading", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_enquiry_loading", value: false }));
      console.log(err);
    });
};

export const forgotPassword = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "forgot_password_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}forgotPassword`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "forgot_password_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "forgot_password_loading", value: false }));
      console.log(err);
    });
};

export const resetPassword = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "reset_password_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}resetPassword`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "reset_password_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "reset_password_loading", value: false }));
      console.log(err);
    });
};

export const getUserPermissions = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getUserPermissions`)
    .then((res) => {
      if (res.data.status) {
        localStorage.setItem(
          "permission",
          JSON.stringify(res.data.customPermissions)
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAirportCodes = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getAirportCodes`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "airport_codes", value: res.data.data }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createBooking = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_booking_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}createBooking`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "add_booking_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        callback && callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "add_booking_loading", value: false }));
      console.log(err);
    });
};
