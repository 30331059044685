import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";

function AttractionsDragAndDrop({
  list = [],
  data,
  inputDataRemoveChangeHandler,
  index,
  inputDataChangeHandler,
  attractionPath,
  handleDeleteAttraction,
  handleReorderAttraction,
  handleOpenAttraction,
}) {
  const [items, setItems] = useState(list);

  useEffect(() => {
    setItems([...list]);
  }, [list, data]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const newItems = [...items];
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);

    setItems(newItems);

    handleReorderAttraction(newItems);
  };

  console.log({items})

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: "flex",
              padding: 0,
              paddingRight: 0,
              paddingLeft: 0,
            }}
            className="attraction__drag"
          >
            {Array.from({ length: 8 }).map((_, arrIndex) =>
              items[arrIndex] ? (
                <Draggable
                  key={items[arrIndex]._id}
                  draggableId={items[arrIndex]._id}
                  index={arrIndex}
                >
                  {(provided) => (
                    <div
                      key={items[arrIndex]._id}
                      className="mb-3 col all__padding__0"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        backgroundColor: "#e3e3e3",
                        margin: 10,
                        height: 130,
                        borderRadius: 8,
                        position: "relative",
                        ...provided.draggableProps.style,
                      }}
                    >
                      <img
                        src={`${IMAGE_BASE_URL}${attractionPath}/${items[arrIndex]?.image}`}
                        alt=""
                        height={"70%"}
                        width={"100%"}
                        style={{ display: "block" }}
                      />
                      <p className="text-center">{items[arrIndex]?.title}</p>
                      <AiOutlineDelete
                        size={20}
                        className="delete-icon"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          cursor: "pointer",
                          color: "white",
                          padding: "3px",
                          background: "red",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleDeleteAttraction(arrIndex)}
                      />
                      <div className="index_number">
                        <p>{arrIndex + 1}</p>
                      </div>
                    </div>
                  )}
                </Draggable>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "120px",
                    backgroundColor: "#e3e3e3",
                    margin: 10,
                    borderRadius: 8,
                    position: "relative",
                  }}
                >
                  <AiOutlinePlus
                    size={30}
                    className="plus-icon"
                    onClick={() => handleOpenAttraction(arrIndex)}
                  />
                  <div className="index_number">
                    <p>{arrIndex + 1}</p>
                  </div>
                </div>
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default AttractionsDragAndDrop;
