import React, { useEffect } from "react";
import Input from "../commonComponents/Input/Input";
import useForm from "../utils/Hooks/useForm";
import TextArea from "../commonComponents/TextArea/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountingInfo,
  insertAccountinginfo,
} from "../redux/actionCreator";
import { account_info_data } from "../redux/commonReducer";

const AccountInfo = () => {
  const dispatch = useDispatch();

  const details = useSelector(account_info_data);

  useEffect(() => {
    dispatch(getAccountingInfo());
  }, [dispatch]);

  useEffect(() => {
    if (details) {
      let result = {
        account_name: details?.account_name ,
        account_number: details?.account_number,
        routing_code: details?.routing_code,
        terms_conditions: details?.terms_conditions
      };
      setInputs({ ...result });

  }}, [details]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    dispatch(getAccountingInfo());
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });

    if (isValidated?.valid) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let raw = {
      account_name: inputs?.account_name,
      account_number: inputs?.account_number,
      routing_code: inputs?.routing_code,
      terms_conditions: inputs?.terms_conditions,
    };
    console.log(raw);
    dispatch(
      insertAccountinginfo(raw, (res) => {
        if (res) {
          onCancelHandler();
        }
      })
    );
  };

  return (
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title border-style mb-3">Account Info</h5>
          <div className="row">
            <div className="col-12">
              <Input
                className="css-inp-esc1 mb-3"
                label="Name"
                placeholder="Type here"
                required={{ message: "Please Enter Name " }}
                field={"account_name"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
              />
            </div>
            <div className="col-6 ">
              <Input
                className="css-inp-esc1 mb-3"
                label="Account Number"
                placeholder="Type here"
                required={{ message: "Please Enter Account Number" }}
                field={"account_number"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
              />
            </div>
            <div className="col-6">
              <Input
                className="css-inp-esc1 mb-3"
                label="Routing Code"
                placeholder="Type here"
                required={{ message: "Please Enter Routing Code" }}
                field={"routing_code"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
              />
            </div>

            <div className="col-12 mb-4">
              <TextArea
                label={"Terms And Conditions"}
                className={"css-txt-esc1"}
                placeholder={"Type here"}
                field={"terms_conditions"}
                value={inputs.terms_conditions}
                error={errors.terms_conditions}
                inputSocket={formPlug}
                onChange={inputHandler}
                required={{ message: "Please Enter Terms And Conditions" }}
              />
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-3"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
