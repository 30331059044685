import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import useFilter from "../../utils/Hooks/useFilter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import { deletePermission, getPermission } from "../../redux/actionCreator";
import {
  permission_data,
  permission_loading,
  permission_page_details,
  permission_path,
} from "../../redux/commonReducer";

export default function ManagePermissions() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(permission_data);
  const pageData = useSelector(permission_page_details);
  const path = useSelector(permission_path);
  const loading = useSelector(permission_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "createdAt",
    sortValue: -1,
    keyword: "",
  };

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("en-GB", {
  //     day: "2-digit",
  //     month: "long",
  //     year: "numeric",
  //   });
  // };
  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deletePermission(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });

          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");

          dispatch(getPermission(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const getSearchDataHandler = (filter) => {
    const formData = new FormData();
    formData.append("page", filter.page);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter.keyword);
    formData.append("per_page", filter.perPage); 
    dispatch(getPermission(formData));
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="table__add border-style">
                <h5 className="card-title">Manage Permission</h5>
                <Link to="/add-permission">
                  <button className="btn btn-primary">Add User</button>
                </Link>
              </div>
              <div className="search__head">
                <div className="count">
                  <EntriesCounter
                    value={filterData.perPage}
                    onChange={(e) => {
                      filterHandler("entries", e);
                    }}
                  />
                </div>
                <div className="search">
                  <Input
                    value={filterData.keyword}
                    type="text"
                    className="css-inp-esc2 search"
                    // icon={GenericSearchIcon}
                    placeholder={"Search Here"}
                    reverse
                    width="300px"
                    field="keyword"
                    showCloseBtn
                    onChange={(e) => !e && filterHandler("keyword", e)}
                    onSubmit={(e) => {
                      filterHandler("keyword", e);
                    }}
                    onIconClick={(e) => {
                      filterHandler("keyword", e);
                    }}
                  />
                </div>
              </div>
              {loading ? (
                <LoadingSpinner height={"200px"} />
              ) : (
                <Table
                  titles={[
                    {
                      title: "Sl no:",
                      key: "sl_no",
                      width: "10px",
                    },
                    {
                      title: "Profile",
                      key: "profile_image",
                      // sort: true,
                      width: "300px",
                    },
                    {
                      title: "First Name",
                      key: "first_name",
                      sort: true,
                      width: "200px",
                    },
                    {
                      title: "Last Name",
                      key: "last_name",
                      sort: true,
                      width: "100px",
                    },
                    {
                      title: "Email",
                      key: "email",
                      sort: true,
                      width: "100px",
                    },
                    {
                      title: "Gender",
                      key: "gender",
                      sort: true,
                      width: "100px",
                    },
                    {
                      title: "Phone",
                      key: "phone",
                      sort: true,
                      width: "100px",
                    },

                    { title: "Action", key: "action", width: "100px" },
                  ]}
                  content={data?.map((item, i) => ({
                    sl_no: filterData.page * filterData.perPage + (i + 1),
                    profile_image: item?.profile_image ?
                      <img
                        src={`${IMAGE_BASE_URL}${path}/${item?.profile_image}`}
                        alt={item?.profile_image}
                      />  : <img src = "/assets/img/logo.png" alt = "alt completed" />
                    ,
                    first_name: item?.first_name,
                    last_name: item?.last_name,
                    email: item?.email,
                    gender: item?.gender.charAt(0).toUpperCase() + item?.gender.slice(1),
                    phone: item?.phone,


                    action: (
                      <Actions
                        buttons={{
                          edit: EditIcon,
                          delete: DeleteIcon,
                        }}
                        onEdit={() => navigate(`/update-permission/${item?._id}`)}
                        onDelete={() => showDeletePopup(item?._id)}
                      />
                    ),
                  }))}
                  onSort={(e) => filterHandler("sort", e)}
                  page={filterData.page}
                  pagesCount={pageData?.pages}
                  pageStatus={pageData?.status}
                  onPageChange={(e) => {
                    filterHandler("page", e.selected);
                  }}
                  sortValue={{
                    key: filterData.sortColumn,
                    value: filterData.sortValue,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Permission"}
      />
    </>
  );
}


