import React, { useEffect, useState } from "react";
import "./Checkbox.scss";
import { v4 } from "uuid";

export default function Checkbox({
  label = "Label",
  className,
  inputClassName,
  checked,
  required,
  error,
  field,
  onChange,
  reverse,
  size,
  toggleSwitch,
  inputSocket,
  condition,
  clearOnHide,
  disabled,
}) {
  let uuid = v4();
  const [inError, setInError] = useState("");

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function checkedHandler(checked) {
    let res = false;
    if (typeof checked === "object" && checked?.[field] !== undefined)
      res = checked?.[field];
    else if (typeof checked === "object" && checked?.[field] === undefined)
      res = false;
    else if (checked !== undefined) res = checked;
    return res;
  }

  if (!condition && condition !== undefined) return null;

  return (
    <span id="Checkbox" className={className || ""}>
      <span className={reverse ? "reverse" : ""}>
        {label && (
          <label htmlFor={uuid}>
            <p>{label}</p>
            {(required?.condition !== undefined
              ? required?.condition
              : required) && <em>*</em>}
          </label>
        )}
        {toggleSwitch ? (
          <ToggleSwitch
            label={toggleSwitch}
            onChange={(e) => onChange && onChange(e, field)}
            value={checkedHandler(checked)}
            disabled={disabled}
          />
        ) : (
          <input
            type="checkbox"
            className={inputClassName || ""}
            style={{ width: size, height: size }}
            id={uuid}
            checked={checkedHandler(checked)}
            disabled={disabled}
            onChange={(e) => onChange && onChange(e.target.checked, field)}
          />
        )}
      </span>
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error">
          {inError ||
            (typeof error === "object" ? error?.[field] || "" : error)}
        </small>
      )}
    </span>
  );
}

const ToggleSwitch = ({ onChange, label, value, disabled }) => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setToggle(typeof value === "boolean" ? value : false);
  }, [value]);

  let toggleHandler = () => {
    if (disabled) return;
    setToggle((s) => {
      let r = !s;
      onChange && onChange(r);
      return r;
    });
  };

  return (
    <span
      id="ToggleSwitch"
      className={disabled ? "disabled" : ""}
      data-checked={toggle}
      onClick={toggleHandler}
    >
      {label[toggle] && <label htmlFor="">{label[toggle]}</label>}
      <span></span>
    </span>
  );
};
