import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, Col, Row } from "react-bootstrap";
import Input from "../../commonComponents/Input/Input";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../utils/editorConfig";
import Select from "../../commonComponents/Select/Select";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import DNDIcon from "../../assets/icons/dragdrop.svg";

function ItenararyDragAndDrop({
  list,
  activeKey,
  setActiveKey,
  hasError,
  formPlug,
  itineraryDataChangeHandler,
  errors,
  activityData,
  inputDataRemoveChangeHandler,
  attractionData,
  removeImage,
  setErrors,
  fileUploadHandler,
  SupportedFormats,
  documentList,
  onAddAdditionalItineraryData,
  onClearAdditionalItineraryData,
  onEditAttachment,
  onDeleteAttachment,
  reOrderItenarary,
  details,
}) {
  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const newItems = [...list];
  //   const [removed] = newItems.splice(result.source.index, 1);
  //   newItems.splice(result.destination.index, 0, removed);

  //   reOrderItenarary([...newItems]);
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newItems = [...list];
    if (destination.index === 0) {
      return;
    }
    if (
      !details?.is_oneday_travel &&
      destination.index === newItems.length - 1
    ) {
      return;
    }
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);
    reOrderItenarary([...newItems]);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              padding: "20px",
            }}
            className="itenarary_dnd"
          >
            {list.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={
                  details?.is_oneday_travel
                    ? index === 0
                    : index === 0 || index === list?.length - 1
                }
              >
                {(provided) => (
                  <Accordion.Item eventKey={index}>
                    <div
                      style={{
                        ...provided.draggableProps.style,
                        position: "relative",
                        width: "100%",
                        height: 120,
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="header__container"
                    >
                      <Accordion.Header
                        onClick={() =>
                          setActiveKey(activeKey === index ? null : index)
                        }
                      >
                        <div className="d-flex justify-content-between w-full">
                          <div>Day {index + 1}</div>
                          {hasError(index) ? (
                            <div>
                              <span className="error mx-3 align-end">
                                Please resolve the error inside
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Accordion.Header>

                      <div
                        style={{
                          height: 20,
                          width: 20,
                          position: "absolute",
                          top: -15,
                          right: -15,
                          borderRadius: "25%",
                          padding: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={DNDIcon} width={20} />
                      </div>
                    </div>

                    <Accordion.Body>
                      <div className="row mb-5">
                        <div className="col-12 ">
                          <Input
                            className="css-inp-esc1 mb-3"
                            label="Day Title"
                            placeholder="Type here"
                            required={{
                              message: "Please enter title",
                            }}
                            field={"title"}
                            inputSocket={formPlug}
                            value={item?.title}
                            onChange={(e, key) => {
                              itineraryDataChangeHandler(
                                e,
                                key,
                                index,
                                "itinerary"
                              );
                            }}
                            error={errors[`${"itinerary"}_${"title"}_${index}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextEditor
                            config={editorConfigEn}
                            label={"Day Description"}
                            className={"mb-3"}
                            field={"description"}
                            inputSocket={formPlug}
                            // required={{
                            //   message: "Please enter day description",
                            // }}
                            placeholder="Type Here"
                            value={item?.description}
                            onBlur={(newContent) =>
                              itineraryDataChangeHandler(
                                newContent,
                                "description",
                                index,
                                "itinerary"
                              )
                            }
                            onChange={(e, key) => {
                              itineraryDataChangeHandler(
                                e,
                                key,
                                index,
                                "itinerary"
                              );
                            }}
                            error={
                              errors[`${"itinerary"}_${"description"}_${index}`]
                            }
                            validation={{ maxChar: 255 }}
                          />
                        </div>

                        {index == 0 ||
                        (index == list?.length - 1 &&
                          !details?.is_oneday_travel) ? (
                          <></>
                        ) : (
                          <>
                            <div className="col-12 col-md-6">
                              <Select
                                title="Activities"
                                className="css-slc-esc1 mb-3"
                                placeholder={"Select"}
                                options={activityData}
                                labelSetter={(o) => o?.title}
                                valueSetter={(option) => option._id}
                                field={"activity"}
                                inputSocket={formPlug}
                                // required={{ message: "Please select activity" }}
                                Multi2
                                value={item.activity ? item.activity : []}
                                onChange={(e, key) => {
                                  itineraryDataChangeHandler(
                                    e,
                                    key,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"itinerary"}_${"activity"}_${index}`
                                  ]
                                }
                              />
                            </div>
                            <div className="raw">
                              <div className="col-12 selectedListData">
                                {item?.activity?.map((option, i) => (
                                  <div key={option} className="mb-3 ">
                                    <div className="d-inline-block mb-1">
                                      <button
                                        className="selected__item"
                                        type="button"
                                      >
                                        {option?.title}
                                        <img
                                          src="/assets/img/icons/close-red.svg"
                                          onClick={() =>
                                            inputDataRemoveChangeHandler(
                                              "activity",
                                              option?._id,
                                              "itinerary",
                                              index
                                            )
                                          }
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <Select
                                  title="Attractions"
                                  className="css-slc-esc1 mb-3"
                                  placeholder={"Select"}
                                  options={attractionData}
                                  labelSetter={(o) => o?.title}
                                  valueSetter={(option) => option._id}
                                  field={"attraction"}
                                  inputSocket={formPlug}
                                  // required={{ message: "Please select attraction" }}
                                  Multi2
                                  value={item.attraction ? item.attraction : []}
                                  onChange={(e, key) => {
                                    itineraryDataChangeHandler(
                                      e,
                                      key,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  error={
                                    errors[
                                      `${"itinerary"}_${"attraction"}_${index}`
                                    ]
                                  }
                                />
                              </div>
                            </div>
                            <div className="raw">
                              <div className="col-12 selectedListData">
                                {item?.attraction.map((option) => (
                                  <div key={option} className="mb-3 ">
                                    <div className="d-inline-block mb-1">
                                      <button
                                        className="selected__item"
                                        type="button"
                                      >
                                        {option?.title}

                                        <img
                                          src="/assets/img/icons/close-red.svg"
                                          onClick={() =>
                                            inputDataRemoveChangeHandler(
                                              "attraction",
                                              option?._id,
                                              "itinerary",
                                              index
                                            )
                                          }
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div>
                                  <div className="d-flex gap-3">
                                    <FileUpload
                                      style={{
                                        marginRight: 5,
                                        marginTop: 15,
                                      }}
                                      className="css-atc-esc5  mt-3"
                                      label="Map Image"
                                      accept={["jpg", "jpeg", "png"]}
                                      icon={"/assets/img/icons/plus_black.svg"}
                                      closeIcon={
                                        <AiOutlineClose
                                          size={30}
                                          className="remove-icon"
                                          onClick={() =>
                                            removeImage("map_image")
                                          }
                                        />
                                      }
                                      onChange={(
                                        e,
                                        isInvalid,
                                        message,
                                        isClosing
                                      ) => {
                                        setErrors((s) => ({
                                          ...s,
                                          map_image: "",
                                        }));
                                        fileUploadHandler(
                                          e,
                                          isInvalid,
                                          message,
                                          isClosing,
                                          "map_image",
                                          itineraryDataChangeHandler,
                                          "single",
                                          index,
                                          "itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                          `${"itinerary"}_${"map_image"}_${index}`
                                        ]
                                      }
                                      width={28}
                                      field={"map_image"}
                                      id="map_image"
                                      maxFileSizeMB={50}
                                      multiple={false}
                                      showPreview
                                      files={item?.map_image}
                                      required={{
                                        message: "Please upload map image",
                                      }}
                                      inputSocket={formPlug}
                                    />

                                  </div>
                                  <SupportedFormats
                                    formats={["jpg", "jpeg", "png"]}
                                    maxSize={50}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <Input
                                  className="css-inp-esc1 mb-3"
                                  label="Map Alt Text"
                                  placeholder="Type here"
                                  // required={{ message: "Please enter map alt" }}
                                  field={"map_image_alt"}
                                  inputSocket={formPlug}
                                  value={item?.map_image_alt}
                                  onChange={(e, key) => {
                                    itineraryDataChangeHandler(
                                      e,
                                      key,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  // error={
                                  //   errors[
                                  //     `${"itinerary"}_${"map_image_alt"}_${index}`
                                  //   ]
                                  // }
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div>
                                  <div className="d-flex gap-3">
                                    <FileUpload
                                      style={{
                                        marginRight: 5,
                                        marginTop: 15,
                                      }}
                                      className="css-atc-esc5  mt-3"
                                      label="Mobile App image"
                                      accept={["jpg", "jpeg", "png"]}
                                      icon={"/assets/img/icons/plus_black.svg"}
                                      closeIcon={
                                        <AiOutlineClose
                                          size={30}
                                          className="remove-icon"
                                          onClick={() =>
                                            removeImage("mobile_map_image")
                                          }
                                        />
                                      }
                                      onChange={(
                                        e,
                                        isInvalid,
                                        message,
                                        isClosing
                                      ) => {
                                        setErrors((s) => ({
                                          ...s,
                                          mobile_map_image: "",
                                        }));
                                        fileUploadHandler(
                                          e,
                                          isInvalid,
                                          message,
                                          isClosing,
                                          "mobile_map_image",
                                          itineraryDataChangeHandler,
                                          "single",
                                          index,
                                          "itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                          `${"itinerary"}_${"mobile_map_image"}_${index}`
                                        ]
                                      }
                                      width={28}
                                      field={"mobile_map_image"}
                                      id="mobile_map_image"
                                      maxFileSizeMB={50}
                                      multiple={false}
                                      showPreview
                                      files={item?.mobile_map_image}
                                      required={{
                                        message:
                                          "Please upload mobile app image",
                                      }}
                                      inputSocket={formPlug}
                                    />
                                  </div>
                                  <SupportedFormats
                                    formats={["jpg", "jpeg", "png"]}
                                    maxSize={50}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <Input
                                  className="css-inp-esc1 mb-3"
                                  label="Mobile App Image Alt Text"
                                  placeholder="Type here"
                                  // required={{
                                  //   message: "Please enter alt text",
                                  // }}
                                  field={"mobile_map_image_alt"}
                                  inputSocket={formPlug}
                                  value={item?.mobile_map_image_alt}
                                  onChange={(e, key) => {
                                    itineraryDataChangeHandler(
                                      e,
                                      key,
                                      index,
                                      "itinerary"
                                    );
                                  }}
                                  // error={
                                  //   errors[
                                  //     `${"itinerary"}_${"mobile_map_image_alt"}_${index}`
                                  //   ]
                                  // }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {index == 0 ||
                      (index == list?.length - 1 &&
                        !details?.is_oneday_travel) ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div
                            class="tab__sub_header mt-4"
                            id="essential_documents"
                          >
                            <h2>Add Essential Documents</h2>
                          </div>
                          <div className="grid__items col-3">
                            <div className="item">
                              <Select
                                title={
                                  <p>
                                    Document type <em>*</em>
                                  </p>
                                }
                                className="css-slc-esc1 mb-3"
                                placeholder={"Select"}
                                options={documentList}
                                labelSetter={(o) => o?.label}
                                valueSetter={(o) => o.value}
                                field={"type"}
                                inputSocket={formPlug}
                                value={item?.type != "" ? item?.type : ""}
                                onChange={(e, key) => {
                                  itineraryDataChangeHandler(
                                    e,
                                    key,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[`${"itinerary"}_${"type"}_${index}`]
                                }
                              />
                            </div>
                            <div className="item">
                              <Input
                                className="css-inp-esc1 mb-3"
                                label={
                                  <p>
                                    Title <em>*</em>
                                  </p>
                                }
                                placeholder="Type here"
                                field={"doc_title"}
                                inputSocket={formPlug}
                                value={item?.doc_title}
                                onChange={(e, key) => {
                                  itineraryDataChangeHandler(
                                    e,
                                    key,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"itinerary"}_${"doc_title"}_${index}`
                                  ]
                                }
                              />
                            </div>
                            <div className="item">
                              <label>Document <em>*</em></label>
                              <FileUpload
                                className="css-atc-esc1 "
                                buttonLabel={
                                  <p>
                                    Upload Document 
                                  </p>
                                }
                                accept={[
                                  "jpg",
                                  "jpeg",
                                  "png",
                                  "pdf",
                                  "application/pdf",
                                ]}
                                onChange={(
                                  e,
                                  isInvalid,
                                  message,
                                  isClosing
                                ) => {
                                  setErrors((s) => ({
                                    ...s,
                                    item: "",
                                  }));
                                  fileUploadHandler(
                                    e,
                                    isInvalid,
                                    message,
                                    isClosing,
                                    "item",
                                    itineraryDataChangeHandler,
                                    "single",
                                    index,
                                    "itinerary"
                                  );
                                }}
                                // loader={imageLoader}
                                error={
                                  errors[`${"itinerary"}_${"item"}_${index}`]
                                }
                                id="item"
                                maxFileSizeMB={100}
                                showPreview
                                files={item?.item}
                              />
                              <SupportedFormats
                                    formats={["jpg",
                                  "jpeg",
                                  "png",
                                  "pdf",
                                  "application/pdf",]}
                                  />
                            </div>
                            <div className="item grid-3">
                              <TextEditor
                                config={editorConfigEn}
                                label={
                                  <p>
                                    Description <em>*</em>
                                  </p>
                                }
                                className={"mb-3"}
                                field={"doc_description"}
                                inputSocket={formPlug}
                                placeholder="Type Here"
                                value={item?.doc_description}
                                onBlur={(newContent) =>
                                  itineraryDataChangeHandler(
                                    newContent,
                                    "doc_description",
                                    index,
                                    "itinerary"
                                  )
                                }
                                onChange={(e, key) => {
                                  itineraryDataChangeHandler(
                                    e,
                                    key,
                                    index,
                                    "itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"itinerary"}_${"doc_description"}_${index}`
                                  ]
                                }
                                validation={{ maxChar: 255 }}
                              />

                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary me-3"
                              onClick={() =>
                                onAddAdditionalItineraryData(item, index)
                              }
                            >
                              Add
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger "
                              onClick={() =>
                                onClearAdditionalItineraryData(item, index)
                              }
                            >
                              Cancel
                            </button>
                          </div>
                          {item?.attachments?.length ? (
                            <Table
                              className="css-tbl-esc1 mt-3"
                              titles={[
                                { title: "Sl No", key: "sl_no", width: "10px" },

                                { title: "Type", key: "type", width: "200px" },
                                {
                                  title: "Title",
                                  key: "doc_title",
                                  width: "300px",
                                },
                                {
                                  title: "Description",
                                  key: "doc_description",
                                  width: "450px",
                                },
                                {
                                  title: "Action",
                                  key: "action",
                                  width: "10px",
                                },
                              ]}
                              content={item?.attachments?.map((item, i) => {
                                return {
                                  sl_no: i + 1,
                                  doc_title: item.doc_title,
                                  type: item?.type?.label,
                                  doc_description: (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item?.doc_description,
                                      }}
                                    ></span>
                                  ),
                                  action: (
                                    <Actions
                                      buttons={{
                                        edit: EditIcon,
                                        delete: DeleteIcon,
                                      }}
                                      onEdit={() => {
                                        onEditAttachment(index, item, i);
                                      }}
                                      onDelete={() => {
                                        onDeleteAttachment(index, i);
                                      }}
                                    />
                                  ),
                                };
                              })}
                              sortValue={{ key: "", value: "" }}
                              onSort={() => {}}
                              page={""}
                              pagesCount={""}
                              onPageChange={() => {}}
                              pageStatus={""}
                              top
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ItenararyDragAndDrop;
