import DatePick from "../../../commonComponents/DatePicker/DatePick";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import Input from "../../../commonComponents/Input/Input";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import TextArea from "../../../commonComponents/TextArea/TextArea";

const VehicleDetails = ({
  inputs,
  inputHandler,
  errors,
  formPlug,
  getMinTime,
  details,
  fileUploadHandler,
  setInputs,
}) => {
  return (
    <>
      {/* {details?.flight_booking ? ( */}
      <div className="card">
        <div className="card-body pt-0">
          <div className="tab-content">
            <div className="tab__header">
              <h2>Flight Details</h2>
            </div>
            <div class="tab__sub_header">
              <h2>Start Trip</h2>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Departure Airport"
                    placeholder="Type here"
                    // required={{
                    //   message: "Please enter start departure airport",
                    // }}
                    field={"start_departure_airport"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Departure Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.flight_start_date}
                    onChange={(e, key) => {
                      setInputs((s) => ({
                        ...s,
                        flight_start_time: "",
                        arrival_start_date: "",
                        arrival_start_time: "",
                      }));
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={details?.start_travel_date ?? new Date()}
                    error={errors?.flight_start_date}
                    inputSocket={formPlug}
                    // required={{ message: "Please select start date" }}
                    field={"flight_start_date"}
                    showMonthDropdown
                    showYearDropdown
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Departure Time"}
                    selected={inputs?.flight_start_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={getMinTime(
                      inputs.flight_start_date > new Date()
                        ? new Date(
                          new Date(inputs.flight_start_date).setHours(0, 0, 0)
                        )
                        : new Date(details?.start_travel_date) ?? new Date()
                    )}
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"flight_start_time"}
                    inputSocket={formPlug}
                    // required={{ message: "Please select start time" }}
                    error={errors?.flight_start_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Arrival Airport"
                    placeholder="Type here"
                    // required={{ message: "Please enter arrival airport" }}
                    field={"start_arrival_airport"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Arrival Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.arrival_start_date}
                    onChange={(e, key) => {
                      setInputs((s) => ({ ...s, arrival_start_time: "" }));
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={
                      !inputs?.flight_start_date
                        ? details?.start_travel_date ?? new Date()
                        : inputs?.flight_start_date
                    }
                    error={errors?.arrival_start_date}
                    inputSocket={formPlug}
                    // required={{ message: "Please select start date" }}
                    field={"arrival_start_date"}
                    showMonthDropdown
                    showYearDropdown
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Arrival Time"}
                    selected={inputs?.arrival_start_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={getMinTime(
                      inputs.arrival_start_date > new Date()
                        ? new Date(
                          new Date(inputs.arrival_start_date).setHours(
                            0,
                            0,
                            0
                          )
                        )
                        : new Date(details?.start_travel_date) ?? new Date()
                    )}
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"arrival_start_time"}
                    inputSocket={formPlug}
                    // required={{ message: "Please select start time" }}
                    error={errors?.arrival_start_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Travel Time"
                    placeholder="Type here"
                    // required={{ message: "Please enter travel time" }}
                    field={"start_travel_time"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Flight Stops"
                    placeholder="Type here"
                    // required={{ message: "Please enter flight stops" }}
                    field={"start_flight_stops"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <TextArea
                    className={"css-txt-esc1"}
                    placeholder={"Type here"}
                    label="Flight Description"
                    field={"start_flight_description"}
                    value={inputs.start_flight_description}
                    error={errors.start_flight_description}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    // required={{ message: "Please enter flight description" }}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc2 mt-0 mb-3"
                    noLabel
                    buttonLabel="Choose File"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      // "docx",
                      // "doc",
                      // "xlsx",
                      // "xls",
                      // "csv",
                      // "ppt",
                      // "pptx",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "flight_starttrip_attachments",
                        inputHandler,
                        "multiple"
                      );
                    }}
                    validFilesOnly
                    field={"flight_starttrip_attachments"}
                    error={errors.flight_starttrip_attachments}
                    id="flight_starttrip_attachments"
                    maxFileSizeMB={100}
                    multiple={true}
                    files={inputs?.flight_starttrip_attachments}
                    inputSocket={() => { }}
                    showPreview
                  />
                 
                </div>
                <SupportedFormats
                    formats={["jpg",
                      "jpeg",
                      "png",
                      "pdf",]}
                  />
              </div>
            </div>
            <div class="tab__sub_header">
              <h2>Return Trip</h2>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Departure Airport"
                    placeholder="Type here"
                    // required={{ message: "Please enter departure airport" }}
                    field={"return_departure_airport"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Departure Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.flight_return_date}
                    onChange={(e, key) => {
                      setInputs((s) => ({
                        ...s,
                        flight_return_time: "",
                        return_arrival_start_date: "",
                        return_arrival_start_time: "",
                      }));
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={details?.end_travel_date ?? new Date()}
                    error={errors?.flight_return_date}
                    inputSocket={formPlug}
                    // required={{ message: "Please select return date" }}
                    field={"flight_return_date"}
                    showMonthDropdown
                    showYearDropdown
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Departure time"}
                    selected={inputs?.flight_return_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={getMinTime(
                      inputs.flight_return_date > new Date()
                        ? new Date(
                          new Date(inputs.flight_return_date).setHours(
                            0,
                            0,
                            0
                          )
                        )
                        : new Date(details?.end_travel_date) ?? new Date()
                    )}
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"flight_return_time"}
                    inputSocket={formPlug}
                    // required={{ message: "Please select return time" }}
                    error={errors?.flight_return_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Arrival Airport"
                    placeholder="Type here"
                    // required={{ message: "Please enter arrival airport" }}
                    field={"return_arrival_airport"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Arrival Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.return_arrival_start_date}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={
                      !inputs?.flight_return_date
                        ? details?.end_travel_date ?? new Date()
                        : inputs?.flight_return_date
                    }
                    error={errors?.return_arrival_start_date}
                    inputSocket={formPlug}
                    // required={{ message: "Please select return date" }}
                    field={"return_arrival_start_date"}
                    showMonthDropdown
                    showYearDropdown
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Arrival time"}
                    selected={inputs?.return_arrival_start_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={getMinTime(
                      inputs.return_arrival_start_date > new Date()
                        ? new Date(
                          new Date(inputs.return_arrival_start_date).setHours(
                            0,
                            0,
                            0
                          )
                        )
                        : new Date(details?.end_travel_date) ?? new Date()
                    )}
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"return_arrival_start_time"}
                    inputSocket={formPlug}
                    // required={{ message: "Please select return time" }}
                    error={errors?.return_arrival_start_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Travel Time"
                    placeholder="Type here"
                    // required={{ message: "Please enter travel time" }}
                    field={"return_travel_time"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Flight Stops"
                    placeholder="Type here"
                    // required={{ message: "Please enter flight stops" }}
                    field={"return_flight_stops"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <TextArea
                    className={"css-txt-esc1"}
                    placeholder={"Type here"}
                    label="Flight Description"
                    field={"return_flight_description"}
                    value={inputs.return_flight_description}
                    error={errors.return_flight_description}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    // required={{ message: "Please enter flight description" }}
                    required={{ condition: details?.flight_booking }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc2 mt-0 mb-3"
                    noLabel
                    buttonLabel="Choose File"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      // "docx",
                      // "doc",
                      // "xlsx",
                      // "xls",
                      // "csv",
                      // "ppt",
                      // "pptx",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "flight_returntrip_attachments",
                        inputHandler,
                        "multiple"
                      );
                    }}
                    validFilesOnly
                    field={"flight_returntrip_attachments"}
                    error={errors.flight_returntrip_attachments}
                    id="flight_returntrip_attachments"
                    maxFileSizeMB={100}
                    multiple={true}
                    files={inputs?.flight_returntrip_attachments}
                    inputSocket={() => { }}
                    showPreview
                  />
                  
                </div>
                <SupportedFormats
                    formats={["jpg",
                      "jpeg",
                      "png",
                      "pdf",]}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <></>
      )} */}
      <div className="card">
        <div className="card-body pt-0">
          <div className="tab-content">
            <div className="tab__header">
              <h2>Car Rental</h2>
            </div>
            <div class="tab__sub_header">
              <h2>Pickup</h2>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Pickup Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.pick_up_date}
                    onChange={(e, key) => {
                      setInputs((s) => ({
                        ...s,
                        drop_off_date: "",
                        pick_up_time: "",
                      }));
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={details?.start_travel_date ?? new Date()}
                    error={errors?.pick_up_date}
                    inputSocket={formPlug}
                    // required={{ message: "Please select pickup date" }}
                    field={"pick_up_date"}
                    showMonthDropdown
                    showYearDropdown
                    required
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Pickup Time"}
                    selected={inputs?.pick_up_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={getMinTime(
                      inputs.pick_up_date > new Date()
                        ? new Date(
                          new Date(inputs.pick_up_date).setHours(0, 0, 0)
                        )
                        : new Date(details?.start_travel_date) ?? new Date()
                    )}
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"pick_up_time"}
                    inputSocket={formPlug}
                    // required={{ message: "Please select pickup time" }}
                    error={errors?.pick_up_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Pickup Location"
                    placeholder="Type here"
                    // required={{ message: "Please enter pickup location" }}
                    field={"pick_up_location"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc2 mt-0 mb-3"
                    noLabel
                    buttonLabel="Choose File"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      // "docx",
                      // "doc",
                      // "xlsx",
                      // "xls",
                      // "csv",
                      // "ppt",
                      // "pptx",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "pickup_attachments",
                        inputHandler,
                        "multiple"
                      );
                    }}
                    validFilesOnly
                    field={"pickup_attachments"}
                    error={errors.pickup_attachments}
                    id="pickup_attachments"
                    maxFileSizeMB={100}
                    multiple={true}
                    files={inputs?.pickup_attachments}
                    inputSocket={() => { }}
                    showPreview
                  />
                   
                </div>
                <SupportedFormats
                    formats={["jpg",
                      "jpeg",
                      "png",
                      "pdf",]}
                  />
              </div>
            </div>
            <div class="tab__sub_header">
              <h2>Drop-off</h2>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    className="css-dtp-esc2 mb-3"
                    title={"Drop-off Date"}
                    placeholder="DD/MM/YYYY"
                    selected={inputs?.drop_off_date}
                    onChange={(e, key) => {

                      setInputs((prevState) => ({ ...prevState, drop_off_time: "" }));
                      inputHandler(e, key);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={inputs?.pick_up_date || details?.end_travel_date || new Date()}
                    error={errors?.drop_off_date}
                    inputSocket={formPlug}
                    field={"drop_off_date"}
                    showMonthDropdown
                    showYearDropdown
                    required
                  />

                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <DatePick
                    title={"Drop-off Time"}
                    selected={inputs?.drop_off_time}
                    onChange={(e, key) => {
                      inputHandler(e, key);
                    }}
                    className="css-dtp-esc2 w-100"
                    dateFormat="h:mm aa"
                    minTime={
                      inputs?.drop_off_date &&
                        inputs?.pick_up_date &&
                        new Date(inputs?.drop_off_date).toDateString() ===
                        new Date(inputs?.pick_up_date).toDateString()
                        ? inputs?.pick_up_time || new Date().setHours(0, 0, 0)
                        : new Date().setHours(0, 0, 0)
                    }
                    maxTime={new Date().setHours(23, 59)}
                    placeholder="-- : --"
                    field={"drop_off_time"}
                    inputSocket={formPlug}
                    error={errors?.drop_off_time}
                    timeSelectOnly
                    timeIntervals={1}
                    required
                  />



                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label="Drop-off Location"
                    placeholder="Type here"
                    // required={{ message: "Please enter drop-off location" }}
                    field={"drop_off_location"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc2 mt-0 mb-3"
                    noLabel
                    buttonLabel="Choose File"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      // "docx",
                      // "doc",
                      // "xlsx",
                      // "xls",
                      // "csv",
                      // "ppt",
                      // "pptx",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      fileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "dropoff_attachments",
                        inputHandler,
                        "multiple"
                      );
                    }}
                    validFilesOnly
                    field={"dropoff_attachments"}
                    error={errors.dropoff_attachments}
                    id="dropoff_attachments"
                    maxFileSizeMB={100}
                    multiple={true}
                    files={inputs?.dropoff_attachments}
                    inputSocket={() => { }}
                    showPreview
                  />
                   
                </div>
                <SupportedFormats
                    formats={["jpg",
                      "jpeg",
                      "png",
                      "pdf",]}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VehicleDetails;
