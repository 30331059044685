

export const editorConfigEn = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  placeholder: "Type here",
  // askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteFromWord: true,
  askBeforePasteHTML: true,
};