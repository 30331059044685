import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEnquiryDetails,
  getStatusList,
  updateEnquiry,
  uploadFile,
} from "../../redux/actionCreator";
import {
  detail_enquiry_loading,
  enquiry_details,
  enquiry_details_path,
  status_list,
} from "../../redux/commonReducer";
import TextArea from "../../commonComponents/TextArea/TextArea";
import useForm from "../../utils/Hooks/useForm";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import CommentUpdates from "../../components/CommentUpdates";
import History from "../../components/History";
import AttachmentContent from "../../commonComponents/AttachmentContent/AttachmentContent";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

const EditEnquiries = () => {
  // const historyTabs = ["Comments Updates"];
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [matchedStatus, setMatchedStatus] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [trackId, setTrackId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const enquiryDetails = useSelector(enquiry_details);
  const statusData = useSelector(status_list);
  const path = useSelector(enquiry_details_path);
  const loading = useSelector(detail_enquiry_loading)
 

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
  } = useForm({ attachments: [] });

  useEffect(() => {
    let statusKey = {
      key: "enquiry_management",
    };
    dispatch(getStatusList(statusKey));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEnquiryDetails(id));
    }
  }, [id]);

  useEffect(() => {
    const initialStatus = statusData?.find(
      (status) => enquiryDetails?.status === status?._id
    );
    setInputs((prev) => ({
      ...prev,
      status: initialStatus?._id,
    }));

    setMatchedStatus(initialStatus?.name);
  }, [statusData, enquiryDetails]);

  const handleStatusChange = (statusId,userId) => {
    const row = {
      id: userId,
      status: statusId,
    };
    dispatch(
      updateEnquiry(row, (res) => {
        if (res) {
         onSubmitFinal ()
        }
      })
    );
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "enquiry");
        dispatch(
          uploadFile(formData, (res) => {
            let oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  ...ele,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [...oldFiles, ...arr],
            }));
            setImageLoader(false);
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    navigate("/manage-enquiries");
  };

  const onSubmitFinal = () =>{
    setInputs({});
    setErrors({});
    dispatch(getEnquiryDetails(id))
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    const date = new Date(year, month - 1, day);

    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);
  }

  const getStatusColor = (status) => {
    if (status === "New Lead") return "#379777"; 
    if (status === "Lost Lead") return "#FF4545"; 
    if (status === "Discussion In Progress") return "#007bff"; 
    if(status === "Lead Converted" ) return "#F4CE14"
    return "#6c757d";
  };

  const DateConverter = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate();

    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let row = {
      id: id,
      status: inputs.status,
      comment: inputs?.comment,
      attachments: inputs?.attachments?.map((item, i) => item?.response),
      track_id: trackId,
      path : "enquiry"
    };
    console.log(row);

    dispatch(
      updateEnquiry(row, (res) => {
        if (res) {
          onSubmitFinal()
        }
      })
    );
  };

  return (
    loading? (<LoadingSpinner height={"200px"}/>):(

    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style mb-4">
              <h5 className="card-title ">Enquiry Details</h5>
              <Dropdown>
              <Dropdown.Toggle style={{ backgroundColor: getStatusColor(matchedStatus), color: "#fff" }}>
            {matchedStatus}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {statusData
                    ?.filter((data) => data?._id !== enquiryDetails?.status && (data?.name !== "New Lead"))
                    .map((data) => (
                      <Dropdown.Item
                        key={data?._id}
                        onClick={() =>
                          handleStatusChange(data?._id,id)
                        }
                      >
                        {data?.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="row mb-5">
              <div className="col-2">
                <label>Submission Date</label>
                <p className="mb-0">
                  {formatDate(enquiryDetails?.submission_date)}
                </p>
              </div>
              <div className="col-3">
                <label>Customer Name</label>
                <p className="mb-0">
                  {enquiryDetails?.enquiry_type === "curated"
                    ? enquiryDetails?.name || "N/A"
                    : enquiryDetails?.first_name +
                        " " +
                        enquiryDetails?.last_name || "N/A"}
                </p>
              </div>
              <div className="col-3">
                <label>Email Address</label>
                <p className="mb-0">{enquiryDetails?.email}</p>
              </div>
              <div className="col-2">
                <label>Country Code</label>
                <p className="mb-0">{enquiryDetails?.country_code}</p>
              </div>
              <div className="col-2">
                <label>Phone Number</label>
                <p className="mb-0">{enquiryDetails?.phone}</p>
              </div>
            </div>
            {enquiryDetails?.enquiry_type === "curated" && (
              <div className="row mb-4">
                <div className="col-3 enquiry_submission">
                  <label>Travel Dates</label>
                  <p className="mb-0">
                    {DateConverter(enquiryDetails?.start_travel_date)} -{" "}
                    {DateConverter(enquiryDetails?.end_travel_date)}
                  </p>
                </div>
                <div className="col-3">
                  <label>Number of Travellers</label>
                  <p className="mb-0">
                    Adults: {enquiryDetails?.no_of_guest[0]?.adults}
                  </p>
                  <p className="mb-0">
                    Children (5-15): {enquiryDetails?.no_of_guest[1]?.age_5_12}
                  </p>
                  <p className="mb-0">
                    Children (2-15): {enquiryDetails?.no_of_guest[2]?.age_2_5}
                  </p>
                  <p className="mb-0">
                    Infants(0-2): {enquiryDetails?.no_of_guest[3]?.age_less_2}
                  </p>
                </div>
                <div className="col-3">
                  <label>Point Of Origin</label>
                  <p className="mb-0">{enquiryDetails.point_of_origin}</p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 mb-5">
                <label>Message</label>
                <p className="mb-0">{enquiryDetails?.comment}</p>
              </div>
            </div>

            <div>
              <div className="row mb-4">
                <h5 className="card-title border-style mb-3">Updates</h5>

                <div className="col-12 mb-3 ">
                  <TextArea
                    label={"Comment"}
                    className={"css-txt-esc1"}
                    placeholder={"Type here"}
                    field={"comment"}
                    required={{ message: "Please enter comment" }}
                    value={inputs.comment}
                    error={errors.comment}
                    inputSocket={formPlug}
                    onChange={inputHandler}

                    // required={{ message: "Please enter comment" }}
                  />
                </div>
                <div className="col-12 mb-3">
                  <FileUpload
                    label="Add Attachments"
                    className="css-atc-esc1  mt-3"
                    buttonLabel="Add Attachments"
                    accept={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      "docx",
                      "doc",
                      "xlsx",
                      "xls",
                      "csv",
                      "ppt",
                      "pptx",
                      "application/pdf",
                    ]}
                    onChange={(e, isInvalid, message, isClosing) => {
                      setErrors((s) => ({
                        ...s,
                        attachments: "",
                      }));
                      fileSelectedHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "attachments"
                      );
                    }}
                    loader={imageLoader}
                    error={errors.attachments}
                    id="attachments"
                    maxFileSizeMB={100}
                    showPreview
                    value={inputs}
                    multiple={true}
                    files={inputs?.attachments}
                    // inputSocket={formPlug}
                    // required={{ message: "Please upload images" }}
                  />
                </div>
                <SupportedFormats
                    formats={[
                      "jpg",
                      "jpeg",
                      "png",
                      "pdf",
                      "docx",
                      "doc",
                      "xlsx",
                      "xls",
                      "csv",
                      "ppt",
                      "pptx",
                    ]}
                    maxSize={100}
                  />

                <div className="col-12">
                  <button
                    className="btn btn-primary m-2"
                    onClick={() => onSubmitHandler()}
                  >
                    Submit
                  </button>

                  <button
                    className="btn btn-danger"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="history__tabs">
          {/* <ul>
            {historyTabs.map((tab, index) => (
              <li
                key={index}
                className={activeHistoryTab === index ? "active" : ""}
                onClick={() => setActiveHistoryTab(index)}
              >
                {tab}
              </li>
            ))}
          </ul> */}
          
            <CommentUpdates
              comments={enquiryDetails?.comments}
              formatDate={DateConverter}
              path={path}
            />
      
          {/* {activeHistoryTab === 1 && <History  />} */}
        </div>
      </div>
    </div>
  ))
};

export default EditEnquiries;
