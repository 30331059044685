import { useEffect } from "react";
import Input from "../../../commonComponents/Input/Input";
import { scrollToTop } from "../../../utils/helpers";

const Costing = ({ inputs, errors, formPlug, inputHandler ,setErrors}) => {
  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop()
  }, []);

  return (
    <>
      <div>
        <h5 className="package__header">Costing</h5>

        <div className="row mb-4">
          <div className="row">
            <div className="col-6">
              <Input
                type="number"
                className="css-inp-esc1 mb-3"
                label="Fixed Package Cost (Per Adult)"
                placeholder="Type here"
                required={{
                  message: "Please enter cost",
                }}
                field={"fixed_package_cost"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
                validation={{maxChar: 10}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Costing;
