import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const logout_loader = (state ) => state.commonReducer.logout_loader;
export const login_loading = (state ) => state.commonReducer.login_loading;

export const season_data = (state ) => state.commonReducer.season_data;
export const season_page_details = (state ) => state.commonReducer.season_page_details;
export const season_path = (state ) => state.commonReducer.season_path;
export const detail_season_loading = (state ) => state.commonReducer.detail_season_loading;
export const season_details = (state ) => state.commonReducer.season_details;
export const update_season_loading = (state ) => state.commonReducer.update_season_loading;
export const season_loading = (state ) => state.commonReducer.season_loading;

export const attraction_data = (state ) => state.commonReducer.attraction_data;
export const attraction_page_details = (state ) => state.commonReducer.attraction_page_details;
export const attraction_path = (state ) => state.commonReducer.attraction_path;
export const update_attraction_loading = (state ) => state.commonReducer.update_attraction_loading;
export const detail_attraction_loading = (state ) => state.commonReducer.detail_attraction_loading;
export const attraction_details = (state ) => state.commonReducer.attraction_details;
export const attraction_loading = (state ) => state.commonReducer.attraction_loading;

export const update_activity_loading = (state ) => state.commonReducer.update_activity_loading;
export const activity_data = (state ) => state.commonReducer.activity_data;
export const activity_page_details = (state ) => state.commonReducer.activity_page_details;
export const activity_path = (state ) => state.commonReducer.activity_path;
export const detail_activity_loading = (state ) => state.commonReducer.detail_activity_loading;
export const activity_details = (state ) => state.commonReducer.activity_details;
export const activity_loading = (state ) => state.commonReducer.activity_loading;

export const inclusion_data = (state ) => state.commonReducer.inclusion_data;
export const inclusion_page_details = (state ) => state.commonReducer.inclusion_page_details;
export const inclusion_loading = (state ) => state.commonReducer.inclusion_loading;

export const exclusion_data = (state ) => state.commonReducer.exclusion_data;
export const exclusion_page_details = (state ) => state.commonReducer.exclusion_page_details;
export const exclusion_loading = (state ) => state.commonReducer.exclusion_loading;

export const update_faq_loading = (state ) => state.commonReducer.update_faq_loading;
export const faq_data = (state ) => state.commonReducer.faq_data;
export const faq_page_details = (state ) => state.commonReducer.faq_page_details;
export const detail_faq_loading = (state ) => state.commonReducer.detail_faq_loading;
export const faq_details = (state ) => state.commonReducer.faq_details;
export const faq_loading = (state ) => state.commonReducer.faq_loading;

export const questionnaire_data = (state ) => state.commonReducer.questionnaire_data;
export const questionnaire_page_details = (state ) => state.commonReducer.questionnaire_page_details;
export const questionnaire_loading = (state ) => state.commonReducer.questionnaire_loading;

export const detail_hotel_loading = (state ) => state.commonReducer.detail_hotel_loading;
export const hotel_details = (state ) => state.commonReducer.hotel_details;
export const hotel_path = (state ) => state.commonReducer.hotel_path;
export const hotel_partners_path = (state ) => state.commonReducer.hotel_partners_path;
export const hotel_gallery_path = (state ) => state.commonReducer.hotel_gallery_path;

export const update_room_loading = (state ) => state.commonReducer.update_room_loading;
export const detail_room_loading = (state ) => state.commonReducer.detail_room_loading;
export const room_details = (state ) => state.commonReducer.room_details;

export const update_vehicle_costing_loading = (state ) => state.commonReducer.update_vehicle_costing_loading;
export const detail_vehicle_costing_loading = (state ) => state.commonReducer.detail_vehicle_costing_loading;
export const vehicle_costing_details = (state ) => state.commonReducer.vehicle_costing_details;

export const update_child_cost_loading = (state ) => state.commonReducer.update_child_cost_loading;
export const detail_child_cost_loading = (state ) => state.commonReducer.detail_child_cost_loading;
export const child_cost_details = (state ) => state.commonReducer.child_cost_details;

export const update_flight_cost_loading = (state ) => state.commonReducer.update_flight_cost_loading;
export const detail_flight_cost_loading = (state ) => state.commonReducer.detail_flight_cost_loading;
export const flight_cost_details = (state ) => state.commonReducer.flight_cost_details;

export const update_payment_milestone_loading = (state ) => state.commonReducer.update_payment_milestone_loading;
export const payment_milestone_data = (state ) => state.commonReducer.payment_milestone_data;
export const payment_milestone_page_details = (state ) => state.commonReducer.payment_milestone_page_details;
export const detail_payment_milestone_loading = (state ) => state.commonReducer.detail_payment_milestone_loading;
export const payment_milestone_details = (state ) => state.commonReducer.payment_milestone_details;
export const payment_milestone_loading = (state ) => state.commonReducer.payment_milestone_loading;

export const update_currencyConversion_loading = (state ) => state.commonReducer.update_currencyConversion_loading;
export const currencyConversion_data = (state ) => state.commonReducer.currencyConversion_data;
export const currencyConversion_page_details = (state ) => state.commonReducer.currencyConversion_page_details;
export const detail_currencyConversion_loading = (state ) => state.commonReducer.detail_currencyConversion_loading;
export const currencyConversion_details = (state ) => state.commonReducer.currencyConversion_details;
export const currency_loading = (state ) => state.commonReducer.currency_loading;

export const update_flight_loading = (state ) => state.commonReducer.update_flight_loading;
export const flight_details = (state ) => state.commonReducer.flight_details;
export const flight_data = (state ) => state.commonReducer.flight_data;
export const flight_page_details = (state ) => state.commonReducer.flight_page_details;
export const flight_path = (state ) => state.commonReducer.flight_path;
export const detail_flight_loading = (state ) => state.commonReducer.detail_flight_loading;
export const flight_loading = (state ) => state.commonReducer.flight_loading;

export const fileUploadLoader = (state ) => state.commonReducer.fileUploadLoader;

export const update_vehicle_loading = (state ) => state.commonReducer.update_vehicle_loading;
export const vehicle_data = (state ) => state.commonReducer.vehicle_data;
export const vehicle_page_details = (state ) => state.commonReducer.vehicle_page_details;
export const detail_vehicle_loading = (state ) => state.commonReducer.detail_vehicle_loading;
export const vehicle_details = (state ) => state.commonReducer.vehicle_details;
export const vehicle_path = (state ) => state.commonReducer.vehicle_path;
export const vehicle_loading = (state ) => state.commonReducer.vehicle_loading;

export const update_vehicle_partners_loading = (state ) => state.commonReducer.update_vehicle_partners_loading;
export const detail_vehicle_partners_loading = (state ) => state.commonReducer.detail_vehicle_partners_loading;
export const vehicle_partners_details = (state ) => state.commonReducer.vehicle_partners_details;
export const vehicle_partners_path = (state ) => state.commonReducer.vehicle_partners_path;

export const update_curated_loading = (state ) => state.commonReducer.update_curated_loading;
export const detail_curated_loading = (state ) => state.commonReducer.detail_curated_loading;
export const curated_details = (state ) => state.commonReducer.curated_details;
export const curated_path = (state ) => state.commonReducer.curated_path;

export const update_about_loading = (state ) => state.commonReducer.update_about_loading;
export const detail_about_loading = (state ) => state.commonReducer.detail_about_loading;
export const about_details = (state ) => state.commonReducer.about_details;
export const about_path = (state ) => state.commonReducer.about_path;

export const full_attraction_list = (state ) => state.commonReducer.full_attraction_list;
export const full_review_list = (state ) => state.commonReducer.full_review_list;
export const review_path = (state ) => state.commonReducer.review_path;

export const update_home_loading = (state ) => state.commonReducer.update_home_loading;
export const detail_home_loading = (state ) => state.commonReducer.detail_home_loading;
export const home_details = (state ) => state.commonReducer.home_details;
export const home_path = (state ) => state.commonReducer.home_path;

export const update_tc_loading = (state ) => state.commonReducer.update_tc_loading;
export const tc_path = (state ) => state.commonReducer.tc_path;
export const detail_tc_loading = (state ) => state.commonReducer.detail_tc_loading;
export const tc_details = (state ) => state.commonReducer.tc_details;

export const update_booking_loading = (state ) => state.commonReducer.update_booking_loading;
export const detail_booking_loading = (state ) => state.commonReducer.detail_booking_loading;
export const booking_details = (state ) => state.commonReducer.booking_details;
export const booking_path = (state ) => state.commonReducer.booking_path;

export const update_cancellation_loading = (state ) => state.commonReducer.update_cancellation_loading;
export const detail_cancellation_loading = (state ) => state.commonReducer.detail_cancellation_loading;
export const cancellation_details = (state ) => state.commonReducer.cancellation_details;
export const cancellation_path = (state ) => state.commonReducer.cancellation_path;

export const update_privacy_loading = (state ) => state.commonReducer.update_privacy_loading;
export const detail_privacy_loading = (state ) => state.commonReducer.detail_privacy_loading;
export const privacy_details = (state ) => state.commonReducer.privacy_details;
export const privacy_path = (state ) => state.commonReducer.privacy_path; 

export const update_blog_loading = (state ) => state.commonReducer.update_blog_loading;
export const blog_data = (state ) => state.commonReducer.blog_data;
export const blog_page_details = (state ) => state.commonReducer.blog_page_details;
export const blog_path = (state ) => state.commonReducer.blog_path;
export const detail_blog_loading = (state ) => state.commonReducer.detail_blog_loading;
export const blog_details = (state ) => state.commonReducer.blog_details;
export const blog_loading = (state ) => state.commonReducer.blog_loading;

export const package_list = (state ) => state.commonReducer.package_list;

export const update_package_loading = (state ) => state.commonReducer.update_package_loading;
export const detail_package_loading = (state ) => state.commonReducer.detail_package_loading;
export const package_details = (state ) => state.commonReducer.package_details;
export const package_path = (state ) => state.commonReducer.package_path;
export const package_data = (state ) => state.commonReducer.package_data;
export const package_page_details = (state ) => state.commonReducer.package_page_details;
export const package_loading = (state ) => state.commonReducer.package_loading;

export const enquiries_data = (state ) => state.commonReducer.enquiries_data;
export const enquiry_loading = (state ) => state.commonReducer.enquiry_loading;
export const update_enquiry_loading  = (state ) => state.commonReducer.update_enquiry_loading;
export const enquiry_details_path  = (state ) => state.commonReducer.enquiry_details_path;
export const enquiries_page_details = (state ) => state.commonReducer.enquiries_page_details;
export const detail_enquiry_loading = (state ) => state.commonReducer.detail_enquiry_loading;
export const enquiry_details = (state ) => state.commonReducer.enquiry_details;

export const status_list = (state ) => state.commonReducer.status_list;

export const essential_info_details = (state ) => state.commonReducer.essential_info_details;

export const update_contact_loading = (state ) => state.commonReducer.update_contact_loading;
export const contact_details = (state ) => state.commonReducer.contact_details;
export const contact_path = (state ) => state.commonReducer.contact_path;
export const detail_contact_loading = (state ) => state.commonReducer.detail_contact_loading;

export const booking_update_loading = (state) => state.commonReducer.booking_update_loading 
export const booking_data = (state ) => state.commonReducer.booking_data;
export const booking_page = (state ) => state.commonReducer.booking_page;
export const booking_detail_loading = (state ) => state.commonReducer.booking_detail_loading;
export const details_booking = (state ) => state.commonReducer.details_booking;

export const update_general_loading = (state) => state.commonReducer.update_general_loading 
export const general_details = (state ) => state.commonReducer.general_details;
export const general_path = (state ) => state.commonReducer.general_path;
export const detail_general_loading = (state ) => state.commonReducer.detail_general_loading;
export const country_list = (state ) => state.commonReducer.country_list;

export const update_package_page_loading = (state) => state.commonReducer.update_package_page_loading 
export const packagepage_details = (state ) => state.commonReducer.packagepage_details;
export const package_page_path = (state ) => state.commonReducer.package_page_path;
export const package_page_loading = (state ) => state.commonReducer.package_page_loading;

export const update_promocode_loading = (state ) => state.commonReducer.update_promocode_loading;
export const promocode_data = (state ) => state.commonReducer.promocode_data;
export const promocode_page_details = (state ) => state.commonReducer.promocode_page_details;
export const details_promocode_loading = (state ) => state.commonReducer.details_promocode_loading;
export const promocode_details = (state ) => state.commonReducer.promocode_details;
export const promocode_loading = (state ) => state.commonReducer.promocode_loading;

export const update_permission_loading = (state ) => state.commonReducer.update_permission_loading;
export const permission_data = (state ) => state.commonReducer.permission_data;
export const permission_path = (state ) => state.commonReducer.permission_path;
export const permission_page_details = (state ) => state.commonReducer.permission_page_details;
export const details_permission_loading = (state ) => state.commonReducer.details_permission_loading;
export const permission_details = (state ) => state.commonReducer.permission_details;
export const permission_loading = (state ) => state.commonReducer.permission_loading;

export const modules_loading = (state ) => state.commonReducer.modules_loading;
export const modules_data = (state ) => state.commonReducer.modules_data;

export const setpassword_loading = (state ) => state.commonReducer.setpassword_loading;

export const account_info_data = (state ) => state.commonReducer.account_info_data;
export const update_accountInfo_loading = (state ) => state.commonReducer.update_accountInfo_loading;

export const update_review_loading = (state ) => state.commonReducer.update_review_loading;
export const review_data = (state ) => state.commonReducer.review_data;
export const reviews_path = (state ) => state.commonReducer.reviews_path;
export const review_page_details = (state ) => state.commonReducer.review_page_details;
export const details_review_loading = (state ) => state.commonReducer.details_review_loading;
export const review_details = (state ) => state.commonReducer.review_details;
export const review_loading = (state ) => state.commonReducer.review_loading;

export const payment_milestone_total = (state ) => state.commonReducer.payment_milestone_total;

export const update_status_loading = (state ) => state.commonReducer.update_status_loading;

export const forgot_password_loading = (state ) => state.commonReducer.forgot_password_loading;
export const reset_password_loading = (state ) => state.commonReducer.reset_password_loading;

export const airport_codes = (state ) => state.commonReducer.airport_codes;
export const add_booking_loading = (state ) => state.commonReducer.add_booking_loading;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;

export default commonSlice.reducer;