import React, { useEffect, useRef, useState } from "react";
import "./TextEditor.scss";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

let validationObj = {
  minChar: { message: "", condition: "", length: "" },
  maxChar: { message: "", condition: "", length: "" },
  alphabetsOnly: { message: "", condition: "", more: "" },
  alphaNumeric: { message: "", condition: "", more: "" },
};

export default function TextEditor({
  label,
  value,
  onChange,
  field,
  placeholder,
  inputSocket,
  className,
  error,
  required,
  validation = validationObj,
  condition,
  clearOnHide,
  disabled,
  lang,
  dontTranslate,
  style,
  onBlur,
  config,
}) {
  const reactQuillRef = useRef(null);
  let uuid = v4();
  const editor = useRef();

  const [inValue, setInValue] = useState("");
  const [inLabel, setInLabel] = useState("");
  const [inPlaceholder, setInPlaceholder] = useState("");
  const [inError, setInError] = useState("");

  useEffect(() => {
    setInValue(() => {
      let res = "";
      if (typeof value === "object" && value?.[field] !== undefined)
        res = value?.[field];
      else if (typeof value === "object" && value?.[field] === undefined)
        res = "";
      else if (value !== undefined) res = value;
      return res;
    });
    placeholder && setInPlaceholder(placeholder);
    label && setInLabel(label);
    return () => {
      setInValue("");
      setInPlaceholder("");
      setInLabel("");
    };
  }, [value, placeholder, label]);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      if (ifObjectExist(validation.minChar)) obj.minChar = validation.minChar;
      if (ifObjectExist(validation.maxChar)) obj.maxChar = validation.maxChar;
      if (ifObjectExist(validation.alphabetsOnly))
        obj.alphabetsOnly = validation.alphabetsOnly;
      if (ifObjectExist(validation.alphaNumeric))
        obj.alphaNumeric = validation.alphaNumeric;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join("").length > 0 || obj === true;
  }
  const generateTrimmedHTML = (htmlContent, truncatedText) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent; 
    const nodes = tempDiv.childNodes;
    let currentLength = 0;

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (currentLength >= truncatedText.length) {
        tempDiv.removeChild(node); 
        i--;
      } else {
        if (node.nodeType === Node.TEXT_NODE) {
          const remainingLength = truncatedText.length - currentLength;
          if (node.textContent.length > remainingLength) {
            node.textContent = node.textContent.substring(0, remainingLength);
          }
          currentLength += node.textContent.length;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const innerText = node.innerText || "";
          const remainingLength = truncatedText.length - currentLength;
          if (innerText.length > remainingLength) {
            node.innerText = innerText.substring(0, remainingLength);
          }
          currentLength += innerText.length;
        }
      }
    }

    return tempDiv.innerHTML; 
  };

  function onChangeHandler(content, delta, source, editor) {
        
       if(content.length  <= validation.maxChar){
        console.log("245")
      setInValue(content);
      onChange && onChange(content, field);
       }else{
        console.log("23")
        return
       }
   
  }
  const checkCharacterCount = (event) => {
    if (event?.target?.innerText.trim().length === 0) {
      setInValue("");
      onChange && onChange("", field);
    }
    if (
      event?.target?.innerText.length > validation.maxChar - 1 &&
      event.key !== "Backspace" &&
      validation.maxChar
    )
      event.preventDefault();
  };
  if (!condition && condition !== undefined) return null;

  return (
    <div id="TextEditor" className={className}>
      {inLabel && (
        <label htmlFor={uuid}>
          {inLabel}
          {(required?.condition !== undefined
            ? required?.condition
            : required) && <em>*</em>}
        </label>
      )}
      <ReactQuill
        ref={editor}
        id={uuid}
        theme={"snow"}
        // onPaste={checkCharacterCount}
        onKeyDown={checkCharacterCount}
        onChange={onChangeHandler}
        value={inValue}
        placeholder={inPlaceholder}
        modules={{
          toolbar: [
            // [{ 'direction': 'rtl' }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ indent: "-1" }, { indent: "+1" }],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          // "indent",
          "blockquote",
          "direction",
          "align",
          "link",
          "image",
          "video",
        ]}
        onFocus={(e, a, b) => {
          let val = b.getText().replace("\n", "");
          if (editor.current && val.length === 0) {
            const edit = editor.current.getEditor();
            // edit.format("direction", lang === "en" ? "ltr" : "rtl");
          }
        }}
      />
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className="error">
          {inError ||
            (typeof error === "object" ? error?.[field] || "" : error)}
        </small>
      )}
    </div>
  );
}

{
  /* 

<TextArea
  className={'css-txt-esc1'}
  label={'Comment'}
  error={'error message'}
  placeholder={'Comment here'}
  value={''}
  onChange={(e) => { }}
/> 

*/
}
