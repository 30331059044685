import React, { useEffect, useState } from "react";
import Input from "../commonComponents/Input/Input";
import useForm from "../utils/Hooks/useForm";
import TextArea from "../commonComponents/TextArea/TextArea";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import {
  getGeneralDetails,
  insertGeneral,
  uploadFile,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { general_details, general_path } from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";
import SupportedFormats from "../commonComponents/SupportedFormats/SupportedFormats";

const GeneralPage = () => {
  const [trackId, setTrackId] = useState("");

  const [invalidUpload, setInvalidUpload] = useState(false);

  const dispatch = useDispatch();
  const details = useSelector(general_details);
  const path = useSelector(general_path);
  useEffect(() => {
    dispatch(getGeneralDetails());
  }, []);

  useEffect(() => {
    if (details) {
      let result = {
        blog_title: details?.blog_title || "",
        blog_description: details?.blog_description || "",
        token_payment: details?.token_payment || "",
        search_tagline:details?.search_tagline,
        search_title:details?.search_title,
        footer_logo: details?.footer_logo
          ? [
              {
                type: details?.footer_logo.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.footer_logo}`,
                name: details?.footer_logo,
                response: details?.footer_logo,
              },
            ]
          : "",
        footer_logo_alt: details?.footer_logo_alt || "",
      };

      if (details?.social_media?.length) {
        const socialMediaArr = details.social_media.map((ele) => ({
          media_icon: ele?.media_icon
            ? [
                {
                  type: ele?.media_icon.split(".").pop(),
                  url: `${IMAGE_BASE_URL}${path}/${ele?.media_icon}`,
                  name: ele?.media_icon,
                  response: ele?.media_icon,
                },
              ]
            : "",
          media_icon_alt: ele?.media_icon_alt || "",
          link: ele?.link || "",
        }));
        result = {
          ...result,
          social_media: socialMediaArr,
        };
      }

      setInputs({ ...result });
    }
  }, [details, path]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    social_media: [{ media_icon: "", media_icon_alt: "", link: "" }],
  });
  const socialMediaValidator = (error) => {
    let temp = {};

    if (inputs?.social_media?.length) {
      inputs?.social_media?.forEach((ele, i) => {
        if (ele?.media_icon === "") {
          temp[`${"social_media"}_${"media_icon"}_${i}`] = "Please upload icon";
        }
        if (!ele?.media_icon_alt?.trim()?.length) {
          temp[`${"social_media"}_${"media_icon_alt"}_${i}`] =
            "Please enter alt text";
        }
        if (!ele?.link?.trim()?.length) {
          temp[`${"social_media"}_${"link"}_${i}`] = "Please enter link";
        }
      });
    }

    if (Object.values(temp).length != 0) {
      setErrors({
        ...error,
        ...temp,
      });
      return false;
    } else {
      setErrors({ ...error });
      return true;
    }
  };

  const inputDataChangeHandler = (key, value, index, field) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const handleRemoveField = (index) => {
    setInputs((prev) => ({
      ...prev,
      social_media: prev.social_media.filter((_, i) => i !== index),
    }));
  };

  const handleAddField = () => {
    setInputs((prev) => ({
      ...prev,
      social_media: [
        ...prev.social_media,
        { media_icon: "", media_icon_alt: "", link: "" },
      ],
    }));
  };

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const removeIcon = (index) => {
    setInputs((prev) => {
      const updatedSocialMedia = prev.social_media.map((item, idx) =>
        idx === index ? { ...item, media_icon: "" } : item
      );
      return {
        ...prev,
        social_media: updatedSocialMedia,
      };
    });
  };

  const singleFileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    mainField
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "cms");
        dispatch(
          uploadFile(formData, (res) => {
            let tempValue = [
              {
                type: e[0].type,
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ];
            if (field === "media_icon") {
              setFunction(field, tempValue, index, mainField);
            } else {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: tempValue,
              }));
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    dispatch(getGeneralDetails());
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });

    let isValidated2 = await socialMediaValidator(isValidated?.err);

    if (isValidated?.valid && !invalidUpload && isValidated2) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let raw = {
      blog_title: inputs?.blog_title,
      blog_description: inputs?.blog_description,
      token_payment: inputs?.token_payment,
      footer_logo: inputs?.footer_logo?.[0]?.response ?? "",
      footer_logo_alt: inputs?.footer_logo_alt,
      search_tagline:inputs?.search_tagline,
      search_title: inputs?.search_title,
      track_id: trackId,
      social_media: inputs.social_media.map((item) => ({
        media_icon: item.media_icon?.[0]?.response ?? "",
        media_icon_alt: item.media_icon_alt,
        link: item.link,
      })),
      path : "cms"
    };
    console.log(raw);
    dispatch(
      insertGeneral(raw, (res) => {
        if (res) {
          onCancelHandler();
        }
      })
    );
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Blog Page </h5>
            <div className="row">
              <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label=" Blog Title"
                  placeholder="Type here"
                  required={{ message: "Please enter title" }}
                  field={"blog_title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12  mb-3">
                <TextArea
                  label={" Short Description"}
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  field={"blog_description"}
                  value={inputs.blog_description}
                  error={errors.blog_description}
                  inputSocket={formPlug}
                  // validation={{maxChar:200}}
                  onChange={inputHandler}
                  required={{ message: "Please enter description" }}
                />
              </div>
              
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Token Payment</h5>
            <div className="row">
              <div className="col-12">
                <Input
                  type={"number"}
                  className="css-inp-esc1 mb-3"
                  label="Reserve Payment"
                  placeholder="Type here"
                  required={{ message: "Please enter payment" }}
                  field={"token_payment"}
                  validation={{ maxChar: 10 }}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Social Media</h5>
            <div className="row">
              {inputs?.social_media?.length >0 ? (
              inputs?.social_media?.map((ele, index) => (
                <div key={ele._id} className="row mb-2  align-items-center">
                  <div className="col-4">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5 image2 mt-3"
                      label="Icon"
                      accept={["jpg", "jpeg", "png", "svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeIcon(index)}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          media_icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "media_icon",
                          inputDataChangeHandler,
                          index,
                          "social_media"
                        );
                      }}
                      error={
                        errors[`${"social_media"}_${"media_icon"}_${index}`]
                      }
                      width={28}
                      field={"media_icon"}
                      id="media_icon"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={ele?.media_icon}
                      inputSocket={formPlug}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1} Media icon Alt`}
                      placeholder="Type here"
                      field={"media_icon_alt"}
                      inputSocket={formPlug}
                      value={ele?.media_icon_alt}
                      onChange={(e, key) => {
                        inputDataChangeHandler(key, e, index, "social_media");
                      }}
                      error={
                        errors[`${"social_media"}_${"media_icon_alt"}_${index}`]
                      }
                    />
                    <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1} Link`}
                      placeholder="Type here"
                      field={"link"}
                      inputSocket={formPlug}
                      value={ele?.link}
                      onChange={(e, key) => {
                        inputDataChangeHandler(key, e, index, "social_media");
                      }}
                      error={errors[`${"social_media"}_${"link"}_${index}`]}
                    />
                  </div>
                  <div className="col-12 col-md-3 preview-imagemargin d-flex gap-2">
                    {index === inputs.social_media.length - 1 ? (
                      <button
                        className="btn btn-primary  "
                        onClick={handleAddField}
                      >
                        Add More
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btn btn-danger "
                      onClick={() => handleRemoveField(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))):(
                <div className="col-4">
                <button
                  className="btn btn-primary "
                  style={{ "width": "150px" }}
                  onClick={() => handleAddField}
                >
                  Add
                </button>
              </div>
              )}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Search Section Content</h5>
            <div className="row">
              <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Search Title"
                  placeholder="Type here"
                  required={{ message: "Please enter content" }}
                  field={"search_title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12  mb-3">
                <TextArea
                  label={"Search Tagline"}
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  field={"search_tagline"}
                  value={inputs.search_tagline}
                  error={errors.search_tagline}
                  inputSocket={formPlug}
                  // validation={{maxChar:200}}
                  onChange={inputHandler}
                  required={{ message: "Please enter tagline" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Common Footer</h5>
            <div className="row">
              <div className="col-12">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc5 mt-3"
                  label="Banner Image"
                  accept={["jpg", "jpeg", "png","svg"]}
                  icon={"/assets/img/icons/plus_black.svg"}
                  closeIcon={
                    <AiOutlineClose
                      size={30}
                      className="remove-icon"
                      onClick={() => removeImage("footer_logo")}
                    />
                  }
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      footer_logo: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "footer_logo"
                    );
                  }}
                  error={errors.footer_logo}
                  width={28}
                  field={"footer_logo"}
                  id="footer_logo"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.footer_logo}
                  required={{ message: "Please Upload Image" }}
                  inputSocket={formPlug}
                />
                <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
              </div>
              <div className="col-6 mt-3">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Footer Logo Alt"
                  placeholder="Type here"
                  // required={{ message: "Please enter logo Att" }}
                  field={"footer_logo_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-3"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralPage;
