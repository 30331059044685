import React from "react";
import Input from "../commonComponents/Input/Input";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import TextArea from "../commonComponents/TextArea/TextArea";
import SupportedFormats from "../commonComponents/SupportedFormats/SupportedFormats";

const PackageFooter = ({
  header_title,
  short_title,
  short_description,
  data,
  title,
  errors,
  inputs,
  inputHandler,
  formPlug,
  setErrors,
  singleFileUploadHandler,
  inputDataChangeHandler,
  field,
  removeIcon,
  handleAddField,
  handleRemoveField
}) => {

  return (
    <div>
      <h5 className="card-title border-style mb-3">{header_title} </h5>

      <div className="col-12 mb-3 border-style">
        <Input
          className="css-inp-esc1 mb-3"
          label=" Title"
          placeholder="Type here"
          required={{ message: "Please enter title" }}
          field={title}
          value={inputs}
          error={errors}
          inputSocket={formPlug}
          onChange={inputHandler}
        />
      </div>
      {data?.length > 0 ? (
        data?.map((ele, index) => (
          <div key={ele._id} className="row mb-2 ">
            <div className="col-4 mb-2">
              <FileUpload
                style={{
                  marginRight: 5,
                  marginTop: 15,
                }}
                className="css-atc-esc5 image2 mt-0 "
                label={<p>Icon<em>*</em></p>}
                accept={["jpg", "jpeg", "png", "svg"]}
                icon={"/assets/img/icons/plus_black.svg"}
                closeIcon={
                  <AiOutlineClose
                    size={30}
                    className="remove-icon"
                    onClick={() => removeIcon(index, field)}
                  />
                }
                onChange={(e, isInvalid, message, isClosing) => {
                  setErrors((s) => ({
                    ...s,
                    icon: "",
                  }));
                  singleFileUploadHandler(
                    e,
                    isInvalid,
                    message,
                    isClosing,
                    "icon",
                    inputDataChangeHandler,
                    index,
                    field
                  );
                }}
                error={errors[`${field}_${"icon"}_${index}`]}
                width={28}
                field={"icon"}
                id="icon"
                maxFileSizeMB={50}
                multiple={false}
                showPreview
                files={ele?.icon}
                inputSocket={formPlug}
              />
              <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
            </div>
            <div className="col-12">
              <Input
                className="css-inp-esc1 mb-3"
                label={`${index + 1}. Title`}
                placeholder="Type here"
                field={"title"}
                // required={{ message: "Please Enter title" }}
                value={ele?.title}
                inputSocket={formPlug}
                onChange={(e, key) => {
                  inputDataChangeHandler(key, e, index, field);
                }}
                error={errors[`${field}_${"title"}_${index}`]}
              />
            </div>
            <div className="col-12">
              <Input
                className="css-inp-esc1 mb-3"
                label={`${index + 1}. Icon Alt`}
                placeholder="Type here"
                field={"icon_alt"}
                inputSocket={formPlug}
                value={ele?.icon_alt}
                onChange={(e, key) => {
                  inputDataChangeHandler(key, e, index, field);
                }}
                error={errors[`${field}_${"icon_alt"}_${index}`]}
              />


            </div>
            <div className="col-12 mb-3">
              <TextArea
                label={"Short Description"}
                className={"css-txt-esc1"}
                placeholder={"Type here"}
                field={"description"}
                
                inputSocket={formPlug}
                value={ele?.description}
                onChange={(e, key) => {
                  inputDataChangeHandler(key, e, index, field);
                }}
                error={errors[`${field}_${"description"}_${index}`]}
              />
            </div>
            <div className="col-12  d-flex justify-content-end gap-2">
              {index === data.length - 1 ? (
                <button className="btn btn-primary  " onClick={() => handleAddField(field)}>
                  Add
                </button>
              ) : (
                <></>
              )}
              <button
                className="btn btn-danger "
                onClick={() => handleRemoveField(index, field)}
              >
                Delete
              </button>
            </div>
          </div>
        ))) : (
        <div className="col-4">
          <button
            className="btn btn-primary "
            style={{ "width": "150px" }}
            onClick={() => handleAddField(field)}
          >
            Add
          </button>
        </div>
      )}

      <div className="row">
        <div className="col-6 mb-3">
          <TextArea
            label={"Short Title"}
            className={"css-txt-esc1"}
            placeholder={"Type here"}
            field={short_title}
            value={inputs}
            error={errors}
            inputSocket={formPlug}
            onChange={inputHandler}
            required={{ message: "Please Enter title" }}
          />
          {/* <Input
            className="css-inp-esc1 mb-3"
            // label=" Short Title"
            placeholder="Type here"
            // required={{ message: "Please Enter title" }}
            // field={short_title}
            value={inputs}
            error={errors}
            inputSocket={formPlug}
            onChange={inputHandler}
          /> */}
        </div>
        <div className="col-6 mb-3">
          <TextArea
            label={"Short Description"}
            className={"css-txt-esc1"}
            placeholder={"Type here"}
            field={short_description}
            value={inputs}
            error={errors}
            inputSocket={formPlug}
            onChange={inputHandler}
            required={{ message: "Please Enter title" }}
          />
          {/* <Input
            className="css-inp-esc1 mb-3"
            label=" Short Description"
            placeholder="Type here"
            required={{ message: "Please Enter Description" }}
            field={short_description}
            value={inputs}
            error={errors}
            inputSocket={formPlug}
            onChange={inputHandler}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default PackageFooter;
