import React, { useEffect, useRef, useState } from "react";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgot_password_loading, login_loading } from "../../redux/commonReducer";
import { login, forgotPassword } from "../../redux/actionCreator"; // Assume you have a forgotPassword action
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import cryptoJs from "crypto-js";
import { v4 } from "uuid";

export default function Login() {
  const pwdInput = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uuid = v4();
  const loading = useSelector(login_loading);
  const passwordLoading = useSelector(forgot_password_loading)

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);

  const handleSubmit = (e) => {
    e?.preventDefault();
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for validating email format
  
    if (isForgotPassword) {
      if (username.trim() === "") {
        toast.error("Please enter your email", {
          position: "bottom-center",
          autoClose: 3000,
        });
      } else if (!emailRegex.test(username.trim())) {
        toast.error("Please enter a valid email address", {
          position: "bottom-center",
          autoClose: 3000,
        });
      } else {
        dispatch(
          forgotPassword({ email: username }, (msg) => {
            if (msg === true) {
              setIsForgotPassword(false);
              setUsername("");
            }
          })
        );
      }
    } else {
      // Login logic
      if (username?.trim() === "" || password?.trim() === "") {
        toast.error("Please enter email and password", {
          position: "bottom-center",
          autoClose: 3000,
        });
      } else if (!emailRegex.test(username.trim())) {
        toast.error("Please enter a valid email address", {
          position: "bottom-center",
          autoClose: 3000,
        });
      } else {
        const prepareNewPasswordData = (password, uuid, token) => {
          const chunkSize = 15;
          let encryptedPassword;
          if (password.length > chunkSize) {
            const chunks = [];
            for (let i = 0; i < password.length; i += chunkSize) {
              const chunk = password.slice(i, i + chunkSize);
              chunks.push(cryptoJs.AES.encrypt(chunk, uuid).toString());
            }
            encryptedPassword = chunks.join('|');
          } else {
            encryptedPassword = cryptoJs.AES.encrypt(password, uuid).toString();
          }

          const encodedData =
            encryptedPassword + "_" + window.btoa(uuid + "_" + token);
      
          return encodedData;
        };
        const new_password = prepareNewPasswordData(password, uuid,username);
        const data = {
          email: username,
          password:new_password
        }
        dispatch(
          login(data, (msg) => {
            if (msg === true) {
              navigate("/");
              toast.success("Login successfully", {
                position: "bottom-center",
                autoClose: 3000,
              });
            }
          })
        );
      }
    }
  };

  const onUsernamekeyPress = (e) => {
    if (e.key === "Enter") {
      pwdInput.current?.focus();
    }
  };

  const onEnterLogin = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div id="login-page">
      <div className="container">
        <div className="c-r row justify-content-md-center">
          <div className="col-md-12 col-lg-4">
            <div className="card login-box-container">
              <div className="card-body">
                <div className="authent-logo">
                  <img
                    width={100}
                    className="logo_img"
                    src="/assets/img/logo.png"
                    alt="Logo"
                  />
                </div>
                <div className="authent-text">
                  <p>Welcome to SKYWARD</p>
                  <p>
                    {isForgotPassword
                      ? "Enter your email to reset your password."
                      : "Please Sign-in to your account."}
                  </p>
                </div>

                <form>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value = {username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="off"
                        onKeyDown={(e) => onUsernamekeyPress(e)}
                      />
                      <label htmlFor="floatingInput">Email address</label>
                    </div>
                  </div>

                  {/* Show Password field only for Login */}
                  {!isForgotPassword && (
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          ref={pwdInput}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          autoComplete="off"
                          onKeyDown={(e) => onEnterLogin(e)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                    </div>
                  )}

                  {/* Toggle between Forgot Password and Login */}
                  <div className="text-end">
                    {!isForgotPassword && (
                      <p
                        className="forgot_password_word"
                        onClick={() => setIsForgotPassword(true)}

                      >
                        Forgot password?
                      </p>
                    )}
                    {isForgotPassword && (
                      <p
                        className="forgot_password_word"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        Back to Login
                      </p>
                    )}
                  </div>

                  <div className="d-grid">
                    <button
                      type="submit"
                      className="btn btn-info m-b-xs"
                      onClick={(e)=>handleSubmit(e)}
                      disabled={isForgotPassword ? passwordLoading : loading}
                    >
                      {isForgotPassword ? (
                        passwordLoading ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          "Reset Password"
                        )
                      ) : loading ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        "Login"
                      )}
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
