import React, { useEffect, useRef, useState } from "react";
import feather from "feather-icons";
import Seo from "../components/Seo";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutDetail,
  insertAbout,
  updateAbout,
  uploadFile,
} from "../redux/actionCreator";
import {
  about_details,
  about_path,
  detail_about_loading,
  update_about_loading,
} from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";
import LoadingSpinner from "../commonComponents/LoadingSpinner/LoadingSpinner";
import TextEditor from "../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../utils/editorConfig";
import useForm from "../utils/Hooks/useForm";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import Input from "../commonComponents/Input/Input";
import SupportedFormats from "../commonComponents/SupportedFormats/SupportedFormats";

export default function About() {
  const [invalidUpload, setInvalidUpload] = useState(false);
  const dispatch = useDispatch();

  const submitLoading = useSelector(update_about_loading);
  const loading = useSelector(detail_about_loading);
  const details = useSelector(about_details);
  const path = useSelector(about_path);

  const [data, setData] = useState({
    title: "",
    short_description: "",
    banner_image1_alt: "",
    banner_image2_alt: "",
    vision_title: "",
    vision_description: "",
    vision_image_alt: "",
    vision_icon_alt: "",
    mission_title: "",
    mission_description: "",
    mission_image_alt: "",
    mission_icon_alt: "",
    main_title: "",
    main_image_alt: "",
    // features: [
    //   { title: "", icon: "", icon_alt: "" },
    //   { title: "", icon: "", icon_alt: "" },
    //   { title: "", icon: "", icon_alt: "" },
    //   { title: "", icon: "", icon_alt: "" },
    //   { title: "", icon: "", icon_alt: "" },
    //   { title: "", icon: "", icon_alt: "" },
    // ],
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    features: [
      {
        title: "",
        icon_alt: "",
        icon: "",
      },
    ],
  });

  useEffect(() => {
    dispatch(getAboutDetail());
  }, []);

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        title: details?.title,
        short_description: details?.short_description,
        banner_image1_alt: details?.banner_image1_alt,
        banner_image2_alt: details?.banner_image2_alt,
        vision_title: details?.vision_title,
        vision_description: details?.vision_description,
        vision_image_alt: details?.vision_image_alt,
        vision_icon_alt: details?.vision_icon_alt,
        mission_title: details?.mission_title,
        mission_description: details?.mission_description,
        mission_image_alt: details?.mission_image_alt,
        mission_icon: details?.mission_icon
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.mission_icon}`,
            file: null,
            image: details?.mission_icon,
          }
          : "",
        mission_icon_alt: details?.mission_icon_alt,
        main_title: details?.main_title,
        main_image_alt: details?.main_image_alt,
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      };
    
      setData({ ...result });
      setInputs({...inputs,main_description:details?.main_description,
        banner_image1: details?.banner_image1
        ? [
          {
            type: details?.banner_image1?.split(".").pop(),
            url: `${IMAGE_BASE_URL}${path}/${details?.banner_image1}`,
            name: `${details?.banner_image1}`,
            response: `${details?.banner_image1}`,
          },
        ]:"",
        banner_image2: details?.banner_image2
        ? [
          {
            type: details?.banner_image2?.split(".").pop(),
            url: `${IMAGE_BASE_URL}${path}/${details?.banner_image2}`,
            name: `${details?.banner_image2}`,
            response: `${details?.banner_image2}`,
          },
        ]:"",
        vision_image: details?.vision_image
        ? [
          {
            type: details?.vision_image?.split(".").pop(),
            url: `${IMAGE_BASE_URL}${path}/${details?.vision_image}`,
            name: `${details?.vision_image}`,
            response: `${details?.vision_image}`,
          },
        ]:"",
        vision_icon: details?.vision_icon
        ? [
          {
            type: details?.vision_icon?.split(".").pop(),
            url: `${IMAGE_BASE_URL}${path}/${details?.vision_icon}`,
            name: `${details?.vision_icon}`,
            response: `${details?.vision_icon}`,
          },
        ]:"",
        mission_image: details?.mission_image
          ? [
            {
              type: details?.mission_image?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.mission_image}`,
              name: `${details?.mission_image}`,
              response: `${details?.mission_image}`,
            },
          ]:"",
          mission_icon: details?.mission_icon
          ? [
            {
              type: details?.mission_icon?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.mission_icon}`,
              name: `${details?.mission_icon}`,
              response: `${details?.mission_icon}`,
            },
          ]:"",
          main_image: details?.main_image
          ? [
            {
              type: details?.main_image?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.main_image}`,
              name: `${details?.main_image}`,
              response: `${details?.main_image}`,
            },
          ]:"",
          features: details.features.map((ele) => ({
            icon: ele?.icon
              ? [
                  {
                    type: ele?.icon.split(".").pop(),
                    url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                    name: ele?.icon,
                    response: ele?.icon,
                  },
                ]
              : "",
            icon_alt: ele?.icon_alt || "",
            title: ele?.title || "",
          }))
      })
    }
  }, [details]);

  console.log(inputs)

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  // const singleFileSelectedHandler = (event, field, setFunction, index) => {
  //   const file = event.target.files?.[0];
  //   setErr({
  //     ...err,
  //     [field]: "",
  //   });
  //   let formData = new FormData();
  //   if (trackId?.length == 0) {
  //     let track_id = Math.floor(1000 + Math.random() * 9000).toString();
  //     setTrackId(track_id);
  //     formData.append("track_id", track_id);
  //   } else {
  //     formData.append("track_id", trackId);
  //   }
  //   formData.append(`file`, file);
  //   formData.append(`path`, "about");
  //   dispatch(
  //     uploadFile(formData, (res) => {
  //       setErr({
  //         ...err,
  //         [field]: "",
  //       });
  //       setFunction(field, file, res, index);
  //     })
  //   );
  // };

  // const setSingleImageState = (field, file, res) => {
  //   setData((s) => ({
  //     ...s,
  //     [field]: {
  //       file: file,
  //       image: res?.data[0],
  //       preview: URL.createObjectURL(file),
  //     },
  //   }));
  // };

  const inputDataChangeHandler = (key, value, index, field) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };
  const featureValidator = (error) => {
    let temp = {};
    if (inputs?.features?.length) {
      inputs?.features?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"features"}_${"icon"}_${i}`] = "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"features"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.title?.trim()?.length) {
          temp[`${"features"}_${"title"}_${i}`] = "Please enter title";
        }
      });
      if (Object.values(temp).length !== 0) {
        temp = { ...temp, ...error };
        return temp;
      } else {
        setErrors({ ...error });
        return error;
      }
    }
    }


  // const setFeatureImageState = (field, file, res, index) => {
  //   let dataList = { ...data };
  //   dataList.features[index][field] = {
  //     file: file,
  //     image: res?.data[0],
  //     preview: URL.createObjectURL(file),
  //   };
  //   setData(dataList);
  //   setErr({
  //     ...err,
  //     [`features_${field}_${index}`]: "",
  //   });
  // };

  const removeIcon = (index, type) => {
    setInputs((prev) => {
      const updatedMedia = prev[type].map((item, idx) =>
        idx === index ? { ...item, icon: "" } : item
      );
      return {
        ...prev,
        [type]: updatedMedia,
      };
    });
  };

  // const handleFeaturesChange = (index, value, field) => {
  //   let dataList = { ...data };
  //   dataList.features[index][field] = value;
  //   setData(dataList);
  //   setErr({
  //     ...err,
  //     [`features_${field}_${index}`]: "",
  //   });
  // };

  const validator = () => {
    let temp = {};
    if (!data?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter title",
      };
    }
    if (!data?.short_description?.trim()?.length) {
      temp = {
        ...temp,
        short_description: "Please enter short description",
      };
    }
    // if (!data?.banner_image1_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_image1_alt: "Please enter banner alt text",
    //   };
    // }
    // if (data?.banner_image1 == "") {
    //   temp = {
    //     ...temp,
    //     banner_image1: "Please upload banner image",
    //   };
    // }
    // if (!data?.banner_image2_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_image2_alt: "Please enter banner alt text",
    //   };
    // }
    // if (data?.banner_image2 == "") {
    //   temp = {
    //     ...temp,
    //     banner_image2: "Please upload banner image",
    //   };
    // }
    if (!data?.vision_title?.trim()?.length) {
      temp = {
        ...temp,
        vision_title: "Please enter vision title",
      };
    }
    if (!data?.vision_description?.trim()?.length) {
      temp = {
        ...temp,
        vision_description: "Please enter vision description",
      };
    }
    // if (data?.vision_image == "") {
    //   temp = {
    //     ...temp,
    //     vision_image: "Please upload vision image",
    //   };
    // }
    // if (!data?.vision_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     vision_image_alt: "Please enter vision alt text",
    //   };
    // }
    // if (data?.vision_icon == "") {
    //   temp = {
    //     ...temp,
    //     vision_icon: "Please upload vision icon",
    //   };
    // }
    // if (!data?.vision_icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     vision_icon_alt: "Please enter vision icon alt text",
    //   };
    // }
    if (!data?.mission_title?.trim()?.length) {
      temp = {
        ...temp,
        mission_title: "Please enter mission title",
      };
    }
    if (!data?.mission_description?.trim()?.length) {
      temp = {
        ...temp,
        mission_description: "Please enter mission description",
      };
    }
    // if (data?.mission_image == "") {
    //   temp = {
    //     ...temp,
    //     mission_image: "Please upload mission image",
    //   };
    // }
    // if (!data?.mission_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     mission_image_alt: "Please enter mission alt text",
    //   };
    // }
    if (data?.mission_icon == "") {
      temp = {
        ...temp,
        mission_icon: "Please upload mission icon",
      };
    }
    // if (!data?.mission_icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     mission_icon_alt: "Please enter mission icon alt text",
    //   };
    // }
    if (!data?.main_title?.trim()?.length) {
      temp = {
        ...temp,
        main_title: "Please enter main title",
      };
    }
    // if (!data?.main_description?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     main_description: "Please enter main description",
    //   };
    // }
  
    // if (!data?.main_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     main_image_alt: "Please enter main image alt text",
    //   };
    // }
  //   data?.features?.forEach((ele, i) => {
  //     if (!ele.title?.trim()?.length) {
  //       temp[`features_title_${i}`] = "Please enter name";
  //     }
  //     // if (!ele.icon_alt?.trim()?.length) {
  //     //   temp[`features_icon_alt_${i}`] = "Please enter alt text";
  //     // }
  //     if (ele.icon == "") {
  //       temp[`features_icon_${i}`] = "Please upload icon";
  //     }
  //   });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };


  const singleFileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    mainField
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "about");
        dispatch(
          uploadFile(formData, (res) => {
            let tempValue = [
              {
                type: e[0].type,
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ];
            if (field === "icon") {
              setFunction(field, tempValue, index, mainField);
            } else {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: tempValue,
              }));
            }
          })
        );
      }
    }
  };
  const onSubmitHandler = async () => {
    let isValidate1 = false;
    let isValidated = await validate({
      returnErrors: true,
    });
    let temp = {
      ...isValidated.err,
      ...featureValidator(),
    };
    console.log(temp);

    if (Object.values(temp).every((value) => value === "")) {
      isValidate1 = true;
    } else {
      setErrors({ ...temp });
    }

    if (isValidated?.valid && !invalidUpload && isValidate1) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    if (validator()) {
      let raw = {
        title: data?.title?.trim(),
        short_description: data?.short_description?.trim(),
        banner_image1: inputs?.banner_image1?.[0]?.response ?? "",
        banner_image1_alt: data?.banner_image1_alt?.trim(),
        banner_image2: inputs?.banner_image2?.[0]?.response ?? "",
        banner_image2_alt: data?.banner_image2_alt?.trim(),
        vision_title: data?.vision_title?.trim(),
        vision_description: data?.vision_description?.trim(),
        vision_image: inputs?.vision_image?.[0]?.response ?? "",
        vision_image_alt: data?.vision_image_alt?.trim(),
        vision_icon: inputs?.vision_icon?.[0]?.response ?? "",
        vision_icon_alt: data?.vision_icon_alt?.trim(),
        mission_title: data?.mission_title?.trim(),
        mission_description: data?.mission_description?.trim(),
        mission_image: inputs?.mission_image?.[0]?.response ?? "",
        mission_image_alt: data?.mission_image_alt?.trim(),
        mission_icon: inputs?.mission_icon?.[0]?.response ?? "",
        mission_icon_alt: data?.mission_icon_alt?.trim(),
        main_title: data?.main_title?.trim(),
        main_description: inputs?.main_description?.trim(),
        main_image: inputs?.main_image?.[0]?.response ?? "",
        main_image_alt: data?.main_image_alt?.trim(),
        features: inputs.features.map((item) => ({
          icon: item.icon?.[0]?.response ?? "",
          icon_alt: item.icon_alt,
          title: item.title,
        })),
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
        path : "about"
      };
      console.log(raw);
      if (details?._id) {
        raw = {
          ...raw,
          id: details?._id,
        };
        dispatch(
          updateAbout(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertAbout(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      short_description: "",
      banner_image1_alt: "",
      banner_image2_alt: "",
      vision_title: "",
      vision_description: "",
      vision_image_alt: "",
      vision_icon_alt: "",
      mission_title: "",
      mission_description: "",
      mission_image_alt: "",
      mission_icon: "",
      mission_icon_alt: "",
      main_title: "",
      main_image_alt: "",
      // features: [
      //   { title: "", icon: "", icon_alt: "" },
      //   { title: "", icon: "", icon_alt: "" },
      //   { title: "", icon: "", icon_alt: "" },
      //   { title: "", icon: "", icon_alt: "" },
      //   { title: "", icon: "", icon_alt: "" },
      //   { title: "", icon: "", icon_alt: "" },
      // ],
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    setInputs({})
    dispatch(getAboutDetail());
  };

  return (
    loading ? (<LoadingSpinner height={"200px"} />) : (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Add About</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Title <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) => onChangeHandler("title", e?.target?.value)}
                      value={data?.title}
                    />
                    <small id="error" className="error">
                      {err?.title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      type="text"
                      className="form-control"
                      rows={4}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("short_description", e?.target?.value)
                      }
                      value={data?.short_description}
                    />
                    <small id="error" className="error">
                      {err?.short_description}
                    </small>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="mb-3">
                  <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5 mt-3"
                      label="About Page Images(Recommended Size:430px * 695px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("banner_image1")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          banner_image1: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "banner_image1"
                        );
                      }}
                      error={errors.banner_image1}
                      width={28}
                      field={"banner_image1"}
                      id="banner_image1"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.banner_image1}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                  />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_image1_alt", e?.target?.value)
                      }
                      value={data?.banner_image1_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_image1_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-3 ">
                  <div className="mb-3">
                  <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label="About Page Images(Recommended Size:430px * 695px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("banner_image2")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          banner_image2: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "banner_image2"
                        );
                      }}
                      error={errors.banner_image2}
                      width={28}
                      field={"banner_image2"}
                      id="banner_image2"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.banner_image2}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                  />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_image2_alt", e?.target?.value)
                      }
                      value={data?.banner_image2_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_image2_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Feature icons sections */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Feature List</h5>
              <div className="row">
                {inputs?.features?.map((ele, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5 image2 mt-3"
                      label={`Icon ${index+1}`} 
                      accept={["jpg", "jpeg", "png", "svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeIcon(index, "features")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "icon",
                          inputDataChangeHandler,
                          index,
                          "features"
                        );
                      }}
                      error={errors[`${"features"}_${"icon"}_${index}`]}
                      width={28}
                      field={"icon"}
                      id="icon"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={ele?.icon}
                      inputSocket={formPlug}
                      // required={{message:"Please upload image"}}
                    />
                      </div>
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png","svg"]}
                  />
                    </div>
                    <div className="col-12 col-md-8 mt-3">
                    <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1}. Features Name`}
                      placeholder="Type here"
                      required={{ message: "Please enter Features name" }}
                      field={"title"}
                      value={ele?.title}
                      inputSocket={() => {}}
                      onChange={(e, key) => {
                        inputDataChangeHandler(
                          key,
                          e,
                          index,
                          "features"
                        );
                      }}
                      error={
                        errors[`${"features"}_${"title"}_${index}`]
                      }
                    />
                      <div className="mb-4">
                      <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1}. Alt Text`}
                      placeholder="Type here"
                      field={"icon_alt"}
                      value={ele?.icon_alt}
                      inputSocket={() => {}}
                      onChange={(e, key) => {
                        inputDataChangeHandler(
                          key,
                          e,
                          index,
                          "features"
                        );
                      }}
                      error={
                        errors[`${"features"}_${"icon_alt"}_${index}`]
                      }
                    />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Vision</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Vision Title<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("vision_title", e?.target?.value)
                      }
                      value={data?.vision_title}
                    />
                    <small id="error" className="error">
                      {err?.vision_title}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label=" Icon "
                      accept={["jpg", "jpeg", "png","svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("vision_icon")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          vision_icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "vision_icon"
                        );
                      }}
                      error={errors.vision_icon}
                      width={28}
                      field={"vision_icon"}
                      id="vision_icon"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.vision_icon}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                      
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("vision_icon_alt", e?.target?.value)
                      }
                      value={data?.vision_icon_alt}
                    />
                    <small id="error" className="error">
                      {err?.vision_icon_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-4">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label="Background Image (Recommended Size:960px * 634px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("vision_image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          vision_image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "vision_image"
                        );
                      }}
                      error={errors.vision_image}
                      width={28}
                      field={"vision_image"}
                      id="vision_image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.vision_image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png"]}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("vision_image_alt", e?.target?.value)
                      }
                      value={data?.vision_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.vision_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("vision_description", e?.target?.value)
                      }
                      value={data?.vision_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.vision_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">Mission</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Mission Title<em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("mission_title", e?.target?.value)
                      }
                      value={data?.mission_title}
                    />
                    <small id="error" className="error">
                      {err?.mission_title}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label=" Icon"
                      accept={["jpg", "jpeg", "png","svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("mission_icon")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          mission_icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "mission_icon"
                        );
                      }}
                      error={errors.mission_icon}
                      width={28}
                      field={"mission_icon"}
                      id="mission_icon"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.mission_icon}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("mission_icon_alt", e?.target?.value)
                      }
                      value={data?.mission_icon_alt}
                    />
                    <small id="error" className="error">
                      {err?.mission_icon_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label="Background Image (Recommended Size:960px * 634px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("mission_image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          mission_icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "mission_image"
                        );
                      }}
                      error={errors.mission_image}
                      width={28}
                      field={"mission_image"}
                      id="mission_image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.mission_image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png"]}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("mission_image_alt", e?.target?.value)
                      }
                      value={data?.mission_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.mission_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Short description<em>*</em></label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("mission_description", e?.target?.value)
                      }
                      value={data?.mission_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.mission_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Add Main Contents</h5>
              <div className="row">
              <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Title <em>*</em></label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) => onChangeHandler("main_title", e?.target?.value)}
                      value={data?.main_title}
                    />
                    <small id="error" className="error">
                      {err?.main_title}
                    </small>
                  </div>
                </div>
                <div className="col-12 mb-3">
                <TextEditor
                  config={editorConfigEn}
                  label={"Description (maximum character 250)"}
                  className={"mb-3"}
                  field={"main_description"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter description",
                  }}
                  placeholder="Type Here"
                  validation={{ maxChar: 250 }}
                />
              </div>

                <div className="col-4">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5"
                      label=" Banner Image (Recommended Size:2160px * 1440px)"
                      accept={["jpg", "jpeg", "png",]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("main_image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          main_image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "main_image"
                        );
                      }}
                      error={errors.main_image}
                      width={28}
                      field={"main_image"}
                      id="main_image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.main_image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png"]}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("main_image_alt", e?.target?.value)
                      }
                      value={data?.main_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.main_image_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Seo data={data} onChangeHandler={onChangeHandler} />
            </div>
          </div>
          <div className="card">
            <div className="card-body ">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary me-3"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
}
