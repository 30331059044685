import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../../constants/config";
import {
  getMilestoneDetail,
  insertMilestone,
  updateMilestone,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  detail_payment_milestone_loading,
  payment_milestone_details,
  update_payment_milestone_loading,
} from "../../redux/commonReducer";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import useForm from "../../utils/Hooks/useForm";
import Input from "../../commonComponents/Input/Input";
import { editorConfigEn } from "../../utils/editorConfig";

const PaymentMilestones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();


  const loading = useSelector(detail_payment_milestone_loading);
  const submitLoading = useSelector(update_payment_milestone_loading);
  const details = useSelector(payment_milestone_details);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    setErrors,
    validate,
    setInputs,
    clearInputs,
  } = useForm({});



  useEffect(() => {
    if (id) {
      dispatch(getMilestoneDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        milestone_name: details?.milestone_name,
        milestone_percentage: details?.milestone_percentage,
        milestone_description: details?.milestone_description,
      };
      setInputs({ ...result });
    }
  }, [details]);


  const onSubmitHandler = async () => {
    let isValidated = await validate({
      returnErrors: true,
    });

    if (isValidated?.valid) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let raw = {
      milestone_name: inputs?.milestone_name?.trim(),
      milestone_description: inputs?.milestone_description?.trim(),
      milestone_percentage: inputs?.milestone_percentage?.trim(),
    };

    console.log(raw);
    if (id) {
      raw = {
        ...raw,
        id: id,
      };
      dispatch(
        updateMilestone(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertMilestone(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  }

  const onCancelHandler = () => {
    setInputs({
      milestone_name: "",
      milestone_percentage: "",
      milestone_description: "",
      is_billable: "",
    });
    navigate("/payment-milestone");
  };
  return (
    loading ? (
      <LoadingSpinner height="200px" />) : (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Payment Milestone</h5>
              <div className="row">
                <div className="col-12">
                  <Input
                    className="css-inp-esc1 mb-3"
                    label=" Milestone Name"
                    placeholder="Type here"
                    required={{ message: "Please enter milestone name" }}
                    field={"milestone_name"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-12">
                  <Input
                    type={"number"}
                    className="css-inp-esc1 mb-3"
                    label="Percentage (%)"
                    placeholder="Type here"
                    required={{ message: "Please enter milestone percentage" }}
                    field={"milestone_percentage"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    maxDecimals={2}
                    validation={{maxValue:100}}
                  />
                </div>

                <div className="col-12 mb-3">
                  <TextEditor
                    config={editorConfigEn}
                    label={"Content Section One"}
                    className={"mb-3"}
                    field={"milestone_description"}
                    value={inputs}
                    error={errors}
                    inputSocket={formPlug}
                    onChange={inputHandler}
                    required={{
                      message: "Please enter description",
                    }}
                    placeholder="Type Here"
                    validation={{ maxChar: 200 }}
                  />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary m-2" onClick={() => onSubmitHandler()}>
                    Submit
                  </button>
                  <button type="button" className="btn btn-danger" onClick={() => onCancelHandler()}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default PaymentMilestones;
