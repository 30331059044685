import { useRef } from "react";
import React, { useState, useEffect } from "react";
import "./Table.scss";
import Pagination from "../Pagination/Pagination";

export default function Table({
  titles = [{ title: "", width: "", key: "", condition: "" }],
  titles2,
  content = [],
  className,
  sortValue = { key: "", value: "" },
  onSort,
  page,
  pagesCount,
  onPageChange,
  pageStatus,
  top,
  tbody,
  scroll_Max_Rows,
}) {
  const [inTitles, setTitles] = useState([]);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({});
  const [paginationWidth, setPaginationWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(null);

  const tableRef = useRef();

  useEffect(() => {
    titles && setTitles(titles);
    content && setData(content);
    return () => {
      setTitles([]);
      setData([]);
    };
  }, [titles,content]);

  useEffect(() => {
    setPaginationWidth(tableRef.current?.clientWidth);
  }, [inTitles]);

  useEffect(() => {
    if (scroll_Max_Rows) {
      if (data?.length > scroll_Max_Rows) {
        setTimeout(() => {
          let bodyRowHeight = tableRef?.current
            ?.querySelector("tbody")
            ?.querySelector("tr")?.clientHeight;
          let headRowHeight = tableRef?.current
            ?.querySelector("thead")
            ?.querySelector("tr")?.clientHeight;
          let netHeight;
          if (bodyRowHeight) netHeight = bodyRowHeight * scroll_Max_Rows;
          if (headRowHeight) netHeight += headRowHeight;
          if (netHeight) setTableHeight(netHeight);
        }, 0);
      } else setTableHeight("auto");
    }
  }, [scroll_Max_Rows, data]);

  useEffect(() => {
    if (sortValue?.key && sortValue?.value) {
      setSort({ [sortValue.key]: sortValue.value === 1 });
    }
  }, [sortValue]);

  function sortHandler(key) {
    let value = key in sort ? !sort[key] : true;
    setSort({ [key]: value });
    onSort && onSort({ key: key, value: value ? 1 : -1 });
  }

  function pageChangeHandler(e) {
    onPageChange && onPageChange(e);
  }

  return (
    <div
      id="Table"
      className={className + " " + (top ? "top" : "")}
      style={{ height: tableHeight, paddingTop: scroll_Max_Rows ? 0 : "" }}
    >
      <div className="table-responsive">
      <table ref={tableRef}>
        <thead>
          <tr>
            {inTitles.map((column, i) => {
              if (!column?.condition && column?.condition !== undefined)
                return null;
              return (
                <th
                  key={i}
                  className={column.sort ? "sort" : ""}
                  width={column.width}
                  colSpan={column.span}
                  style={{
                    minWidth: column.minWidth || column.width,
                    textAlign: column.h_align,
                    paddingRight: column.h_align === "center" ? "0" : "",
                    position: scroll_Max_Rows ? "sticky" : "",
                  }}
                >
                  {column.title}
                  {column.sort && (
                    <span
                      className={
                        column.key in sort
                          ? sort?.[column.key]
                            ? "sort__asc"
                            : "sort__dsc"
                          : ""
                      }
                      onClick={() => sortHandler(column.key)}
                    ></span>
                  )}
                </th>
              );
            })}
          </tr>
          {titles2 && (
            <tr>
              {titles2?.map((column, i) => {
                if (!column?.condition && column?.condition !== undefined)
                  return null;
                return (
                  <th
                    key={i}
                    className={column.sort ? "sort" : ""}
                    width={column.width}
                    style={{
                      minWidth: column.minWidth || column.width,
                      textAlign: column.h_align,
                      paddingRight: column.h_align === "center" ? "0" : "",
                      position: scroll_Max_Rows ? "sticky" : "",
                    }}
                  >
                    {column.sort && (
                      <span
                        className={
                          column.key in sort
                            ? sort?.[column.key]
                              ? "sort__asc"
                              : "sort__dsc"
                            : ""
                        }
                        onClick={() => sortHandler(column.key)}
                      ></span>
                    )}
                    {column.title}
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {tbody
            ? tbody instanceof Function
              ? tbody(data)
              : tbody
            : data?.length > 0 &&
              data.map((row, row_index) => {
                return (
                  <tr key={row_index}>
                    {inTitles.map((column, col_index) => {
                      if (!column?.condition && column?.condition !== undefined)
                        return null;
                      return (
                        <td
                          className={column.key}
                          key={col_index}
                          dir={column.dir}
                          style={{
                            textAlign: column.d_align,
                            paddingRight:
                              column.d_align === "center" ? "0" : "",
                          }}
                          colSpan={column.span}
                        >
                          {row[column.key]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      </div>
      {(Array.isArray(tbody) ? tbody.length === 0 : data.length === 0) &&
      inTitles.length !== 0 ? (
        <p className="no__data">{"No Data Available"}</p>
      ) : (
        <Pagination
          page={page}
          pageCount={pagesCount}
          pageStatus={pageStatus}
          onChange={pageChangeHandler}
          // width={}
        />
      )}
    </div>
  );
}

{
  /*

<Table
  className='css-tbl-esc1'
  titles={[
    { title: '', key: '', width: '', h_align: '', d_align: '' },
  ]}
  content={[{},{}]}
  sortValue={{ key: '', value: '' }}
  onSort={() => { }}
  page={''}
  pagesCount={''}
  onPageChange={() => { }}
  pageStatus={''}
  top
/>

*/
}
