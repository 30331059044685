import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import feather from "feather-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import { deleteCurrency, getCurrency } from "../../redux/actionCreator";
import {
  currency_loading,
  currencyConversion_data,
  currencyConversion_page_details,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import { Modal } from "react-bootstrap";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import Table from "../../commonComponents/Table/Table";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

const ManageCurrrencyConversion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector(currencyConversion_data);
  const pageData = useSelector(currencyConversion_page_details);
  const loading = useSelector(currency_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    getSearchDataHandler(initialFilter);
  }, []);

  const getSearchDataHandler = (filter) => {
    let raw = {
      page: filter.page,
      perPage: filter.perPage,
      sort_order: filter.sortValue,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
    };
    dispatch(getCurrency(raw));
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteCurrency(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let raw = {
            page: filterData?.page - 1,
            perPage: filterData.perPage,
            sort_order: filterData.sortValue,
            sort_key: filterData.sortColumn,
            keyword: filterData?.keyword?.trim() ?? "",
          };
          dispatch(getCurrency(raw));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title ">Manage Currency Conversion</h5>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/add-conversion")}
              >
                Add Conversion
              </button>
            </div>
            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Here"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "10px",
                  },
                  {
                    title: "Currency Code",
                    key: "currency_code",
                    sort: true,
                    width: "200px",
                  },
                  {
                    title: "Conversion Rate",
                    key: "conversion_rate",
                    sort: true,
                    width: "100px",
                  },
                  {
                    title: "Displayed In Website",
                    key: "is_website_display",
                    sort: true,
                    width: "100px",
                  },

                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  currency_code: item?.currency_code,
                  conversion_rate: item?.conversion_rate,
                  is_website_display: item?.is_website_display ? "Yes" : "No",
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() =>
                        F_navigate(`/update-conversion/${item?._id}`)
                      }
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Currency Conversion"}
      />
    </div>
  );
};

export default ManageCurrrencyConversion;
