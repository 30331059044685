import * as Switch from "@radix-ui/react-switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { countryCurrencyList } from "../../utils/helpers";
import {
  currencyConversion_details,
  detail_currencyConversion_loading,
  update_currencyConversion_loading,
} from "../../redux/commonReducer";
import {
  getCurrencyDetail,
  insertCurrency,
  updateCurrency,
} from "../../redux/actionCreator";
import { Loader } from "react-feather";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

const CurrencyConversions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const submitLoading = useSelector(update_currencyConversion_loading);
  const loading = useSelector(detail_currencyConversion_loading);
  const details = useSelector(currencyConversion_details);

  const [data, setData] = useState({
    currency_code: "",
    conversion_rate: "",
    is_website_display: false,
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getCurrencyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      setData({
        currency_code: countryCurrencyList?.filter(
          (ele) => ele?.currencyCode == details?.currency_code
        )?.[0],
        conversion_rate: details?.conversion_rate,
        is_website_display: details?.is_website_display,
      });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (data?.currency_code == "") {
      temp = {
        ...temp,
        currency_code: "Please select currency code",
      };
    }
    if (!data?.conversion_rate?.trim()?.length) {
      temp = {
        ...temp,
        conversion_rate: "Please enter conversion rate",
      };
    }
    if (data?.is_website_display === "") {
      temp = {
        ...temp,
        is_website_display: "Please choose value",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    console.log(data?.currency_code)
    if (validate()) {
      let raw = {
        currency_code: data?.currency_code?.currencyCode,
        conversion_rate: data?.conversion_rate,
        is_website_display: data?.is_website_display,
      };
      console.log(raw);
      if (id) {
        raw = {
          ...raw,
          id: id,
        };
        dispatch(
          updateCurrency(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertCurrency(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      currency_code: "",
      conversion_rate: "",
      is_website_display: false,
    });
    navigate("/currency-conversion");
  };
  return (
    loading ? (<LoadingSpinner height={"200px"}/>):(

    
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">
              Currency Conversions
            </h5>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-3">
                  <label htmlFor="milestoneInput" className="form-label">
                    Currency Code <em>*</em>
                  </label>
                  <Select
                    placeholder={"Select"}
                    name="unit"
                    options={countryCurrencyList}
                    hideSelectedOptions={false}
                    value={data?.currency_code}
                    getOptionLabel={(option) =>
                      `${option.country}- ${option.currencyCode}`
                    }
                    getOptionValue={(option) => option.currencyCode}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#31D68F",
                        primary: "#0D7B58",
                      },
                    })}
                    onChange={(e) => onChangeHandler("currency_code", e)}
                  />
                  <small id="error" className="error">
                    {err?.currency_code}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="conversionRateInput" className="form-label">
                    Conversion Rate (Related To USD) <em>*</em>
                  </label>
                  <input
                    type="text"
                    className="form-control typeNumber"
                    id={`conversionRateInput`}
                    placeholder="Type here"
                    value={data?.conversion_rate}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,5}(\.\d{0,3})?$/.test(inputValue)) {
                        onChangeHandler("conversion_rate", inputValue);
                      }
                    }}
                  />
                  <small id="error" className="error">
                    {err?.conversion_rate}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Display in Website</label>
                  <div className="switch_layout">
                    <Switch.Root
                      className="switchRoot"
                      checked={data?.is_website_display}
                      onCheckedChange={(e) =>
                        onChangeHandler("is_website_display", e)
                      }
                    >
                      <Switch.Thumb className="switchThumb" />
                    </Switch.Root>
                    <div className="switch_content">
                      <p>{data?.is_website_display ? "Active" : ""}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2 ">
                <button
                  type="submit"
                  className="btn btn-primary me-2 "
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
};

export default CurrencyConversions;
