import { Accordion } from "react-bootstrap";
import Input from "../../../../commonComponents/Input/Input";
import TextEditor from "../../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../../utils/editorConfig";
import Select from "../../../../commonComponents/Select/Select";
import SupportedFormats from "../../../../commonComponents/SupportedFormats/SupportedFormats";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../../../../commonComponents/FileUpload/FileUpload";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DNDIcon from "../../../../assets/icons/dragdrop.svg";

const NonEuroItinerary = ({
  inputs,
  errors,
  formPlug,
  setErrors,
  singleFileUploadHandler,
  removeImage,
  activityData,
  attractionData,
  inputDataChangeHandler,
  inputDataRemoveChangeHandler,
  reOrderNonEuroItinerary,
  nonEuroActiveKey, setNonEuroActiveKey
}) => {

  const handleNonEuroDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newItems = [...inputs?.nonEuropean_itinerary];
    if (destination.index === 0 || destination.index === newItems.length - 1) {
      return;
    }
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);
    reOrderNonEuroItinerary([...newItems]);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleNonEuroDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                padding: "20px",
              }}
              className="itenarary_dnd"
            >
              {inputs?.nonEuropean_itinerary
                ?.map((item, i) => ({
                  ...item,
                  id: `item-${i}`,
                }))
                ?.map((ele, index) => (
                  <Draggable
                    key={ele.id}
                    draggableId={ele.id}
                    index={index}
                    isDragDisabled={
                      index === 0 ||
                      index === inputs?.nonEuropean_itinerary?.length - 1
                    }
                  >
                    {(provided) => (
                      <Accordion.Item eventKey={index}>
                        <div
                          style={{
                            ...provided.draggableProps.style,
                            position: "relative",
                            width: "100%",
                            height: 120,
                          }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="header__container"
                        >
                          <Accordion.Header
                            onClick={() =>
                              setNonEuroActiveKey(
                                nonEuroActiveKey === index ? null : index
                              )
                            }
                          >
                            <div className="d-flex justify-content-between w-full">
                              <div>Day {index + 1}</div>
                            </div>
                          </Accordion.Header>
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              position: "absolute",
                              top: -15,
                              right: -15,
                              borderRadius: "25%",
                              padding: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={DNDIcon} width={20} />
                          </div>
                        </div>
                        <Accordion.Body>
                          <div className="row mb-5">
                            <div className="col-12 ">
                              <Input
                                className="css-inp-esc1 mb-3"
                                label="Day Title"
                                placeholder="Type here"
                                required={{
                                  message: "Please enter title",
                                }}
                                field={"title"}
                                inputSocket={formPlug}
                                value={ele?.title}
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "nonEuropean_itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"nonEuropean_itinerary"}_${"title"}_${index}`
                                  ]
                                }
                                // disabled={
                                //   index == 0 ||
                                //   index ==
                                //     inputs?.nonEuropean_itinerary?.length - 1
                                // }
                              />
                            </div>
                            <div className="col-12">
                              <TextEditor
                                config={editorConfigEn}
                                label={"Day Description"}
                                className={"mb-3"}
                                field={"description"}
                                inputSocket={formPlug}
                                required={{
                                  message: "Please enter day description",
                                }}
                                placeholder="Type Here"
                                value={ele?.description}
                                onBlur={(newContent) =>
                                  inputDataChangeHandler(
                                    "description",
                                    newContent,
                                    index,
                                    "nonEuropean_itinerary"
                                  )
                                }
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "nonEuropean_itinerary"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"nonEuropean_itinerary"}_${"description"}_${index}`
                                  ]
                                }
                                validation={{ maxChar: 200 }}
                              />
                            </div>
                            {index == 0 ||
                            index ==
                              inputs?.nonEuropean_itinerary?.length - 1 ? (
                              <></>
                            ) : (
                              <>
                                <div className="col-12 col-md-6">
                                  <Select
                                    title="Activities"
                                    className="css-slc-esc1 mb-3"
                                    placeholder={"Select"}
                                    options={activityData}
                                    labelSetter={(o) => o?.title}
                                    valueSetter={(option) => option._id}
                                    field={"activity"}
                                    inputSocket={formPlug}
                                    // required={{ message: "Please select activity" }}
                                    Multi2
                                    value={ele.activity ? ele.activity : []}
                                    onChange={(e, key) => {
                                      inputDataChangeHandler(
                                        key,
                                        e,
                                        index,
                                        "nonEuropean_itinerary"
                                      );
                                    }}
                                    error={
                                      errors[
                                        `${"nonEuropean_itinerary"}_${"activity"}_${index}`
                                      ]
                                    }
                                  />
                                </div>
                                <div className="raw">
                                  <div className="col-12 selectedListData">
                                    {ele?.activity?.map((option, i) => (
                                      <div key={option} className="mb-1">
                                        <div className="d-inline-block mb-1">
                                          <button
                                            className="selected__item"
                                            type="button"
                                          >
                                            {option?.title}
                                            <img
                                              src="/assets/img/icons/close-red.svg"
                                              onClick={() =>
                                                inputDataRemoveChangeHandler(
                                                  "activity",
                                                  option?._id,
                                                  "nonEuropean_itinerary",
                                                  index
                                                )
                                              }
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <Select
                                    title="Attractions"
                                    className="css-slc-esc1"
                                    placeholder={"Select"}
                                    options={attractionData}
                                    labelSetter={(o) => o?.title}
                                    valueSetter={(option) => option._id}
                                    field={"attraction"}
                                    inputSocket={formPlug}
                                    // required={{ message: "Please select attraction" }}
                                    Multi2
                                    value={
                                      ele?.attraction ? ele?.attraction : []
                                    }
                                    onChange={(e, key) => {
                                      inputDataChangeHandler(
                                        key,
                                        e,
                                        index,
                                        "nonEuropean_itinerary"
                                      );
                                    }}
                                    error={
                                      errors[
                                        `${"nonEuropean_itinerary"}_${"attraction"}_${index}`
                                      ]
                                    }
                                  />
                                </div>
                                <div className="raw">
                                  <div className="selectedListData">
                                    {ele?.attraction?.map((option) => (
                                      <div key={option} className="mb-3">
                                        <div className="d-inline-block mb-1">
                                          <button
                                            className="selected__item"
                                            type="button"
                                          >
                                            {option?.title}

                                            <img
                                              src="/assets/img/icons/close-red.svg"
                                              onClick={() => {
                                                inputDataRemoveChangeHandler(
                                                  "attraction",
                                                  option?._id,
                                                  "nonEuropean_itinerary",
                                                  index
                                                );
                                              }}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <div>
                                      <div className="d-flex gap-3">
                                        <FileUpload
                                          style={{
                                            marginRight: 5,
                                            marginTop: 15,
                                          }}
                                          className="css-atc-esc5  mt-3"
                                          label="Map Image (Recommended Size:1640px * 665px)"
                                          accept={["jpg", "jpeg", "png"]}
                                          icon={
                                            "/assets/img/icons/plus_black.svg"
                                          }
                                          closeIcon={
                                            <AiOutlineClose
                                              size={30}
                                              className="remove-icon"
                                              onClick={() =>
                                                removeImage("map_image")
                                              }
                                            />
                                          }
                                          onChange={(
                                            e,
                                            isInvalid,
                                            message,
                                            isClosing
                                          ) => {
                                            setErrors((s) => ({
                                              ...s,
                                              map_image: "",
                                            }));
                                            singleFileUploadHandler(
                                              e,
                                              isInvalid,
                                              message,
                                              isClosing,
                                              "map_image",
                                              inputDataChangeHandler,
                                              index,
                                              "nonEuropean_itinerary"
                                            );
                                          }}
                                          error={
                                            errors[
                                              `${"nonEuropean_itinerary"}_${"map_image"}_${index}`
                                            ]
                                          }
                                          width={28}
                                          field={"map_image"}
                                          id="map_image"
                                          maxFileSizeMB={50}
                                          multiple={false}
                                          showPreview
                                          files={ele?.map_image}
                                          required={{
                                            message: "Please upload map image",
                                          }}
                                          inputSocket={formPlug}
                                        />
                                      </div>
                                      <SupportedFormats
                                        formats={["jpg", "jpeg", "png"]}
                                        maxSize={50}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <Input
                                      className="css-inp-esc1 mb-3"
                                      label="Map Alt Text"
                                      placeholder="Type here"
                                      // required={{ message: "Please enter map alt" }}
                                      field={"map_image_alt"}
                                      inputSocket={formPlug}
                                      value={ele?.map_image_alt}
                                      onChange={(e, key) => {
                                        inputDataChangeHandler(
                                          key,
                                          e,
                                          index,
                                          "nonEuropean_itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                          `${"nonEuropean_itinerary"}_${"map_image_alt"}_${index}`
                                        ]
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-12 col-md-6">
                                    <div>
                                      <div className="d-flex gap-3">
                                        <FileUpload
                                          style={{
                                            marginRight: 5,
                                            marginTop: 15,
                                          }}
                                          className="css-atc-esc5  mt-3"
                                          label="Itinerary image (Recommended Size:1640px * 665px)"
                                          accept={["jpg", "jpeg", "png"]}
                                          icon={
                                            "/assets/img/icons/plus_black.svg"
                                          }
                                          closeIcon={
                                            <AiOutlineClose
                                              size={30}
                                              className="remove-icon"
                                              onClick={() =>
                                                removeImage("mobile_map_image")
                                              }
                                            />
                                          }
                                          onChange={(
                                            e,
                                            isInvalid,
                                            message,
                                            isClosing
                                          ) => {
                                            setErrors((s) => ({
                                              ...s,
                                              mobile_map_image: "",
                                            }));
                                            singleFileUploadHandler(
                                              e,
                                              isInvalid,
                                              message,
                                              isClosing,
                                              "mobile_map_image",
                                              inputDataChangeHandler,
                                              index,
                                              "nonEuropean_itinerary"
                                            );
                                          }}
                                          error={
                                            errors[
                                              `${"nonEuropean_itinerary"}_${"Itinerary_image"}_${index}`
                                            ]
                                          }
                                          width={28}
                                          field={"mobile_map_image"}
                                          id="mobile_map_image"
                                          maxFileSizeMB={50}
                                          multiple={false}
                                          showPreview
                                          files={ele?.mobile_map_image}
                                          required={{
                                            message:
                                              "Please upload itinerary  image",
                                          }}
                                          inputSocket={formPlug}
                                        />
                                      </div>
                                      <SupportedFormats
                                        formats={["jpg", "jpeg", "png"]}
                                        maxSize={50}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <Input
                                      className="css-inp-esc1 mb-3"
                                      label="Itinerary Image Alt Text"
                                      placeholder="Type here"
                                      // required={{
                                      //   message: "Please enter alt text",
                                      // }}
                                      field={"mobile_map_image_alt"}
                                      inputSocket={formPlug}
                                      value={ele?.mobile_map_image_alt}
                                      onChange={(e, key) => {
                                        inputDataChangeHandler(
                                          key,
                                          e,
                                          index,
                                          "nonEuropean_itinerary"
                                        );
                                      }}
                                      error={
                                        errors[
                                          `${"nonEuropean_itinerary"}_${"mobile_map_image_alt"}_${index}`
                                        ]
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default NonEuroItinerary;
