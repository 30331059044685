import { useEffect, useState } from "react";
import feather from "feather-icons";
import * as Tabs from "@radix-ui/react-tabs";
import StatusCard from "../../components/StatusCard";
import History from "../../components/History";
import Seo from "../../components/Seo";
import PackageDetails from "./Tabs/PackageDetails";
import Itinerary from "./Tabs/itinerary";
import Highlights from "./Tabs/Highlight";
import Costing from "./Tabs/Costing";
import Gallery from "./Tabs/Gallery";
import useForm from "../../utils/Hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivity,
  getAttraction,
  getBlog,
  getExclusion,
  getInclusion,
  getPackageDetail,
  getPackagePageDetails,
  getSeason,
  insertPackage,
  updatePackage,
  uploadFile,
} from "../../redux/actionCreator";
import {
  activity_data,
  attraction_data,
  blog_data,
  detail_package_loading,
  exclusion_data,
  inclusion_data,
  package_details,
  package_path,
  packagepage_details,
  season_data,
  update_package_loading,
} from "../../redux/commonReducer";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";

import { IMAGE_BASE_URL } from "../../constants/configuration";

export default function AddPackages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const inclusionData = useSelector(inclusion_data);
  const exclusionData = useSelector(exclusion_data);
  const seasonList = useSelector(season_data);
  const activityData = useSelector(activity_data);
  const attractionData = useSelector(attraction_data);
  const blogData = useSelector(blog_data);
  const submitLoading = useSelector(update_package_loading);
  const loading = useSelector(detail_package_loading);
  const details = useSelector(package_details);
  const path = useSelector(package_path);
  const packageMasterData = useSelector(packagepage_details);

  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    exclusions: [],
    inclusions: [],
    blog: [],
    itinerary: [],
    highlights: [{ title: "", description: "", link_type: "", link_item: "" }],
    gallery: [],
    banner_image: [],
    is_active: "false",
    nonEuropean_itinerary: [],
  });

  const [data, setData] = useState({
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [err, setErr] = useState({});
  const [videoUrl, setVideoUrl] = useState("");
  const [highlightPageErr, setHighlightPageErr] = useState([]);
  const [deletedBlogs, setDeletedBlogs] = useState([]);

  const linkTypeList = [
    { label: "Activity", value: "activity" },
    { label: "Attraction", value: "attraction" },
  ];
  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    let formData = new FormData();
    formData.append("is_pagination", 0);
    dispatch(getInclusion(formData));
    dispatch(getExclusion(formData));
    dispatch(getSeason(formData));
    dispatch(getActivity(formData));
    dispatch(getAttraction(formData));
    let raw = {
      is_pagination: "0",
    };
    dispatch(getBlog(raw));
    dispatch(getPackagePageDetails());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getPackageDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        package_name: details?.package_name,
        rank: details?.rank,
        thumbnail: details?.thumbnail
          ? [
              {
                type: details?.thumbnail?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.thumbnail}`,
                name: `${details?.thumbnail}`,
                response: `${details?.thumbnail}`,
              },
            ]
          : "",
        thumbnail_alt: details?.thumbnail_alt,
        overview: details?.overview,
        duration: details?.duration?.toString(),
        klm: details?.klm,
        season: seasonList?.filter((ele) => ele?._id == details?.season)[0],
        // inclusions: inclusionData?.filter((ele) =>
        //   details?.inclusions?.includes(ele?._id)
        // ),
        inclusions: details?.inclusions ?? [],
        // exclusions: exclusionData?.filter((ele) =>
        //   details?.exclusions?.includes(ele?._id)
        // ),
        exclusions: details?.exclusions ?? [],
        // blog: blogData?.filter((ele) => details?.blog?.includes(ele?._id)),
        blog: details?.blog ?? [],
        // essential_info: details?.essential_info,
        main_map: details?.main_map
          ? [
              {
                type: details?.main_map?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.main_map}`,
                name: `${details?.main_map}`,
                response: `${details?.main_map}`,
              },
            ]
          : "",
        main_map_alt: details?.main_map_alt,
        fixed_package_cost: details?.fixed_package_cost,
        is_published: details?.is_published,
        is_active: details?.is_active.toString(),
      };
      if (details?.banner_image?.length) {
        let arr = details?.banner_image?.map((ele, i) => {
          let obj = {
            type: ele?.type,
            url:
              ele?.type == "video"
                ? ele?.banner_item
                : `${IMAGE_BASE_URL}${path}/${ele?.banner_item}`,
            name: `${ele?.banner_item}`,
            response: `${ele?.banner_item}`,
            id: ele?._id,
            alt: ele?.banner_alt,
          };
          return obj;
        });
        result = {
          ...result,
          banner_image: [...arr],
        };
      }
      if (details?.gallery?.length) {
        let arr = details?.gallery?.map((ele, i) => {
          let obj = {
            type: ele?.gallery_type,
            url:
              ele?.gallery_type == "video"
                ? ele?.gallery_item
                : `${IMAGE_BASE_URL}${path}/${ele?.gallery_item}`,
            name: `${ele?.gallery_item}`,
            response: `${ele?.gallery_item}`,
            id: ele?._id,
            alt: ele?.gallery_alt,
          };
          return obj;
        });
        result = {
          ...result,
          gallery: [...arr],
        };
      }
      if (details?.itinerary?.length) {
        let arr = details?.itinerary?.map((ele, i) => {
          let obj = {
            title: ele?.title,
            description: ele?.description,
            activity: ele?.activity,
            attraction: ele?.attraction,
            map_image: ele?.map_image
              ? [
                  {
                    type: ele?.map_image?.split(".").pop(),
                    url: `${IMAGE_BASE_URL}${path}/${ele?.map_image}`,
                    name: `${ele?.map_image}`,
                    response: `${ele?.map_image}`,
                  },
                ]
              : "",
            map_image_alt: ele?.map_image_alt,
            mobile_map_image: ele?.mobile_map_image
              ? [
                  {
                    type: ele?.mobile_map_image?.split(".").pop(),
                    url: `${IMAGE_BASE_URL}${path}/${ele?.mobile_map_image}`,
                    name: `${ele?.mobile_map_image}`,
                    response: `${ele?.mobile_map_image}`,
                  },
                ]
              : "",
            mobile_map_image_alt: ele?.mobile_map_image_alt,
          };
          return obj;
        });
        // if (arr.length > 0) {
        //   arr[0].title = "Travel";
        //   // arr[0].description = "";
        // }
        result = {
          ...result,
          itinerary: [...arr],
        };
      }
      if (details?.nonEuropean_itinerary?.length) {
        let arr = details?.nonEuropean_itinerary?.map((ele, i) => {
          let obj = {
            title: ele?.title,
            description: ele?.description,
            activity: ele?.activity,
            attraction: ele?.attraction,
            map_image: ele?.map_image
              ? [
                  {
                    type: ele?.map_image?.split(".").pop(),
                    url: `${IMAGE_BASE_URL}${path}/${ele?.map_image}`,
                    name: `${ele?.map_image}`,
                    response: `${ele?.map_image}`,
                  },
                ]
              : "",
            map_image_alt: ele?.map_image_alt,
            mobile_map_image: ele?.mobile_map_image
              ? [
                  {
                    type: ele?.mobile_map_image?.split(".").pop(),
                    url: `${IMAGE_BASE_URL}${path}/${ele?.mobile_map_image}`,
                    name: `${ele?.mobile_map_image}`,
                    response: `${ele?.mobile_map_image}`,
                  },
                ]
              : "",
            mobile_map_image_alt: ele?.mobile_map_image_alt,
          };
          return obj;
        });
        // if (arr.length > 0) {
        //   arr[0].title = "Travel";
        //   // arr[0].description = "";
        //   arr[arr.length - 1].title = "Travel";
        //   // arr[arr.length - 1].description = "";
        // }
        result = {
          ...result,
          nonEuropean_itinerary: [...arr],
        };
      } else {
        if (details?.itinerary?.length) {
          let arr = details?.itinerary?.map((ele, i) => {
            let obj = {
              title: ele?.title,
              description: ele?.description,
              activity: ele?.activity,
              attraction: ele?.attraction,
              map_image: ele?.map_image
                ? [
                    {
                      type: ele?.map_image?.split(".").pop(),
                      url: `${IMAGE_BASE_URL}${path}/${ele?.map_image}`,
                      name: `${ele?.map_image}`,
                      response: `${ele?.map_image}`,
                    },
                  ]
                : "",
              map_image_alt: ele?.map_image_alt,
              mobile_map_image: ele?.mobile_map_image
                ? [
                    {
                      type: ele?.mobile_map_image?.split(".").pop(),
                      url: `${IMAGE_BASE_URL}${path}/${ele?.mobile_map_image}`,
                      name: `${ele?.mobile_map_image}`,
                      response: `${ele?.mobile_map_image}`,
                    },
                  ]
                : "",
              mobile_map_image_alt: ele?.mobile_map_image_alt,
            };
            return obj;
          });
          // if (arr.length > 0) {
          //   arr[0].title = "Travel";
          //   // arr[0].description = "";
          //   arr[arr.length - 1].title = "Travel";
          //   // arr[arr.length - 1].description = "";
          // }
          result = {
            ...result,
            nonEuropean_itinerary: [...arr],
          };
        }
      }
      if (details?.highlights?.length) {
        let arr = details?.highlights?.map((ele, i) => {
          let obj = {
            title: ele?.title,
            description: ele?.description,
            link_type: linkTypeList?.filter(
              (item) => item?.value == ele?.link_type
            )[0],
            link_item:
              activityData?.filter((item) => item?._id == ele?.link_item)[0] ||
              attractionData?.filter((item) => item?._id == ele?.link_item)[0],
          };
          return obj;
        });
        result = {
          ...result,
          highlights: [...arr],
        };
      }
      setInputs({ ...result });
      setData({
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      });
    }
  }, [details]);

  useEffect(() => {
    if (inputs?.duration?.length && !isNaN(Number(inputs?.duration))) {
      const numberOfDays = Number(inputs?.duration);
      if (
        numberOfDays !== inputs?.itinerary?.length ||
        numberOfDays !== inputs?.nonEuropean_itinerary?.length
      ) {
        const updatedItinerary = calculateItinerary(
          inputs?.itinerary ?? [],
          numberOfDays,
          false
        );
        const updatedNonEuropeanItinerary = calculateItinerary(
          inputs?.nonEuropean_itinerary ?? [],
          numberOfDays,
          true
        );
        setInputs({
          ...inputs,
          itinerary: updatedItinerary,
          nonEuropean_itinerary: updatedNonEuropeanItinerary,
        });
      }
    }
  }, [inputs?.duration]);

  const calculateItinerary = (itinerary, days, isNonEuropean = false) => {
    let diff = days - itinerary.length;
    let updatedItinerary = [...itinerary];
    if (diff > 0) {
      let temp = [];
      for (let i = 0; i < diff; i++) {
        temp.push({
          title: "",
          description: "",
          activity: [],
          attraction: [],
          map_image: "",
          map_image_alt: "",
          mobile_map_image: "",
          mobile_map_image_alt: "",
        });
      }
      updatedItinerary = [...itinerary, ...temp];
    } else if (diff < 0) {
      if (days >= 2) {
        updatedItinerary = updatedItinerary.slice(0, days);
      }
    }
    if (updatedItinerary.length > 0) {
      updatedItinerary[0].title =
        updatedItinerary[0].title == "" ? "Travel" : updatedItinerary[0].title;
    }
    if (isNonEuropean && updatedItinerary.length > 1) {
      updatedItinerary[updatedItinerary.length - 1].title =
        updatedItinerary[updatedItinerary.length - 1].title == ""
          ? "Travel"
          : updatedItinerary[updatedItinerary.length - 1].title;
    }
    return updatedItinerary;
  };

  const handleTabChange = (value, error) => {
    setActiveTab(value);
    if (error) {
      setErrors({
        ...errors,
        ...error,
      });
    }
  };

  const singleFileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    mainField,
    updateBoth = false
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "package");
        dispatch(
          uploadFile(formData, (res) => {
            if (field === "thumbnail") {
              setInputs((s) => ({
                ...s,
                thumbnail_alt: s?.package_name || "",
              }));
            }

            if (field === "main_map") {
              setInputs((s) => ({
                ...s,
                main_map_alt: s?.package_name || "",
              }));
            }
            let tempValue = [
              {
                type: e[0].type,
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ];
            setFunction(field, tempValue, index, mainField, updateBoth);
          })
        );
      }
    }
  };

  const mainInputFileUpdateHandler = (field, tempValue) => {
    setErrors({
      ...errors,
      [field]: "",
    });
    setInputs((s) => ({
      ...s,
      [field]: tempValue,
    }));
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  console.log(errors);

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "package");
        dispatch(
          uploadFile(formData, (res) => {
            setImageLoader(false);
            let oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  // ...ele,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [...oldFiles, ...arr],
            }));
          })
        );
      }
    }
  };

  const altChangeHandler = (key, value, index, field, id) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${key}_${id}`]: "",
    });
  };

  function getId(url) {
    setErrors({ ...errors, videoUrl: "" });
    setVideoUrl(url);
    // var regExp =
    //   /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    // var match = url.match(regExp);

    // if (match && match[2].length == 11) {
    //   setVideoUrl("https://www.youtube.com/embed/" + match[2]);
    //   setErrors({...errors, videoUrl: ""})
    // } else {
    //   setErrors({...errors, videoUrl: "Please enter a valid youtube url"})
    //   return "error";
    // }
  }

  const handleAddVideoUrl = () => {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = videoUrl.match(regExp);

    let finalUrl = "";

    if (match && match[2].length == 11) {
      finalUrl = "https://www.youtube.com/embed/" + match[2];
      setErrors({ ...errors, videoUrl: "" });
    } else {
      setErrors({ ...errors, videoUrl: "Please enter a valid youtube url" });
      return "error";
    }

    if (finalUrl) {
      setErrors({
        ...errors,
        gallery: "",
      });
      let dataList = { ...inputs };
      dataList?.gallery?.push({
        // ...ele,
        url: finalUrl,
        response: finalUrl,
        type: "video",
        id: finalUrl + new Date().getTime() + 1,
        alt: "",
      });
      setInputs(dataList);
      setVideoUrl("");
    }
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const handleInputDelete = (id, field) => {
    if (field === "blog") {
      if (!deletedBlogs?.includes(id)) {
        setDeletedBlogs([...deletedBlogs, id]);
      }
    }
    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?._id !== id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  const inputBlogHandler = (value, key) => {
    setInputs({
      ...inputs,
      [key]: value,
    });
    if (deletedBlogs?.length) {
      const objectIds = value.map((obj) => obj?._id);
      let idArray = deletedBlogs.filter((id) => !objectIds.includes(id));
      setDeletedBlogs([...idArray]);
    }
  };

  const handleImageInputDelete = (file, field) => {
    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  // const inputDataChangeHandler = (key, value, index, field) => {
  //   let dataList = { ...inputs };
  //   dataList[field][index][key] = value;
  //   setInputs(dataList);
  //   setErrors({
  //     ...errors,
  //     [`${field}_${key}_${index}`]: "",
  //   });
  // };

  const inputDataChangeHandler = (
    key,
    value,
    index,
    field,
    updateBoth = false
  ) => {
    let dataList = { ...inputs };
    if (dataList[field]) {
      dataList[field][index][key] = value;
    }
    if (updateBoth && field === "itinerary") {
      if (
        dataList.nonEuropean_itinerary &&
        dataList.nonEuropean_itinerary[index]
      ) {
        dataList.nonEuropean_itinerary[index][key] = value;
      }
    }
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const inputDataRemoveChangeHandler = (key, id, field, mainIndex) => {
    let dataList = { ...inputs };
    let temp = [...dataList?.[field]?.[mainIndex][key]];
    setInputs(dataList);
    if (temp?.length) {
      dataList[field][mainIndex][key] = temp?.filter((ele) => ele?._id !== id);
      setInputs(dataList);
    }
  };

  const packageValidationRequired = [
    "package_name",
    "thumbnail",
    "thumbnail_alt",
    "banner_image",
    "season",
    "duration",
    // "essential_info",
    "rank",
  ];

  const itineraryValidationRequired = ["main_map", "main_map_alt"];

  const hightlightValidationRequired = [];

  const costValidationRequired = ["fixed_package_cost"];

  const galleryValidationRequired = [];

  const errorHighLight = (requireFields, error) => {
    if (requireFields?.some((ele) => error[ele])) {
      return true;
    } else {
      return false;
    }
  };

  const bannerValidateHelper = () => {
    let temp = {};
    if (inputs?.banner_image?.length == 0) {
      temp = {
        ...temp,
        banner_image: "Please upload banner images",
      };
    }
    //  else {
    //   inputs?.banner_image?.forEach((ele, i) => {
    //     if (!ele.alt?.trim()?.length) {
    //       temp[`alt_${ele?.id}`] = "Please enter alt text";
    //     }
    //   });
    // }

    return temp;
  };

  const bannerValidate = (error) => {
    let temp = bannerValidateHelper();
    if (Object.values(temp).length != 0) {
      setHighlightPageErr([...highlightPageErr, "package"]);
      setErrors({
        ...error,
        ...temp,
      });
      return false;
    } else {
      setHighlightPageErr(highlightPageErr?.filter((ele) => ele !== "package"));
      setErr({ ...error });
      return true;
    }
  };

  const itineraryValidateHelper = () => {
    let temp = {};
    if (inputs?.itinerary?.length) {
      inputs?.itinerary?.forEach((ele, i) => {
        if (!ele?.title?.trim()?.length) {
          temp[`${"itinerary"}_${"title"}_${i}`] = "Please enter title";
        }
        if (ele?.description == "") {
          temp[`${"itinerary"}_${"description"}_${i}`] =
            "Please enter description";
        }
        if (i != 0) {
          if (ele?.map_image == "") {
            temp[`${"itinerary"}_${"map_image"}_${i}`] =
              "Please upload map image";
          }
          // if (!ele?.map_image_alt?.trim()?.length) {
          //   temp[`${"itinerary"}_${"map_image_alt"}_${i}`] =
          //     "Please enter map alt text";
          // }
          if (ele?.mobile_map_image == "") {
            temp[`${"itinerary"}_${"Itinerary_image"}_${i}`] =
              "Please upload itinerary image";
          }
          // if (!ele?.mobile_map_image_alt?.trim()?.length) {
          //   temp[`${"itinerary"}_${"mobile_map_image_alt"}_${i}`] =
          //     "Please enter mobile image alt text";
          // }
        }
      });
    }
    if (inputs?.nonEuropean_itinerary?.length) {
      inputs?.nonEuropean_itinerary?.forEach((ele, i) => {
        if (!ele?.title?.trim()?.length) {
          temp[`${"nonEuropean_itinerary"}_${"title"}_${i}`] =
            "Please enter title";
        }
        if (ele?.description == "") {
          temp[`${"nonEuropean_itinerary"}_${"description"}_${i}`] =
            "Please enter description";
        }
        if (i != 0 && i !== inputs?.nonEuropean_itinerary?.length - 1) {
          if (ele?.map_image == "") {
            temp[`${"nonEuropean_itinerary"}_${"map_image"}_${i}`] =
              "Please upload map image";
          }
          // if (!ele?.map_image_alt?.trim()?.length) {
          //   temp[`${"itinerary"}_${"map_image_alt"}_${i}`] =
          //     "Please enter map alt text";
          // }
          if (ele?.mobile_map_image == "") {
            temp[`${"nonEuropean_itinerary"}_${"Itinerary_image"}_${i}`] =
              "Please upload itinerary image";
          }
          // if (!ele?.mobile_map_image_alt?.trim()?.length) {
          //   temp[`${"itinerary"}_${"mobile_map_image_alt"}_${i}`] =
          //     "Please enter mobile image alt text";
          // }
        }
      });
    }
    return temp;
  };

  const itineraryValidate = (error) => {
    let temp = itineraryValidateHelper();
    if (Object.values(temp).length != 0) {
      setHighlightPageErr([...highlightPageErr, "itinerary"]);
      setErrors({
        ...error,
        ...temp,
      });
      return false;
    } else {
      setHighlightPageErr(
        highlightPageErr?.filter((ele) => ele !== "itinerary")
      );
      setErr({ ...error });
      return true;
    }
  };

  const highlightValidateHelper = () => {
    let temp = {};
    if (inputs?.highlights?.length) {
      inputs?.highlights?.forEach((ele, i) => {
        if (!ele?.title?.trim()?.length) {
          temp[`${"highlights"}_${"title"}_${i}`] =
            "Please enter highlight title";
        }
        if (ele?.description == "") {
          temp[`${"highlights"}_${"description"}_${i}`] =
            "Please enter highlight description";
        }
      });
    }
    return temp;
  };

  const highlightValidate = (error) => {
    let temp = highlightValidateHelper();
    if (Object.values(temp).length != 0) {
      setHighlightPageErr([...highlightPageErr, "highlight"]);
      setErrors({
        ...error,
        ...temp,
      });
      return false;
    } else {
      setHighlightPageErr(
        highlightPageErr?.filter((ele) => ele !== "highlight")
      );
      setErr({ ...error });
      return true;
    }
  };

  // const galleryValidateHelper = () => {
  //   let temp = {};
  //   if (inputs?.gallery?.length) {
  //     inputs?.gallery?.forEach((ele, i) => {
  //       if (!ele.alt?.trim()?.length) {
  //         temp[`alt_${ele?.id}`] = "Please enter alt text";
  //       }
  //     });
  //   }
  //   return temp;
  // };

  // const galleryValidate = (error) => {
  //   // let temp = galleryValidateHelper();
  //   if (Object.values(temp).length != 0) {
  //     setHighlightPageErr([...highlightPageErr, "gallery"]);
  //     setErrors({
  //       ...error,
  //       ...temp,
  //     });
  //     return false;
  //   } else {
  //     setHighlightPageErr(highlightPageErr?.filter((ele) => ele !== "gallery"));
  //     setErrors({ ...error });
  //     return true;
  //   }
  // };

  const nextValidation = async (tab, requireFields, additionalValidate) => {
    let isValidated = requireFields?.length
      ? await validate({
          required: {
            accept: requireFields,
          },
          returnErrors: true,
        })
      : { valid: true, err: {} };
    let isValidated2 = additionalValidate
      ? additionalValidate(isValidated?.err)
      : true;
    if (isValidated?.valid && isValidated2) {
      handleTabChange(tab);
    }
  };

  const validHighLight = () => {
    if (
      [
        ...packageValidationRequired,
        ...itineraryValidationRequired,
        ...costValidationRequired,
      ]?.some((ele) => inputs[ele] == "")
    ) {
      let temp = [...highlightPageErr];
      if ([...packageValidationRequired]?.some((ele) => inputs[ele] == "")) {
        temp = [...temp, "package"];
      }
      if ([...itineraryValidationRequired]?.some((ele) => inputs[ele] == "")) {
        temp = [...temp, "itinerary"];
      }
      if ([...costValidationRequired]?.some((ele) => inputs[ele] == "")) {
        temp = [...temp, "cost"];
      }
      setHighlightPageErr([...temp]);
      return false;
    } else {
      highlightPageErr?.filter((ele) => ele !== "error");
      return true;
    }
  };

  const fullValidation = () => {
    let temp = {};
    if (!inputs?.package_name) {
      temp = {
        ...temp,
        package_name: "Please enter package name",
      };
    }
    // if (!inputs?.thumbnail_alt) {
    //   temp = {
    //     ...temp,
    //     thumbnail_alt: "Please enter thumbnail alt",
    //   };
    // }
    if (!inputs?.thumbnail) {
      temp = {
        ...temp,
        thumbnail: "Please upload thumbnail image",
      };
    }
    if (!inputs?.season) {
      temp = {
        ...temp,
        season: "Please select season",
      };
    }
    if (!inputs?.duration) {
      temp = {
        ...temp,
        duration: "Please enter duration",
      };
    }
    if (!inputs?.rank) {
      temp = {
        ...temp,
        rank: "Please enter rank",
      };
    }
    if (!inputs?.banner_image) {
      temp = {
        ...temp,
        banner_image: "Please upload banner images",
      };
    }
    // } else {
    //   inputs?.banner_image?.forEach((ele, i) => {
    //     if (!ele.alt?.trim()?.length) {
    //       temp[`alt_${ele?.id}`] = "Please enter alt text";
    //     }
    //   });
    // }
    // if (!inputs?.main_map_alt) {
    //   temp = {
    //     ...temp,
    //     main_map_alt: "Please enter map alt",
    //   };
    // }
    if (!inputs?.main_map) {
      temp = {
        ...temp,
        main_map: "Please upload map image",
      };
    }
    if (!inputs?.fixed_package_cost) {
      temp = {
        ...temp,
        fixed_package_cost: "Please enter cost",
      };
    }
    if (!inputs?.is_active) {
      temp = {
        ...temp,
        is_active: "Please select a status",
      };
    }
    temp = {
      ...temp,
      ...bannerValidateHelper(),
      ...itineraryValidateHelper(),
      ...highlightValidateHelper(),
      // ...galleryValidateHelper(),
    };

    if (Object.values(temp).length != 0) {
      let err = [];
      if (Object.values(bannerValidateHelper()).length != 0) {
        err = [...err, "package"];
      }
      if (Object.values(itineraryValidateHelper()).length != 0) {
        err = [...err, "itinerary"];
      }
      if (Object.values(highlightValidateHelper()).length != 0) {
        err = [...err, "highlight"];
      }
      // if (Object.values(galleryValidateHelper()).length != 0) {
      //   err = [...err, "gallery"];
      // }
      // err = [...err, "error"];
      setHighlightPageErr([...err]);
      setErrors({
        ...temp,
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      setHighlightPageErr(highlightPageErr?.filter((ele) => ele !== "error"));
      setErr({});
      return true;
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    setErr({});
    navigate("/manage-packages");
  };

  const onSubmitHandler = async (type) => {
    let isValidated = false;
    let isValidated2 = false;
    if (type == "save") {
      if (inputs?.package_name?.trim()?.length) {
        isValidated = true;
      } else {
        setErrors({ ...errors, package_name: "Please enter package name" });
        isValidated = false;
      }
      isValidated2 = true;
    } else {
      // isValidated = await validate();
      isValidated = fullValidation();
      // isValidated2 = validHighLight();
      isValidated2 = true;
    }
    if (isValidated && isValidated2) {
      finalSubmitHandler(type);
    }
  };

  const finalSubmitHandler = (type) => {
    let raw = {
      package_name: inputs?.package_name?.trim(),
      thumbnail: inputs?.thumbnail?.[0]?.response ?? "",
      thumbnail_alt: inputs?.thumbnail_alt?.trim(),
      banner_image: inputs?.banner_image?.map((item, i) => {
        return {
          [`banner_item`]: item?.response,
          [`banner_alt`]: item?.alt?.trim(),
          [`banner_type`]: item?.type ?? "image",
        };
      }),
      overview: inputs?.overview,
      duration: inputs?.duration,
      klm: inputs?.klm,
      season: inputs?.season?._id,
      inclusions: inputs?.inclusions?.map((item, i) => item?._id),
      exclusions: inputs?.exclusions?.map((item, i) => item?._id),
      blog: inputs?.blog?.map((item, i) => item?._id),
      // essential_info: inputs?.essential_info,
      rank: inputs?.rank,
      main_map: inputs?.main_map?.[0]?.response ?? "",
      main_map_alt: inputs?.main_map_alt?.trim(),
      itinerary: inputs?.itinerary?.map((item, i) => {
        return {
          [`title`]: item?.title?.trim(),
          [`description`]: item?.description,
          [`activity`]:
            i == 0 ? [] : item?.activity?.map((item, i) => item?._id),
          [`attraction`]:
            i == 0 ? [] : item?.attraction?.map((item, i) => item?._id),
          [`map_image`]: i == 0 ? "" : item?.map_image?.[0]?.response ?? "",
          [`map_image_alt`]: i == 0 ? "" : item?.map_image_alt,
          [`mobile_map_image`]:
            i == 0 ? "" : item?.mobile_map_image?.[0]?.response ?? "",
          [`mobile_map_image_alt`]: i == 0 ? "" : item?.mobile_map_image_alt,
        };
      }),
      nonEuropean_itinerary: inputs?.nonEuropean_itinerary?.map((item, i) => {
        return {
          [`title`]: item?.title?.trim(),
          [`description`]: item?.description,
          [`activity`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? []
              : item?.activity?.map((item, i) => item?._id),
          [`attraction`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? []
              : item?.attraction?.map((item, i) => item?._id),
          [`map_image`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? ""
              : item?.map_image?.[0]?.response ?? "",
          [`map_image_alt`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? ""
              : item?.map_image_alt,
          [`mobile_map_image`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? ""
              : item?.mobile_map_image?.[0]?.response ?? "",
          [`mobile_map_image_alt`]:
            i == 0 || i == inputs?.nonEuropean_itinerary?.length - 1
              ? ""
              : item?.mobile_map_image_alt,
        };
      }),
      highlights: inputs?.highlights?.map((item, i) => {
        return {
          [`title`]: item?.title?.trim(),
          [`description`]: item?.description,
          [`link_type`]: item?.link_type?.value,
          [`link_item`]: item?.link_item?._id,
        };
      }),
      fixed_package_cost: inputs?.fixed_package_cost,
      gallery: inputs?.gallery?.map((item, i) => {
        return {
          [`gallery_item`]: item?.response,
          [`gallery_alt`]: item?.alt?.trim(),
          [`gallery_type`]: item?.type ?? "image",
        };
      }),
      meta_title: data?.meta_title?.trim(),
      meta_keyword: data?.meta_keyword?.trim(),
      meta_description: data?.meta_description?.trim(),
      is_published: type == "submit" ? true : details?.is_published ?? false,
      is_active: inputs?.is_active.toString(),
      delete_blog: deletedBlogs?.map((item, i) => item),
      track_id: trackId,
      path: "package",
    };
    if (id) {
      raw = {
        ...raw,
        id: id,
      };

      dispatch(
        updatePackage(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertPackage(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">
              {id ? "Update" : "Add"} Package{" "}
            </h5>
            <div className="row">
              <Tabs.Root
                className="TabsRoot"
                defaultValue="tab1"
                value={activeTab}
                onValueChange={(e) => handleTabChange(e, errors)}
              >
                <Tabs.List
                  className="TabsList"
                  aria-label="Manage your account"
                >
                  <Tabs.Trigger
                    className={`${
                      errorHighLight(packageValidationRequired, errors) ||
                      highlightPageErr?.includes("package") ||
                      highlightPageErr?.includes("error")
                        ? "error"
                        : ""
                    } TabsTrigger`}
                    value="tab1"
                  >
                    Package Details
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    className={`${
                      errorHighLight(itineraryValidationRequired, errors) ||
                      highlightPageErr?.includes("itinerary") ||
                      highlightPageErr?.includes("error")
                        ? "error"
                        : ""
                    } TabsTrigger`}
                    value="tab2"
                  >
                    Itinerary
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    className={`${
                      errorHighLight(hightlightValidationRequired, errors) ||
                      highlightPageErr?.includes("highlight") ||
                      highlightPageErr?.includes("error")
                        ? "error"
                        : ""
                    } TabsTrigger`}
                    value="tab3"
                  >
                    Highlights
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    className={`${
                      errorHighLight(costValidationRequired, errors) ||
                      highlightPageErr?.includes("error")
                        ? "error"
                        : ""
                    } TabsTrigger`}
                    value="tab4"
                  >
                    Costing
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    className={`${
                      errorHighLight(galleryValidationRequired, errors) ||
                      highlightPageErr?.includes("gallery")
                        ? //  ||
                          // highlightPageErr?.includes("error")
                          "error"
                        : ""
                    } TabsTrigger`}
                    value="tab5"
                  >
                    Gallery
                  </Tabs.Trigger>
                  <Tabs.Trigger className="TabsTrigger" value="tab6">
                    SEO
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content className="TabsContent" value="tab1">
                  <PackageDetails
                    inputs={inputs}
                    errors={errors}
                    formPlug={formPlug}
                    inputHandler={inputHandler}
                    setErrors={setErrors}
                    singleFileUploadHandler={singleFileUploadHandler}
                    onDetailsEditorChange={onDetailsEditorChange}
                    removeImage={removeImage}
                    inclusionData={inclusionData}
                    exclusionData={exclusionData}
                    seasonList={seasonList}
                    blogData={blogData}
                    handleInputDelete={handleInputDelete}
                    trackId={trackId}
                    err={err}
                    altChangeHandler={altChangeHandler}
                    imageLoader={imageLoader}
                    fileSelectedHandler={fileSelectedHandler}
                    mainInputFileUpdateHandler={mainInputFileUpdateHandler}
                    setInputs={setInputs}
                    handleImageInputDelete={handleImageInputDelete}
                    inputBlogHandler={inputBlogHandler}
                    packageMasterData={packageMasterData}
                  />
                  <div className="col-12 mt-3">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() =>
                          nextValidation(
                            "tab2",
                            packageValidationRequired,
                            bannerValidate
                          )
                        }
                      >
                        Next <img src="/assets/img/icons/right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content className="TabsContent" value="tab2">
                  <Itinerary
                    inputs={inputs}
                    errors={errors}
                    formPlug={formPlug}
                    inputHandler={inputHandler}
                    onDetailsEditorChange={onDetailsEditorChange}
                    setErrors={setErrors}
                    singleFileUploadHandler={singleFileUploadHandler}
                    removeImage={removeImage}
                    activityData={activityData}
                    attractionData={attractionData}
                    setInputs={setInputs}
                    inputDataChangeHandler={inputDataChangeHandler}
                    inputDataRemoveChangeHandler={inputDataRemoveChangeHandler}
                    mainInputFileUpdateHandler={mainInputFileUpdateHandler}
                    validate={validate}
                    highlightPageErr={highlightPageErr}
                  />
                  <div className="col-12 ">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => handleTabChange("tab1")}
                      >
                        <img src="/assets/img/icons/left.svg" alt="" /> Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          nextValidation(
                            "tab3",
                            itineraryValidationRequired,
                            itineraryValidate
                          )
                        }
                      >
                        Next <img src="/assets/img/icons/right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Tabs.Content>

                <Tabs.Content className="TabsContent" value="tab3">
                  <Highlights
                    inputs={inputs}
                    setInputs={setInputs}
                    errors={errors}
                    formPlug={formPlug}
                    inputHandler={inputHandler}
                    onDetailsEditorChange={onDetailsEditorChange}
                    linkTypeList={linkTypeList}
                    inputDataChangeHandler={inputDataChangeHandler}
                    setErrors={setErrors}
                  />

                  <div className="col-12 ">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => handleTabChange("tab2")}
                      >
                        <img src="/assets/img/icons/left.svg" alt="" /> Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          nextValidation(
                            "tab4",
                            hightlightValidationRequired,
                            highlightValidate
                          )
                        }
                      >
                        Next <img src="/assets/img/icons/right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content className="TabsContent" value="tab4">
                  <Costing
                    inputs={inputs}
                    errors={errors}
                    formPlug={formPlug}
                    inputHandler={inputHandler}
                    setErrors={setErrors}
                  />
                  <div className="col-12">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => handleTabChange("tab3")}
                      >
                        <img src="/assets/img/icons/left.svg" alt="" /> Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          nextValidation("tab5", costValidationRequired)
                        }
                      >
                        Next <img src="/assets/img/icons/right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Tabs.Content>

                <Tabs.Content className="TabsContent" value="tab5">
                  <Gallery
                    inputs={inputs}
                    setInputs={setInputs}
                    errors={errors}
                    setErrors={setErrors}
                    fileSelectedHandler={fileSelectedHandler}
                    imageLoader={imageLoader}
                    getId={getId}
                    videoUrl={videoUrl}
                    handleAddVideoUrl={handleAddVideoUrl}
                    handleImageInputDelete={handleImageInputDelete}
                    trackId={trackId}
                    altChangeHandler={altChangeHandler}
                  />
                  <div className="col-12">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => handleTabChange("tab4")}
                      >
                        <img src="/assets/img/icons/left.svg" alt="" /> Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          nextValidation(
                            "tab6",
                            galleryValidationRequired
                            // galleryValidate
                          )
                        }
                      >
                        Next <img src="/assets/img/icons/right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </Tabs.Content>

                <Tabs.Content className="TabsContent" value="tab6">
                  <Seo data={data} onChangeHandler={onChangeHandler} />

                  <div className="col-12 ">
                    <div className="next_prev_btns">
                      <button
                        type="submit"
                        className="btn btn-primary me-3"
                        onClick={() => handleTabChange("tab5")}
                      >
                        <img src="/assets/img/icons/left.svg" alt="" /> Back
                      </button>
                    </div>
                  </div>
                </Tabs.Content>
              </Tabs.Root>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <RadioButtons
                  label={"Status"}
                  buttons={[
                    {
                      label: "Active",
                      value: true,
                    },
                    {
                      label: "Inactive",
                      value: false,
                    },
                  ]}
                  name="is_active"
                  placeholder={"Select any option"}
                  required={{ message: "Please select  an option" }}
                  field={"is_active"}
                  error={errors.is_active}
                  value={{
                    is_active: inputs?.is_active,
                  }}
                  onChange={(value, key) => inputHandler(value, key)}
                  inputSocket={formPlug}
                />
              </div>

              <div className="col-12">
                {details?.is_published ? (
                  <></>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    onClick={() => onSubmitHandler("save")}
                  >
                    Save
                  </button>
                )}

                <button
                  type="submit"
                  className="btn btn-primary me-3"
                  onClick={() => onSubmitHandler("submit")}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <History /> */}
      </div>
    </div>
  );
}
