import React, { act, useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import Input from "../../../commonComponents/Input/Input";
import Select from "../../../commonComponents/Select/Select";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import DeleteIcon from "../../../assets/icons/DELETE.svg";
import { scrollToTop } from "../../../utils/helpers";

export default function Highlights({
  inputs,
  errors,
  formPlug,
  inputHandler,
  linkTypeList,
  inputDataChangeHandler,
  setInputs,
  setErrors,
}) {
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop();
  }, []);

  const addHighLightHandler = () => {
    setInputs({
      ...inputs,
      highlights: [
        ...inputs?.highlights,
        { title: "", description: "", link_type: "", link_item: "" },
      ],
    });
    setActiveKey(inputs?.highlights?.length);
  };

  let linkItemsList = useMemo(() => {
    let activityArray = [];
    let attractionArray = [];
    if (inputs?.itinerary?.length) {
      inputs?.itinerary?.forEach((ele) => {
        ele?.activity?.forEach((el) => {
          if (!activityArray?.find((e) => e?._id === el?._id)) {
            activityArray?.push(el);
          }
        });
        ele?.attraction?.forEach((el) => {
          if (!attractionArray?.find((e) => e?._id === el?._id)) {
            attractionArray?.push(el);
          }
        });
      });
    }
    return {
      activityArray,
      attractionArray,
    };
  }, [inputs?.itinerary]);

  const removeHighlights = (i) => {
    setInputs({
      ...inputs,
      highlights: inputs?.highlights?.filter((el, index) => index !== i),
    });
  };

  return (
    <>
      <div className="mt-5">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {inputs?.highlights?.map((ele, index) => {
            return (
              <div className="row">
                <div
                  className={
                    index === 0 && inputs?.highlights?.length === 1
                      ? "col-md-12 w-100 w-full"
                      : "col-md-11"
                  }
                >
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header
                      onClick={() =>
                        setActiveKey(activeKey === index ? null : index)
                      }
                    >
                      Highlight {index + 1}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="row mb-5">
                            <div className="col-12 col-md-4">
                              <Input
                                className="css-inp-esc1 mb-3"
                                label="Highlight Name"
                                placeholder="Type here"
                                required={{
                                  message: "Please enter highlight name",
                                }}
                                field={"title"}
                                inputSocket={formPlug}
                                value={ele?.title}
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "highlights"
                                  );
                                }}
                                error={
                                  errors[`${"highlights"}_${"title"}_${index}`]
                                }
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <Select
                                title="Link Type"
                                className="css-slc-esc1 mb-3"
                                placeholder={"Select"}
                                options={linkTypeList}
                                labelSetter={(option) => option?.label}
                                valueSetter={(option) => option.value}
                                field={"link_type"}
                                inputSocket={formPlug}
                                value={ele.link_type}
                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "highlights"
                                  );
                                }}
                                error={
                                  errors[
                                    `${"highlights"}_${"link_type"}_${index}`
                                  ]
                                }
                                // required={{ message: "Please select activity" }}
                              />
                            </div>
                            {ele?.link_type &&
                            inputs?.itinerary?.length &&
                            (linkItemsList?.activityArray?.length ||
                              linkItemsList?.attractionArray?.length) ? (
                              <div className="col-12 col-md-4">
                                <Select
                                  title="Linked Item"
                                  className="css-slc-esc1 mb-3"
                                  placeholder={"Select"}
                                  options={
                                    ele?.link_type?.value === "activity"
                                      ? linkItemsList?.activityArray
                                      : linkItemsList?.attractionArray
                                  }
                                  labelSetter={(o) => o?.title}
                                  valueSetter={(option) => option._id}
                                  field={"link_item"}
                                  error={
                                    errors[
                                      `${"highlights"}_${"link_item"}_${index}`
                                    ]
                                  }
                                  value={ele.link_item}
                                  onChange={(e, key) => {
                                    inputDataChangeHandler(
                                      key,
                                      e,
                                      index,
                                      "highlights"
                                    );
                                  }}
                                  inputSocket={formPlug}
                                />
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="col-12">
                              <TextEditor
                                // config={editorConfigEn}
                                label={"Highlight Description (Maximum character 255)"}
                                className={"mb-3"}
                                field={"description"}
                                validation = {{maxChar:255}}
                                onBlur={(newContent) =>
                                  inputDataChangeHandler(
                                    "description",
                                    newContent,
                                    index,
                                    "highlights"
                                  )
                                }

                                onChange={(e, key) => {
                                  inputDataChangeHandler(
                                    key,
                                    e,
                                    index,
                                    "highlights"
                                  );
                                }}
                                value={ele?.description}
                                error={
                                  errors[
                                    `${"highlights"}_${"description"}_${index}`
                                  ]
                                }
                                inputSocket={formPlug}
                                required={{
                                  message: "Please enter description",
                                }}
                                placeholder="Type Here"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                {index === 0 && inputs?.highlights?.length === 1 ? (
                  ""
                ) : (
                  <div className="col-md-1 d-flex align-items-start justify-content-between mt-2">
                    <img
                      src={DeleteIcon}
                      onClick={() => removeHighlights(index)}
                      width={35}
                      alt=""
                    />
                  </div>
                )}
              </div>
            );
          })}
        </Accordion>

        <div className="text-end mt-4">
          <button className="btn btn-success" onClick={addHighLightHandler}>
            Add Highlight
          </button>
        </div>
      </div>
    </>
  );
}
