import React, { useEffect, useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import feather from "feather-icons";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserPermissions } from "../../redux/actionCreator";

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const permissions = JSON.parse(localStorage.getItem("permission") || "[]");
  const is_admin = localStorage.getItem("role") === "super_admin";

  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    const sidebar = document.querySelector(".page-sidebar");
    if (sidebar) {
      new PerfectScrollbar(sidebar);
    }
    feather.replace();

    menuItems.forEach((section, sectionIndex) => {
      section.items.forEach((item, index) => {
        const currentIndex = `${section.id}-${index}`;
        if (
          item.to === location.pathname ||
          (item.children &&
            item.children.some((child) => child.to === location.pathname))
        ) {
          setOpenIndex(currentIndex);
        }
      });
    });
  }, []);

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const menuItems = [
    {
      id: 1,
      title: "Main",
      items: [
        { to: "/", icon: "layout", label: "Dashboard" },
        {
          to: "/manage-blogs",
          icon: "file-text",
          label: "Blogs",
          key: "blog",
        },
        {
          to: "/manage-bookings",
          icon: "book-open",
          label: "Manage Booking",
          key: "booking",
        },
        {
          to: "/manage-enquiries",
          icon: "phone",
          label: "Enquiries",
          key: "enquiry",
        },
      ],
    },

    {
      id: 2,
      title: "Sub Menu",
      items: [
        {
          to: "/manage-packages",
          icon: "briefcase",
          label: "Packages",
          key: "packages",
        },
        {
          to: "/manage-reviews",
          icon: "star",
          label: "Reviews",
          key: "review",
        },
        {
          to: "/manage-promocodes",
          icon: "gift",
          label: "Promo Codes",
          key: "promo_code",
        },
        // {
        //   to: "/manage-reviews",
        //   icon: "star",
        //   label: "Customer Search",
        //   key: "customer_search",
        // },
        {
          to: "/home",
          icon: "plus-square",
          label: "CMS",
          key: "cms",
          children: [
            { to: "/home", icon: "circle", label: "Home", key: "cms" },
            { to: "/about", icon: "circle", label: "About", key: "cms" },
            { to: "/curated", icon: "circle", label: "Curated", key: "cms" },
            {
              to: "/package-page",
              icon: "circle",
              label: "Package Page",
              key: "cms",
            },
            { to: "/contact", icon: "circle", label: "Contact", key: "cms" },
            {
              to: "/terms-condition",
              icon: "circle",
              label: "Terms & Condition",
              key: "cms",
            },
            {
              to: "/booking-process",
              icon: "circle",
              label: "Booking Process",
              key: "cms",
            },
            {
              to: "/cancel-policy",
              icon: "circle",
              label: "Cancel Policy",
              key: "cms",
            },
            {
              to: "/privacy-policy",
              icon: "circle",
              label: "Policy",
              key: "cms",
            },
            {
              to: "/account-info",
              icon: "circle",
              label: "Account Info",
              key: "cms",
            },
            {
              to: "/general-page",
              icon: "circle",
              label: "General",
              key: "cms",
            },
          ],
        },

        {
          to: "/manage-permission",
          icon: "user-check",
          label: "User Permissions",
          key: "user_permission",
        },
      ],
    },
    {
      id: 3,
      title: "Settings",
      items: [
        // {
        //   to: "/manage-flights",
        //   icon: "plus",
        //   label: "Flight Settings",
        //   key: "flight_settings",
        // },

        {
          to: "/manage-season",
          icon: "plus-square",
          label: " Package Settings",
          key: "package_settings",
          children: [
            {
              to: "/manage-season",
              icon: "circle",
              label: "Manage Seasons",
              key: "package_settings",
            },
            {
              to: "/manage-attraction",
              icon: "circle",
              label: "Manage Attractions",
              key: "package_settings",
            },
            {
              to: "/manage-activity",
              icon: "circle",
              label: "Manage Activities",
              key: "package_settings",
            },
            {
              to: "/manage-essential",
              icon: "circle",
              label: "Essential Info",
              key: "package_settings",
            },
            {
              to: "/manage-inclusions",
              icon: "circle",
              label: "Manage Inclusions",
              key: "package_settings",
            },
            {
              to: "/manage-exclusions",
              icon: "circle",
              label: "Manage Exclusions",
              key: "package_settings",
            },
            {
              to: "/vehicle-info",
              icon: "circle",
              label: "Vehicle Experience",
              key: "package_settings",
            },
            {
              to: "/hotel-experiance",
              icon: "circle",
              label: "Hotel Experience",
              key: "package_settings",
            },
            {
              to: "/questionnaire",
              icon: "circle",
              label: "Questionnaires",
              key: "package_settings",
            },
          ],
        },
        {
          to: "/room",
          icon: "dollar-sign",
          label: "Cost Settings",
          key: "Cost Setting",
          children: [
            {
              to: "/room",
              icon: "circle",
              label: "Room Costing",
              key: "Cost Setting",
            },
            {
              to: "/vehicle",
              icon: "circle",
              label: "Vehicle Costing",
              key: "Cost Setting",
            },
            {
              to: "/cost-child",
              icon: "circle",
              label: "Cost for Child",
              key: "Cost Setting",
            },
            {
              to: "/flight-markup",
              icon: "circle",
              label: "Flight Cost",
              key: "Cost Setting",
            },
            {
              to: "/payment-milestone",
              icon: "circle",
              label: "Payment Milestones",
              key: "Cost Setting",
            },
            {
              to: "/currency-conversion",
              icon: "circle",
              label: "Currency Conversion",
              key: "Cost Setting",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="page-sidebar">
      <ul className="list-unstyled accordion-menu">
        {menuItems?.map((section, sectionIndex) => (
          <React.Fragment key={section?.id}>
            {is_admin ? <li className="sidebar-title">{section.title}</li> : ""}

            {section?.items?.map((item, index) => {
              const currentIndex = `${section?.id}-${index}`;
           
              if ((!is_admin && item?.to !=='/') && !permissions?.includes(item.key)) return null;
              return (
                <li
                  key={currentIndex}
                  className={`${
                    openIndex === currentIndex ? "active-page open" : ""
                  }`}
                >
                  <NavLink
                    to={item?.to}
                    onClick={() => handleAccordionClick(currentIndex)}
                  >
                    <i data-feather={item?.icon}></i>
                    {item?.label}
                    {item.children && (
                      <i
                        className="arrow-right"
                        data-feather="chevron-right"
                      ></i>
                    )}
                  </NavLink>
                  {item.children && (
                    <ul
                      className={`submenu ${
                        openIndex === currentIndex ? "navEffect" : ""
                      }`}
                      style={{
                        display: openIndex === currentIndex ? "block" : "none",
                      }}
                    >
                      {item?.children.map((child, childIndex) => {
                        if (!is_admin && !permissions?.includes(child?.key))
                          return null;
                        return (
                          <li key={`${currentIndex}-${childIndex}`}>
                            <NavLink to={child?.to}>
                              <i data-feather={child?.icon}></i>
                              {child?.label}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
