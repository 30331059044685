import { Accordion } from "react-bootstrap";
import Input from "../../../commonComponents/Input/Input";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import Select from "../../../commonComponents/Select/Select";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import { AiOutlineClose } from "react-icons/ai";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import { useEffect, useState } from "react";
import { scrollToTop } from "../../../utils/helpers";
import * as Tabs from "@radix-ui/react-tabs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DNDIcon from "../../../assets/icons/dragdrop.svg";
import EuroItinerary from "./itinerary/EuroItinerary";
import NonEuroItinerary from "./itinerary/NonEuroItinerary";

const Itinerary = ({
  inputs,
  errors,
  formPlug,
  inputHandler,
  setErrors,
  singleFileUploadHandler,
  removeImage,
  activityData,
  attractionData,
  setInputs,
  inputDataChangeHandler,
  inputDataRemoveChangeHandler,
  mainInputFileUpdateHandler,
  validate,
}) => {
  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
    scrollToTop();
  }, []);

  const [euroActiveKey, setEuroActiveKey] = useState(0);
  const [nonEuroActiveKey, setNonEuroActiveKey] = useState(0);

  const reOrderEuroItinerary = (newList) => {
    setInputs({
      ...inputs,
      itinerary: [...newList],
    });
  };

  const reOrderNonEuroItinerary = (newList) => {
    setInputs({
      ...inputs,
      nonEuropean_itinerary: [...newList],
    });
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 ">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc5  mt-3"
                    label="Main Map (Recommended Size:1640px * 665px)"
                    accept={["jpg", "jpeg", "png"]}
                    icon={"/assets/img/icons/plus_black.svg"}
                    closeIcon={
                      <AiOutlineClose
                        size={30}
                        className="remove-icon"
                        onClick={() => removeImage("main_map")}
                      />
                    }
                    onChange={(e, isInvalid, message, isClosing) => {
                      setErrors((s) => ({
                        ...s,
                        main_map: "",
                      }));
                      singleFileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "main_map",
                        mainInputFileUpdateHandler
                      );
                    }}
                    error={errors.main_map}
                    width={28}
                    field={"main_map"}
                    id="main_map"
                    maxFileSizeMB={50}
                    multiple={false}
                    showPreview
                    files={inputs?.main_map}
                    required={{
                      message: "Please upload main map image",
                    }}
                    inputSocket={formPlug}
                  />
                </div>
                <SupportedFormats
                  formats={["jpg", "jpeg", "png"]}
                  maxSize={50}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <Input
                className="css-inp-esc1 mb-3"
                label="Image Alt Text"
                placeholder="Type here"
                // required={{ message: "Please enter alt text" }}
                field={"main_map_alt"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
              />
            </div>
          </div>
          {inputs?.duration?.length && inputs?.itinerary?.length ? (
            <>
              <Tabs.Root className="TabsRoot" defaultValue="tab1">
                <Tabs.List className="TabsList" aria-label="Manage">
                  <Tabs.Trigger className="TabsTrigger" value="tab1">
                    European Itinerary
                  </Tabs.Trigger>
                  <Tabs.Trigger className="TabsTrigger" value="tab2">
                    Non-European Itinerary
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content className="TabsContent" value="tab1">
                  <div className="tab-content">
                    <div className="tab__sub_header">
                      <h2>Add European Itinerary</h2>
                    </div>
                    <Accordion defaultActiveKey={0} activeKey={euroActiveKey}>
                      <EuroItinerary
                        inputs={inputs}
                        errors={errors}
                        formPlug={formPlug}
                        setErrors={setErrors}
                        singleFileUploadHandler={singleFileUploadHandler}
                        removeImage={removeImage}
                        activityData={activityData}
                        attractionData={attractionData}
                        inputDataChangeHandler={inputDataChangeHandler}
                        inputDataRemoveChangeHandler={
                          inputDataRemoveChangeHandler
                        }
                        reOrderEuroItinerary={reOrderEuroItinerary}
                        euroActiveKey={euroActiveKey}
                        setEuroActiveKey={setEuroActiveKey}
                      />
                    </Accordion>
                  </div>
                </Tabs.Content>
                <Tabs.Content className="TabsContent" value="tab2">
                  <div className="tab-content">
                    <div className="tab__sub_header">
                      <h2>Add Non-European Itinerary</h2>
                    </div>
                    <Accordion
                      defaultActiveKey={0}
                      activeKey={nonEuroActiveKey}
                    >
                      <NonEuroItinerary
                        inputs={inputs}
                        errors={errors}
                        formPlug={formPlug}
                        setErrors={setErrors}
                        singleFileUploadHandler={singleFileUploadHandler}
                        removeImage={removeImage}
                        activityData={activityData}
                        attractionData={attractionData}
                        inputDataChangeHandler={inputDataChangeHandler}
                        inputDataRemoveChangeHandler={
                          inputDataRemoveChangeHandler
                        }
                        reOrderNonEuroItinerary={reOrderNonEuroItinerary}
                        nonEuroActiveKey={nonEuroActiveKey}
                        setNonEuroActiveKey={setNonEuroActiveKey}
                      />
                    </Accordion>
                  </div>
                </Tabs.Content>
              </Tabs.Root>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Itinerary;
