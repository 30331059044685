import React, { useEffect, useState } from "react";
import { Root, List, Trigger, Content } from "@radix-ui/react-tabs";
import { RiDeleteBin6Line } from "react-icons/ri";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import { useDispatch, useSelector } from "react-redux";
import {
  getHotelExpDetail,
  insertHotelExp,
  insertHotelGallery,
  insertHotelPartners,
  updateHotelExp,
  uploadFile,
} from "../../redux/actionCreator";
import {
  detail_hotel_loading,
  hotel_details,
  hotel_gallery_path,
  hotel_partners_path,
  hotel_path,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Seo from "../../components/Seo";
import SortableList from "../../commonComponents/sortFiles/SortableList";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { ProgressBar } from "react-bootstrap";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

const HotelExperience = () => {
  const dispatch = useDispatch();

  const detailLoading = useSelector(detail_hotel_loading);
  const details = useSelector(hotel_details);
  const path = useSelector(hotel_path);
  const partnerPath = useSelector(hotel_partners_path);
  const galleryPath = useSelector(hotel_gallery_path);

  const [data, setData] = useState({
    tagline: "",
    image: "",
    description: [{ desc_points: "" }],
    gallery: [],
    image_alt: "",
    partners: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [delFile, setDelFile] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    dispatch(getHotelExpDetail());
  }, []);

  useEffect(() => {
    if (details) {
      let result = {
        id: details?._id,
        tagline: details?.tagline,
        image_alt: details?.image_alt,
        image: details?.image
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.image}`,
              file: null,
              image: details?.image,
            }
          : "",
      };
      if (details?.desc_points?.length) {
        let arr = details?.desc_points?.map((ele, index) => {
          let obj = {
            desc_points: ele,
          };
          return obj;
        });
        result = {
          ...result,
          description: [...arr],
        };
      }
      if (details?.partners?.length) {
        let arr1 = details?.partners?.map((ele, i) => {
          let obj = {
            type: ele?.image?.split(".").pop(),
            url: `${IMAGE_BASE_URL}${partnerPath}/${ele?.image}`,
            name: `${ele?.image}`,
            response: `${ele?.image}`,
            id: ele?._id,
            alt: ele?.alt,
          };
          return obj;
        });
        result = {
          ...result,
          partners: [...arr1],
        };
      }
      if (details?.gallery?.length) {
        let arr2 = details?.gallery?.map((ele, i) => {
          let obj = {
            type: ele?.type,
            url:
              ele?.type == "video"
                ? ele?.image
                : `${IMAGE_BASE_URL}${galleryPath}/${ele?.image}`,
            name: `${ele?.image}`,
            response: `${ele?.image}`,
            id: ele?._id,
            alt: ele?.alt,
          };
          return obj;
        });
        result = {
          ...result,
          gallery: [...arr2],
        };
      }
      setData({ ...data, ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const handleDescriptionChange = (index, value, field) => {
    let dataList = { ...data };
    dataList.description[index][field] = value;
    setData(dataList);
    setErr({
      ...err,
      [`${field}_${index}`]: "",
    });
  };

  const handleDeleteDescription = (index) => {
    const temp = { ...data };
    temp.description.splice(index, 1);
    console.log(temp);
    setData(temp);
    setErr((prevErr) => ({
      ...prevErr,
      [`desc_points_${index}`]: "",
    }));
  };

  const handleAddDescription = () => {
    const temp = { ...data };
    temp.description.push({
      desc_points: "",
    });
    setData(temp);
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      setData((prevState) => ({
        ...prevState,
        [type]: { file, preview: URL.createObjectURL(file) },
      }));
    }
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const hotelValidate = () => {
    let temp = {};
    if (!data?.tagline?.trim()?.length) {
      temp = {
        ...temp,
        tagline: "Please enter tag line",
      };
    }
    // if (!data?.image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     image_alt: "Please enter alt text",
    //   };
    // }
    if (data?.image == "") {
      temp = {
        ...temp,
        image: "Please upload image",
      };
    }
    data?.description?.forEach((ele, i) => {
      if (!ele.desc_points?.trim()?.length) {
        temp[`desc_points_${i}`] = "Please enter description point";
      }
    });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onHotelSubmitHandler = () => {
    if (hotelValidate()) {
      let formData = new FormData();
      formData.append("tagline", data?.tagline?.trim());
      formData.append("image_alt", data?.image_alt?.trim());
      formData.append("image", data?.image?.file ?? data?.image?.image);
      data?.description?.forEach((item, index) => {
        formData.append(`desc_points[${index}]`, item?.desc_points);
      });
      if (details?._id) {
        console.log(formData);
        dispatch(
          updateHotelExp(formData, (res) => {
            if (res) {
              onHotelCancelHandler();
            }
          })
        );
      } else {
        console.log(formData);
        dispatch(
          insertHotelExp(formData, (res) => {
            if (res) {
              onHotelCancelHandler();
            }
          })
        );
      }
    }
  };

  const onHotelCancelHandler = () => {
    setData({
      tagline: "",
      image: "",
      image_alt: "",
      description: [{ desc_points: "" }],
    });
    dispatch(getHotelExpDetail());
  };

  const handleDelete = (file, field) => {
    console.log(file);
    let temp = [...data?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setData({
        ...data,
        [field]: temp,
      });
      setDelFile([...delFile, file?.response]);
    }
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErr({
        ...err,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(
          `path`,
          field == "partners"
            ? "hotelExp/hotelpartners"
            : "hotelExp/hotelgallery"
        );
        dispatch(
          uploadFile(
            formData,
            (res) => {
              let oldFiles = e?.filter((el) => el?.metaFile == undefined);
              let arr = e
                ?.filter((el) => el?.metaFile != undefined)
                ?.map((ele, i) => {
                  return {
                    // ...ele,
                    url: ele?.url,
                    name: ele?.name,
                    response: res?.data[i],
                    id: res?.data[i] + i + 1,
                    alt: "",
                  };
                });
              setErr({
                ...err,
                [field]: "",
              });
              setData((s) => ({
                ...s,
                [field]: [...oldFiles, ...arr],
              }));
              setImageLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const altChangeHandler = (key, value, index, field, id) => {
    let dataList = { ...data };
    dataList[field][index][key] = value;
    setData(dataList);
    setErr({
      ...err,
      [`${key}_${id}`]: "",
    });
  };

  const partnerValidate = () => {
    let temp = {};
    console.log(data?.partners);
    if (data?.partners == "") {
      temp = {
        ...temp,
        partners: "Please upload logos",
      };
    } 
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onPartnerSubmitHandler = () => {
    console.log(partnerValidate());
    if (partnerValidate()) {
      let raw = {
        partners: data?.partners?.map((item, i) => {
          return {
            [`image`]: item?.response,
            [`alt`]: item?.alt?.trim(),
          };
        }),
        track_id: trackId,
        path: "hotelExp",
      };

      console.log(raw);
      dispatch(
        insertHotelPartners(raw, (res) => {
          if (res) {
            onPartnerCancelHandler();
          }
        })
      );
    }
  };

  const onPartnerCancelHandler = () => {
    setData({
      partners: "",
    });
    dispatch(getHotelExpDetail());
  };

  function getId(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      setVideoUrl("https://www.youtube.com/embed/" + match[2]);
    } else {
      return "error";
    }
  }

  const handleAddVideoUrl = () => {
    if (videoUrl) {
      setErr({
        ...err,
        gallery: "",
      });
      let dataList = { ...data };
      dataList?.gallery?.push({
        // ...ele,
        url: videoUrl,
        response: videoUrl,
        type: "video",
        id: videoUrl + new Date().getTime() + 1,
        alt: "",
      });
      setData(dataList);
      setVideoUrl("");
    }
  };

  const galleryValidate = () => {
    let temp = {};
    if (data?.gallery?.length == 0) {
      temp = {
        ...temp,
        gallery: "Please upload gallery files",
      };
    } 
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onGallerySubmitHandler = () => {
    if (galleryValidate()) {
      let raw = {
        gallery: data?.gallery?.map((item, i) => {
          return {
            [`image`]: item?.response,
            [`alt`]: item?.alt?.trim(),
            [`type`]: item?.type ?? "image",
          };
        }),
        track_id: trackId,
        path: "hotelExp",
      };
      console.log(raw);
      dispatch(
        insertHotelGallery(raw, (res) => {
          if (res) {
            onGalleryCancelHandler();
          }
        })
      );
    }
  };

  const onGalleryCancelHandler = () => {
    setData({
      gallery: [],
    });
    dispatch(getHotelExpDetail());
  };

  return detailLoading ? (
    <LoadingSpinner height={"200px"} />
  ) : (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <Root className="TabsRoot" defaultValue="tab1">
              <List className="TabsList" aria-label="Manage your account">
                <Trigger className="TabsTrigger" value="tab1">
                  Hotel Title And Description
                </Trigger>
                <Trigger className="TabsTrigger" value="tab2">
                  Partners Logo
                </Trigger>
                <Trigger className="TabsTrigger" value="tab3">
                  Gallery
                </Trigger>
              </List>
              <Content className="TabsContent" value="tab1">
                <h5 className=" card-title border-style mb-3">
                  Hotel Experiance Description
                </h5>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">
                        Hotel Tagline <em>*</em>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        onChange={(e) =>
                          onChangeHandler("tagline", e?.target?.value)
                        }
                        value={data?.tagline}
                      />
                      <small id="error" className="error">
                        {err?.tagline}
                      </small>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="mb-3">
                      <label className="form-label">
                        Intro Image (Recommended Size: 480px * 500px) <em>*</em>
                      </label>
                      <MediaUploader
                        media={data.image}
                        onUpload={(e) => handleImageUpload(e, "image")}
                        onRemove={() => removeImage("image")}
                        uploadType={["jpg","jpeg","png","svg"]}
                      />
                      <small id="error" className="error">
                        {err?.image}
                      </small>
                    </div>
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                  </div>
                  <div className="col-7">
                    <div className="mb-3">
                      <label className="form-label">Add Alt Text</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        onChange={(e) =>
                          onChangeHandler("image_alt", e?.target?.value)
                        }
                        value={data?.image_alt}
                      />
                      <small id="error" className="error">
                        {err?.image_alt}
                      </small>
                    </div>
                  </div>
                  <div>
                    <label className="form-label">
                      Description <em>*</em>
                    </label>
                    {data?.description?.map((desc, i) => (
                      <div className="row" key={i}>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              value={desc?.desc_points || ""}
                              placeholder="Type here"
                              onChange={(e) =>
                                handleDescriptionChange(
                                  i,
                                  e.target.value,
                                  "desc_points"
                                )
                              }
                            />
                            <small id="error" className="error">
                              {err[`desc_points_${i}`]}
                            </small>
                          </div>
                        </div>
                        {data?.description?.length > 1 && (
                          <div className="col-12 col-md-1">
                            <div className="mb-3">
                              <RiDeleteBin6Line
                                size={30}
                                onClick={() => handleDeleteDescription(i)}
                                className="delete_icon"
                              />
                            </div>
                          </div>
                        )}
                        {data?.description?.length === i + 1 && (
                          <div className="col-12 col-md-1">
                            <div className="mb-3">
                              <button
                                onClick={handleAddDescription}
                                className="btn btn-primary"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="col-12 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-3"
                      onClick={() => onHotelSubmitHandler()}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => onHotelCancelHandler()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Content>
              <Content className="TabsContent" value="tab2">
                <h5 className="card-title border-style mb-3">Partners Logo</h5>
                <div className="row mb-4">
                  <div className="col-6">
                    <div>
                      <div className="d-flex gap-3">
                        <FileUpload
                          style={{
                            marginRight: 5,
                            marginTop: 15,
                          }}
                          className="css-atc-esc2  mt-3"
                          label="Upload logos"
                          noLabel
                          buttonLabel="Choose File"
                          accept={["jpg", "jpeg", "png", "svg"]}
                          onChange={(e, isInvalid, message, isClosing) => {
                            setErr((s) => ({
                              ...s,
                              partners: "",
                            }));
                            fileSelectedHandler(
                              e,
                              isInvalid,
                              message,
                              isClosing,
                              "partners"
                            );
                          }}
                          field={"partners"}
                          loader={imageLoader}
                          error={err.partners}
                          id="partners"
                          required={{ message: "please upload logo " }}
                          maxFileSizeMB={100}
                          multiple={true}
                          files={data?.partners}
                          inputSocket={() => {}}
                        />
                        <ProgressBar
                          progress={addAttachmentProgress}
                          width={350}
                        />
                      </div>
                      <SupportedFormats
                        formats={["jpg", "jpeg", "png", "svg"]}
                        maxSize={100}
                      />
                    </div>
                  </div>
                  {data?.partners?.length ? (
                    <SortableList
                      setInputs={setData}
                      inputs={data}
                      field="partners"
                      handleDelete={handleDelete}
                      randomTrackID={trackId ?? ""}
                      err={err}
                      altChangeHandler={altChangeHandler}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    onClick={() => onPartnerSubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => onPartnerCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </Content>
              <Content className="TabsContent" value="tab3">
                <h5 className="card-title border-style mb-3">Gallery</h5>
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    <div className="d-flex gap-3">
                      <FileUpload
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                        }}
                        className="css-atc-esc2 mt-0 mb-0"
                        label="Add Images (Recommended Size:525px * 500px)"
                        noLabel
                        buttonLabel="Choose File"
                        accept={["jpg", "jpeg", "png", "svg"]}
                        onChange={(e, isInvalid, message, isClosing) => {
                          setErr((s) => ({
                            ...s,
                            partners: "",
                          }));
                          fileSelectedHandler(
                            e,
                            isInvalid,
                            message,
                            isClosing,
                            "gallery"
                          );
                        }}
                        field={"gallery"}
                        loader={imageLoader}
                        error={err.gallery}
                        id="gallery"
                        maxFileSizeMB={100}
                        required={{ message: " Please upload gallary" }}
                        multiple={true}
                        files={data?.gallery}
                        inputSocket={() => {}}
                      />
                      <ProgressBar
                        progress={addAttachmentProgress}
                        width={350}
                      />
                    </div>
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png", "svg"]}
                      maxSize={100}
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Add Youtube Videos</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Video URL"
                      onChange={(e) => getId(e.target.value)}
                      value={videoUrl ? videoUrl : ""}
                    />
                    <small id="error" className="error">
                      {err?.videoUrl}
                    </small>
                  </div>
                  <div className="col-12 col-md-2 mb-3 margin-container">
                    <button
                      type="submit"
                      className="btn btn-primary m-2"
                      disabled={videoUrl ? false : true}
                      onClick={handleAddVideoUrl}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {data?.gallery?.length ? (
                  <SortableList
                    setInputs={setData}
                    inputs={data}
                    field="gallery"
                    handleDelete={handleDelete}
                    randomTrackID={trackId ?? ""}
                    err={err}
                    altChangeHandler={altChangeHandler}
                  />
                ) : (
                  <></>
                )}
                <div className="col-12 mt-2">
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    onClick={() => onGallerySubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => onGalleryCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </Content>
            </Root>
          </div>
        </div>
        {/* <div className="card">
          <div className="card-body">
            <Seo data = {data} onChangeHandler={onChangeHandler} />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HotelExperience;
