import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import HotelRooms from "../pages/Cost Settings/HotelRooms";
import FlightMarkup from "../pages/Cost Settings/FlightMarkup";
import Activities from "../pages/Activity/Activities";
import ManageEssential from "../pages/Essential Info/ManageEssential";
import AddEssential from "../pages/Essential Info/AddEssential";
import ManageActivity from "../pages/Activity/ManageActivity";
import ManageAttraction from "../pages/Attractions/ManageAttraction";
import ManageInclusions from "../pages/Settings/ManageInclusions";
import ManageExclusions from "../pages/Settings/ManageExclusions";
import Accommodation from "../pages/Accommodation";
import Questionnaire from "../pages/Questionnaire";
import Layout from "../components/Layout/Layout";
import Login from "../pages/Login/Login";
import AddPermission from "../pages/Permission/AddPermission";
import ManagePermissions from "../pages/Permission/ManagePermissions";
import Blog from "../pages/Blogs/Blog";
import ManageBlogs from "../pages/Blogs/ManageBlogs";
import Vehicle from "../pages/Cost Settings/Vehicle";
import Seasons from "../pages/Seasons/Seasons";
import ManageSeasons from "../pages/Seasons/ManageSeasons";
import About from "../pages/About";
import Curated from "../pages/Curated";
import VehicleInfo from "../pages/Vehicle Info/VehicleInfo";

import AddDescription from "../pages/Essential Info/AddDescription";
import ManagePackages from "../pages/Packages/ManagePackages";
import AddPackages from "../pages/Packages/AddPackages";
import Home from "../pages/Home/Home";
import ManageReviews from "../pages/Reviews/ManageReviews";
import ManageFlights from "../pages/Flight Settings/ManageFlights";
import AddVehicle from "../pages/Vehicle Info/AddVehicle";
import AddPartners from "../pages/Partners Info/AddPartners";
import HotelExperiance from "../pages/Hotel Experiance/HotelExperiance";
import CostForChild from "../pages/Cost Settings/CostForChild";
import PaymentMilestones from "../pages/Payment Mile Stone/PaymentMilestones";
import CurrencyConversions from "../pages/Currency Conversion/CurrencyConversions";
import ManagePayment from "../pages/Payment Mile Stone/ManagePayment";
import ManageCurrrencyConversion from "../pages/Currency Conversion/ManageCurrrencyConversion";
import ManageEnquiries from "../pages/Enquiries/ManageEnquiries";

import PrivateRoutes from "../utils/PrivateRoutes";
import Attractions from "../pages/Attractions/Attractions";
import EditEnquiries from "../pages/Enquiries/EditEnquiries";
import AddFlights from "../pages/Flight Settings/AddFlights";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import BookingProcess from "../pages/BookingProcess";
import CancellationPolicy from "../pages/CancellationPolicy";
import usePrevRouteCatcher from "../utils/Hooks/usePrevRouteCatcher";
import ManageBookings from "../pages/Bookings/ManageBookings";
import ViewBooking from "../pages/Bookings/ViewBooking";
import Contact from "../pages/Contact";
import PromoCodes from "../pages/Promo Codes/PromoCodes";

import GeneralPage from "../pages/GeneralPage";
import PackagePage from "../pages/PackagePage";
import AddPromoCode from "../pages/Promo Codes/AddPromoCode";
import ResetPassword from "../pages/ResetPassword";
import PageNotFound from "../pages/PageNotFound";
import AccountInfo from "../pages/AccountInfo";
import AddReview from "../pages/Reviews/AddReview";

function RouteHandler() {
  usePrevRouteCatcher();

  const permissions = JSON.parse(localStorage.getItem("permission") || "[]");
  const is_admin = localStorage.getItem("role") === "super_admin";

  const routes = [
    {
      condition: permissions?.includes("review") || is_admin,
      path: "/manage-reviews",
      element: <ManageReviews />,
    },
    {
      condition: permissions?.includes("review") || is_admin,
      path: "/add-review",
      element: <AddReview />,
    },
    {
      condition: permissions?.includes("review") || is_admin,
      path: "/update-review/:id",
      element: <AddReview />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/add-description",
      element: <AddDescription />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/accommodation",
      element: <Accommodation />,
    },
    {
      condition: permissions?.includes("user_permission") || is_admin,
      path: "/add-permission",
      element: <AddPermission />,
    },
    {
      condition: permissions?.includes("user_permission") || is_admin,
      path: "/update-permission/:id",
      element: <AddPermission />,
    },
    {
      condition: permissions?.includes("user_permission") || is_admin,
      path: "/manage-permission",
      element: <ManagePermissions />,
    },
    {
      condition: permissions?.includes("blog") || is_admin,
      path: "/manage-blogs",
      element: <ManageBlogs />,
    },
    {
      condition: permissions?.includes("blog") || is_admin,
      path: "/add-blog",
      element: <Blog />,
    },
    {
      condition: permissions?.includes("blog") || is_admin,
      path: "/update-blogs/:id",
      element: <Blog />,
    },
    {
      condition: permissions?.includes("enquiry") || is_admin,
      path: "/manage-enquiries",
      element: <ManageEnquiries />,
    },
    {
      condition: permissions?.includes("enquiry") || is_admin,
      path: "/enquiry-details/:id",
      element: <EditEnquiries />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-season",
      element: <Seasons />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-season",
      element: <ManageSeasons />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/update-season/:id",
      element: <Seasons />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-attraction",
      element: <ManageAttraction />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-attraction",
      element: <Attractions />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/update-attraction/:id",
      element: <Attractions />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-activity",
      element: <ManageActivity />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-activity",
      element: <Activities />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/update-activity/:id",
      element: <Activities />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-inclusions",
      element: <ManageInclusions />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-exclusions",
      element: <ManageExclusions />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/manage-essential",
      element: <ManageEssential />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-essential",
      element: <AddEssential />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/update-essential/:id",
      element: <AddEssential />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/questionnaire",
      element: <Questionnaire />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/hotel-experiance",
      element: <HotelExperiance />,
    },

    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/vehicle-info",
      element: <VehicleInfo />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-vehicle",
      element: <AddVehicle />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/update-vehicle/:id",
      element: <AddVehicle />,
    },
    {
      condition: permissions?.includes("package_settings") || is_admin,
      path: "/add-partners",
      element: <AddPartners />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/room",
      element: <HotelRooms />,
    },
    {
      condition: is_admin || permissions?.includes("Cost Setting"),
      path: "/vehicle",
      element: <Vehicle />,
    },

    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/cost-child",
      element: <CostForChild />,
    },

    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/flight-markup",
      element: <FlightMarkup />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/payment-milestone",
      element: <ManagePayment />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/add-milestone",
      element: <PaymentMilestones />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/update-milestone/:id",
      element: <PaymentMilestones />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/currency-conversion",
      element: <ManageCurrrencyConversion />,
    },
    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/add-conversion",
      element: <CurrencyConversions />,
    },

    {
      condition: permissions?.includes("Cost Setting") || is_admin,
      path: "/update-conversion/:id",
      element: <CurrencyConversions />,
    },
    {
      condition: permissions?.includes("flight_settings") || is_admin,
      path: "/manage-flights",
      element: <ManageFlights />,
    },

    {
      condition: permissions?.includes("flight_settings") || is_admin,
      path: "/add-flight",
      element: <AddFlights />,
    },
    {
      condition: permissions?.includes("flight_settings") || is_admin,
      path: "/update-flight/:id",
      element: <AddFlights />,
    },

    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/curated",
      element: <Curated />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/package-page",
      element: <PackagePage />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/general-page",
      element: <GeneralPage />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/contact",
      element: <Contact />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/home",
      element: <Home />,
    },

    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/about",
      element: <About />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/terms-condition",
      element: <TermsAndCondition />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/booking-process",
      element: <BookingProcess />,
    },

    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/cancel-policy",
      element: <CancellationPolicy />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/account-info",
      element: <AccountInfo />,
    },

    {
      condition: permissions?.includes("cms") || is_admin,
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      condition: permissions?.includes("promo_code") || is_admin,
      path: "/manage-promocodes",
      element: <PromoCodes />,
    },
    {
      condition: permissions?.includes("promo_code") || is_admin,
      path: "/add-promocode",
      element: <AddPromoCode />,
    },
    {
      condition: permissions?.includes("promo_code") || is_admin,
      path: "/update-promocode/:id",
      element: <AddPromoCode />,
    },
    {
      condition: permissions?.includes("booking") || is_admin,
      path: "/manage-bookings",
      element: <ManageBookings />,
    },
    {
      condition: permissions?.includes("booking") || is_admin,
      path: "/view-booking/:id",
      element: <ViewBooking />,
    },
    {
      condition: permissions?.includes("packages") || is_admin,
      path: "/manage-packages",
      element: <ManagePackages />,
    },
    {
      condition: permissions?.includes("packages") || is_admin,
      path: "/add-package",
      element: <AddPackages />,
    },
    {
      condition: permissions?.includes("packages") || is_admin,
      path: "/update-package/:id",
      element: <AddPackages />,
    },
  ];

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/create-password/:token" element={<ResetPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword jwt = {true} />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          {routes.map(
            ({ condition, path, element }, index) =>
              condition && <Route key={index} path={path} element={element} />
          )}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default RouteHandler;
