import moment from "moment";

const BookingDetails = ({ details, formatDate }) => {
  return (
    <div className="card">
      <div className="card-body pt-0">
        <div className="tab-content">
          <div className="tab__header">
            <h2>Booking Details</h2>
          </div>
          <div className="grid__items">
            <div className="item grid-2">
              <h4>Package Name</h4>
              <p>{details?.package_name}</p>
            </div>
            <div className="item">
              <h4>Duration</h4>
              <p>{details?.travel_duration} Days</p>
            </div>
            <div className="item">
              <h4>Booking Source</h4>
              <p className="text-capitalize">{details?.source}</p>
            </div>
            <div className="item">
              <h4>Booking Date</h4>
              <p>{formatDate(details?.created_date)}</p>
            </div>
            {details?.source === "manual" ? (
              <></>
            ) : (
              <div className="item">
                <h4>Token Payment </h4>
                <p>${details?.token_payment}</p>
              </div>
            )}
          </div>
          <div className="tab__header">
            <h2>Travel Details</h2>
          </div>
          <div className="grid__items">
            <div className="item">
              <h4>Travel Dates</h4>
              <p>
                {formatDate(details?.start_travel_date)} -{" "}
                {formatDate(details?.end_travel_date)}
              </p>
            </div>
            <div className="item">
              <h4>Total no. of Travellers</h4>
              <p>{details?.no_of_travellers}</p>
            </div>
            <div className="item">
              <h4>Point of origin</h4>
              <p>{details?.point_of_origin?.airport_name}</p>
            </div>
            <div className="item">
              <h4>Traveller Count</h4>
              <p>
                Adult{" "}
                <div
                  className="badge bg-primary mb-2"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  {details?.no_of_adults}
                </div>
              </p>
              <p>
                Child (5-12){" "}
                <div
                  className="badge bg-primary mb-2"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  {details?.no_of_child[0].age_5_12}
                </div>
              </p>
              <p>
                Child (2-5){" "}
                <div
                  className="badge bg-primary mb-2"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  {details?.no_of_child[0].age_2_5}
                </div>
              </p>
              <p>
                Child (0-2){" "}
                <div
                  className="badge bg-primary mb-2"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  {details?.no_of_child[0].age_less_2}
                </div>
              </p>
            </div>
          </div>
          {details?.flight_booking &&
          details?.source !== "manual" &&
          details?.flight_outbound?.aircraft_details?.length ? (
            <>
              <div className="tab__header">
                <h2>Flight Selection</h2>
              </div>

              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  {" "}
                  <div className="card__header">
                    <h5>
                      Start:{" "}
                      {details?.flight_outbound?.origin_start_time?.date
                        ? moment(
                            details?.flight_outbound?.origin_start_time?.date
                          ).format("ddd, D MMM")
                        : moment(details?.start_travel_date).format(
                            "ddd, D MMM"
                          )}
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card__header return">
                    <h5>
                      End :{" "}
                      {details?.flight_return?.reach_time?.date
                        ? moment(
                            details?.flight_return?.reach_time?.date
                          ).format("ddd, D MMM")
                        : moment(details?.end_travel_date).format("ddd, D MMM")}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="trip_details">
                <div className="grid">
                  <div class="flight-info">
                    <div class="departure">
                      <h4 class="time">
                        {details?.flight_outbound?.origin_start_time?.time}
                      </h4>
                      <h5 class="city">
                        {details?.flight_outbound?.departure_airport}
                      </h5>
                    </div>
                    <div class="flight-details">
                      <span class="duration">
                        {details?.flight_outbound?.duration}
                      </span>
                      <span class="plane-icon">
                        <img src="/assets/img/icons/aeroplane.svg" alt="" />
                      </span>
                      <span class="dashed-line"></span>
                      <span class="stops">
                        {details?.flight_outbound?.stop_summary}
                      </span>
                    </div>
                    <div class="arrival">
                      <h4 class="time">
                        {details?.flight_outbound?.reach_time?.time}
                      </h4>
                      <h5 class="city">
                        {details?.flight_outbound?.arrival_airport}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div class="flight-info">
                    <div class="departure">
                      <h4 class="time">
                        {details?.flight_return?.reach_time?.time}
                      </h4>
                      <h5 class="city">
                        {details?.flight_return?.arrival_airport}
                      </h5>
                    </div>
                    <div class="flight-details">
                      <span class="duration">
                        {details?.flight_return?.duration}
                      </span>
                      <span class="plane-icon return">
                        <img src="/assets/img/icons/aeroplane.svg" alt="" />
                      </span>
                      <span class="dashed-line"></span>
                      <span class="stops">
                        {" "}
                        {details?.flight_return?.stop_summary}
                      </span>
                    </div>
                    <div class="arrival">
                      <h4 class="time">
                        {details?.flight_return?.origin_start_time?.time}
                      </h4>
                      <h5 class="city">
                        {details?.flight_return?.departure_airport}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="trip_details">
                <div className="grid">
                  <p>Departure & Return</p>
                  <p>
                    Departure :{" "}
                    <span>
                      {details?.flight_outbound?.aircraft_details
                        ?.map((el) => el?.name)
                        .join(", ")}
                    </span>{" "}
                  </p>
                  <p>
                    Return :{" "}
                    <span>
                      {details?.flight_return?.aircraft_details
                        ?.map((el) => el?.name)
                        .join(", ")}
                    </span>
                  </p>
                </div>

                <div className="grid" style={{ width: "100%" }}>
                  <p>Price per Person</p>
                  <h1>
                    {(
                      Number(details?.flight_total_price) +
                      Number(details?.costs?.flightmarkup_cost)
                    )?.toFixed(2)}{" "}
                    <span>USD</span>
                  </h1>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* COST BREAK DOWN */}
          {details?.source === "manual" ? (
            <></>
          ) : (
            <>
              <div className="tab__header">
                <h2>Cost Breakdown Section</h2>
              </div>
              <div className="invoice">
                <div className="tab__sub_header mt-0">
                  <h2>Cost per Adult:</h2>
                </div>
                <div className="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Items</th>
                        <th>Cost (USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Fixed Package Cost</td>
                        <th>${details?.costs?.fixed_package_cost}</th>
                      </tr>
                      <tr>
                        <td>Vehicle Cost</td>
                        <th>${details?.costs?.vehicle_costing}</th>
                      </tr>
                      <tr>
                        <td>Room Cost</td>
                        <th>${details?.costs?.room_costing}</th>
                      </tr>
                      <tr>
                        <td>Flight Base Fare</td>
                        <th>
                          ${details?.costs?.flight_base_fare}
                          {`${
                            details?.costs?.flightmarkup_cost_base_value
                              ? ` (Included ${details?.costs?.flightmarkup_cost_base_value}% Flight Markup Cost)`
                              : ""
                          }`}
                        </th>
                      </tr>
                      {/* <tr>
                    <td>
                      Flight Markup{" "}
                      {details?.costs?.flightmarkup_cost_base_value
                        ? `(${details?.costs?.flightmarkup_cost_base_value}%)`
                        : ""}
                    </td>
                    <th>${details?.costs?.flightmarkup_cost}</th>
                  </tr> */}
                      <tr>
                        <th>Total Cost per Adult</th>
                        <th>${details?.costs?.totalcost}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}

          {details?.quotation && details?.quotation?.length > 0 && details?.source	!== "manual" && (
            <div className="invoice pt-0">
              <div className="tab__sub_header mt-0">
                <h2>Package Cost</h2>
              </div>
              <div className="col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Cost per Traveller (USD)</th>
                      <th>Number of Travellers</th>
                      <th>Total Cost (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Adults</td>
                      <td>{details?.quotation[0]?.unit_cost}</td>
                      <td>{details?.quotation[0]?.units}</td>
                      <td>{details?.quotation[0]?.total_cost}</td>
                    </tr>
                    <tr>
                      <td>Child (0-2), {details?.age_less_2}%</td>
                      <td>{details?.quotation[1]?.unit_cost}</td>
                      <td>{details?.quotation[1]?.units}</td>
                      <td>{details?.quotation[1]?.total_cost}</td>
                    </tr>
                    <tr>
                      <td>Child (2-5), {details?.age_2_5}%</td>
                      <td>{details?.quotation[2]?.unit_cost}</td>
                      <td>{details?.quotation[2]?.units}</td>
                      <td>{details?.quotation[2]?.total_cost}</td>
                    </tr>
                    <tr>
                      <td>Child (5-12), {details?.age_5_12}%</td>
                      <td>{details?.quotation[3]?.unit_cost}</td>
                      <td>{details?.quotation[3]?.units}</td>
                      <td>{details?.quotation[3]?.total_cost}</td>
                    </tr>
                    <tr>
                      <th className="text-right" colSpan={3}>
                        Final Total :{" "}
                      </th>
                      <th>
                        $
                        {Number(
                          details.quotation?.reduce(
                            (sum, total) => sum + Number(total.total_cost),
                            0
                          )
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-right" color="" colSpan={3}>
                        Additional Cost :{" "}
                      </th>
                      <th>
                        $
                        {(
                          Number(details?.no_of_travellers) *
                          details?.custom_variable_cost
                        )?.toFixed(2) ?? 0}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-right" color="" colSpan={3}>
                        Discount ( applied during booking) :{" "}
                      </th>
                      <th>${details?.total_discount ?? 0}</th>
                    </tr>
                    <tr>
                      <th className="text-right" colSpan={3}>
                        Token Payment :{" "}
                      </th>
                      <th>${details?.token_payment}</th>
                    </tr>
                    <tr>
                      <th className="text-right" colSpan={3}>
                        Remaining Anticipated Payment :{" "}
                      </th>
                      <th>
                        $
                        {(
                          Number(details?.no_of_travellers) *
                            details?.custom_variable_cost +
                          Number(
                            details.quotation?.reduce(
                              (sum, total) => sum + Number(total.total_cost),
                              0
                            )
                          ) -
                          Number(details?.token_payment ?? 0) -
                          Number(details?.total_discount ?? 0)
                        )?.toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
