import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAttractionDetail,
  insertAttraction,
  updateAttraction,
} from "../../redux/actionCreator";
import {
  attraction_details,
  attraction_path,
  detail_attraction_loading,
  update_attraction_loading,
} from "../../redux/commonReducer";
import { useEffect, useState } from "react";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import Input from "../../commonComponents/Input/Input";
import useForm from "../../utils/Hooks/useForm";
import { AiOutlineClose } from "react-icons/ai";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../utils/editorConfig";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

export default function Attractions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm();

  const loading = useSelector(detail_attraction_loading);
  const submitLoading = useSelector(update_attraction_loading);
  const details = useSelector(attraction_details);
  const path = useSelector(attraction_path);

  const [invalidUpload, setInvalidUpload] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getAttractionDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        short_description: details?.short_description,
        long_description: details?.long_description,
        image: details?.image
          ? [
              {
                type: details?.image?.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${details?.image}`,
                name: `${details?.image}`,
                response: `${details?.image}`,
              },
            ]
          : "",
        image_alt: details?.image_alt,
      };
      setInputs({ ...result });
    }
  }, [details]);

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {

        setInputs((s) => ({
          ...s,
          [field]: [
            {
              type: e[0].type,
              url: e[0].url,
              name: e[0]?.name,
              metaFile: e,
            },
          ],
          image_alt:s.title ||""
        }));
      }
    }
  };

  const onSubmitHandler = async () => {
    let isValidated = await validate();
    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title?.trim());
    formData.append("short_description", inputs?.short_description);
    formData.append("long_description", inputs?.long_description);
    formData.append(
      "image",
      inputs?.image?.[0]?.metaFile?.[0]?.metaFile ?? inputs?.image?.[0]?.name
    );
    formData.append("image_alt", inputs?.image_alt?.trim());
    console.log(formData);
    if (id) {
      formData.append("id", id);
      dispatch(
        updateAttraction(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    } else {
      dispatch(
        insertAttraction(formData, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    navigate("/manage-attraction");
  };

  return (
    loading ? (<LoadingSpinner height="200px" /> ) : (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">{id ?"Edit Attraction":"Add Attraction"}</h5>
            <div className="row">
              <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Title"
                  placeholder="Type here"
                  required={{ message: "Please enter title" }}
                  field={"title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="d-flex gap-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5 image2 mt-3"
                      label="Upload Image (Recommended Size:1920px * 630px)"
                      accept={["jpg", "jpeg", "png", "svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "image"
                        );
                      }}
                      error={errors.image}
                      width={28}
                      field={"image"}
                      id="image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                  </div>
                  <SupportedFormats
                  formats= {["jpg", "jpeg", "png","svg"]}
                  />
                </div>
              </div>
              <div className="col-6">
                <Input
                  className="css-inp-esc1 mb-3"
                  label="Add Alt Text"
                  placeholder="Type here"
                  // required={{ message: "Please enter image alt" }}
                  field={"image_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12">
                <TextEditor
                  config={editorConfigEn}
                  label={"Short Description (Maximum 255 characters)"}
                  className={"mb-3"}
                  field={"short_description"}
                  onBlur={(newContent) =>
                    onDetailsEditorChange(newContent, "short_description")
                  }
                  validation = {{maxChar:255}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter short description",
                  }}
                  placeholder="Type Here"
                />
              </div>
              <div className="col-12">
                <TextEditor
                  config={editorConfigEn}
                  label={"Long Description (Maximum 255 characters)"}
                  className={"mb-3"}
                  field={"long_description"}
                  onBlur={(newContent) =>
                    onDetailsEditorChange(newContent, "long_description")
                  }
                  validation = {{maxChar:255}}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{
                    message: "Please enter long description",
                  }}
                  placeholder="Type Here"
                />
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
)
}
