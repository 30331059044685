import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserPermissions, logout } from "../../redux/actionCreator";
import { IMAGE_BASE_URL } from "../../constants/configuration";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [addClass, setAddClass] = useState(false);

  const profile_image = localStorage.getItem("profile_image");

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  const handleCloseSidebar = () => {
    document.body.classList.toggle("sidebar-hidden");
    setAddClass(!addClass);
  };

  const handleShow = () => {
    setShow(true);
    setMenu(!menu);
  };
  const handleHide = () => {
    setShow(false);
  };
  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleLogout = () => {
    dispatch(
      logout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("csrf");
        localStorage.removeItem("role");
        localStorage.removeItem("user");
        localStorage.removeItem("user_id");
        localStorage.removeItem("permission");
        localStorage.removeItem("profile_image");
        navigate("/login");
      })
    );
  };
  return (
    console.log("addClass", addClass),
    (
      <div className="page-header">
        <Modal size="md" show={show} centered onHide={handleHide}>
          <div className="common_modal">
            <div className="close" onClick={handleHide}>
              <img src="/assets/img/icons/icons/close.svg" alt="" />
            </div>

            <Modal.Body>
              <h3>Are you sure you want to logout ?</h3>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="danger" onClick={handleHide}>
                Close
              </Button>
              <Button variant="primary" onClick={handleLogout}>
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="" id="navbarNav">
            <ul className="navbar-nav" id="leftNav">
              <li className="nav-item">
                <a href="/#" className="nav-link">
                  <img src="/assets/img/logo.png" alt="" />
                </a>
              </li>

              <li className="nav-item ps-5">
                {addClass ? (
                  <img
                    src="/assets/img/icons/close.svg"
                    height={25}
                    onClick={handleCloseSidebar}
                  />
                ) : (
                  <img
                    src="/assets/img/icons/menu.svg"
                    height={40}
                    onClick={handleCloseSidebar}
                  />
                )}
              </li>
            </ul>
          </div>

          <div className="" id="headerNav">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className={`nav-link profile-dropdown ${menu ? " show" : ""}`}
                  // href="/#"
                  onClick={handleMenu}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      profile_image
                        ? `${IMAGE_BASE_URL}${"user"}/${profile_image}`
                        : "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt=""
                  />
                </a>
                <div
                  className={`dropdown-menu dropdown-menu-end profile-drop-menu ${
                    menu ? " show" : ""
                  }`}
                  aria-labelledby="profileDropDown"
                >
                  <button className="dropdown-item" onClick={handleShow}>
                    <i className="logout-icon" data-feather="log-out"></i>Logout
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  );
}
