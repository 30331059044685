import React, { useEffect, useState } from "react";
import "./Button.scss";
import { Spinner } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export default function Button({
  content = "",
  icon,
  className = "",
  onClick,
  loader,
  reverse,
  width,
  style = {},
  height,
  fontSize,
  disabled,
  iconWidth,
  condition,
  toolTip,
}) {
  if (!condition && condition !== undefined) return null;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  return (
    // <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: toolTip, })} >
    <button
      id="Button"
      className={className + " " + (reverse ? "reverse" : "")}
      style={{ width: width, height: height, fontSize: fontSize, ...style }}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
      disabled={disabled}
    >
      {typeof content === "string" ? content : content}
      {loader ? (
        <Spinner animation="border" role="status" size="sm"></Spinner>
      ) : (
        icon && <img src={icon} style={{ width: iconWidth }} alt="" />
      )}
    </button>
    // </OverlayTrigger>
  );
}
Button.displayName = "Button";

{
  /* 

<Button
  className=''
  content={'button name'}
  icon={'icon path'}
  reverse
  iconWidth='15px'
  width='100px'
  height='50px'
  disabled={true || false}
  onClick={() => {}}
/> 

*/
}
