import React, { useEffect, useRef, useState } from "react";
import "./DatePick.scss";
import DatePicker from "react-datepicker";
import CalendarIcon from "./DatePick_calendar.svg";
import ClockIcon from "./DatePick_clock.svg";

import "react-datepicker/dist/react-datepicker.css";

let validationObj = {
  minDate: { message: "", condition: "" },
  maxDate: { message: "", condition: "" },
};

export default function DatePick({
  title,
  selected,
  onChange,
  filterTime,
  field,
  dateFormat,
  required,
  inputSocket,
  maxDate,
  minDate,
  className,
  disabled,
  placeholder = "Select",
  error,
  showCloseBtn,
  showMonthYearPicker,
  showMonthDropdown,
  showYearDropdown,
  validation = validationObj,
  timeSelect,
  minTime,
  maxTime,
  icon,
  condition,
  clearOnHide,
  hidestar,
  width,
  style,
  dontTranslate,
  timeSelectOnly,
  timeIntervals
}) {

  const dpRef = useRef();

  const [inError, setInError] = useState("");
  const [inValue, setInValue] = useState("");

  useEffect(() => {
    setInValue(() => {
      let res = "";
      if (
        typeof selected === "object" &&
        !(selected instanceof Date) &&
        selected?.[field] !== undefined
      )
        res = selected?.[field];
      else if (
        typeof selected === "object" &&
        !(selected instanceof Date) &&
        selected?.[field] === undefined
      )
        res = "";
      else if (selected !== undefined) res = selected;
      return res;
    });
  }, [selected]);

  useEffect(() => {
    if (timeSelectOnly) {
      setTimeout(() => {
        dpRef?.current?.querySelector("input")?.setAttribute("readonly", true);
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      if (ifObjectExist(validation.minDate)) obj.minDate = validation.minDate;
      if (ifObjectExist(validation.maxDate)) obj.maxDate = validation.maxDate;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join("").length > 0 || obj === true;
  }

  function changeHandler(e) {
    setInValue(e);
    onChange && onChange(e || "", field);
  }

  if (!condition && condition !== undefined) return null;

  return (
    <span
      id="DatePick"
      className={className}
      style={{ width: width, ...style }}
    >
      {(title || title === "") && (
        <label className="mb-1">
          {dontTranslate ? title : (title)}
          {(required?.condition !== undefined
            ? required?.condition
            : required) &&
            !hidestar ? <em>*</em> : <></>}
        </label>
      )}
      <span ref={dpRef} className={(timeSelect ? "timeselect" : "")}>
        <DatePicker
          dateFormat={dateFormat}
          disabled={disabled}
          dropdownMode="select"
          showMonthYearPicker={showMonthYearPicker}
          maxDate={maxDate}
          maxTime={maxTime}
          minDate={minDate}
          minTime={minTime}
          onChange={changeHandler}
          placeholderText={dontTranslate ? placeholder : (placeholder)}
          selected={inValue}
          showMonthDropdown={showMonthDropdown}
          showTimeSelect={timeSelect || timeSelectOnly}
          showTimeSelectOnly={timeSelectOnly}
          showYearDropdown={showYearDropdown}
          timeIntervals={timeIntervals ?? 15}
        />
        {showCloseBtn && selected ? (
          <span
            className="close_btn"
            onClick={() => {
              onChange && onChange("", field);
            }}
          >
            ✖
          </span>
        ) : (
          <img
            className="calendar"
            src={icon ? icon : timeSelectOnly ? ClockIcon : CalendarIcon}
            alt=""
          />
        )}
      </span>
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className="error">
          {inError ||
            (typeof error === "object" ? (error?.[field] || "") : (error))}
        </small>
      )}
    </span>
  );
}


