import React, { useEffect, useState,useRef } from "react";
import ReactPaginate from "react-paginate";
import EntriesCounter from "../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../commonComponents/Input/Input";
import { Modal } from "react-bootstrap";
import EditIcon from "../assets/icons/EDIT.svg";
import DeleteIcon from "../assets/icons/DELETE.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "../utils/Hooks/useFilter";
import {
  deleteQuestionnaire,
  getQuestionnaire,
  insertQuestionnaire,
  updateQuestionnaire,
} from "../redux/actionCreator";
import {
  questionnaire_data,
  questionnaire_loading,
  questionnaire_page_details,
} from "../redux/commonReducer";
import feather from "feather-icons";
import Table from "../commonComponents/Table/Table";
import Actions from "../commonComponents/Actions/Actions";
import DeleteModal from "../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../commonComponents/LoadingSpinner/LoadingSpinner";

export default function Questionnaire() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const titleInputRef = useRef(null);

  const data = useSelector(questionnaire_data);
  const pageData = useSelector(questionnaire_page_details);
  const loading = useSelector(questionnaire_loading)

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_date",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState({
    title: "",
    id: "",
  });
  const [err, setErr] = useState({});

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  

  useEffect(() => {
    getSearchDataHandler(initialFilter);
  }, []);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(getQuestionnaire(formData));
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteQuestionnaire(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getQuestionnaire(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const onChangeHandler = (key, value) => {
    setQuestionData({
      ...questionData,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!questionData?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter questionnaire",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("title", questionData?.title?.trim());
      if (questionData?.id != "") {
        formData.append("id", questionData?.id);
        dispatch(
          updateQuestionnaire(formData, (res) => {
            if (res) {
              onCancelHandler();
              getSearchDataHandler(filterData);
            }
          })
        );
      } else {
        dispatch(
          insertQuestionnaire(formData, (res) => {
            if (res) {
              onCancelHandler();
              getSearchDataHandler(filterData);
            }
          })
        );
      }
    }
  };

  const editHandler = (ele) => {
    setQuestionData({
      title: ele?.title,
      id: ele?._id,
    });
    setOpen(true);
    setTimeout(() => {
      titleInputRef.current?.focus();
    }, 0);
  }

  const onCancelHandler = () => {
    setQuestionData({
      title: "",
      id: "",
    });
    setOpen(false);
  };
  return (
    <div className="row">
      {open && (
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">
                Engagement Questionnaire{" "}
              </h5>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">Questionnaire <em>*</em></label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      ref={titleInputRef}
                      onChange={(e) =>
                        onChangeHandler("title", e?.target?.value)
                      }
                      value={questionData?.title}
                    />
                    <small id="error" className="error">
                      {err?.title}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary m-2"
                    onClick={() => onSubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style">
              <h5 className="card-title ">Engagement Questionnaire</h5>
              <button
                className="btn btn-primary"
                onClick={() => setOpen(!open)}
              >
                {open ? "Back To Manage" : "Add Questionnaire"}
              </button>
            </div>
            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => {
                    filterHandler("entries", e);
                  }}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  // icon={GenericSearchIcon}
                  placeholder={"Search Here"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => !e && filterHandler("keyword", e)}
                  onSubmit={(e) => {
                    filterHandler("keyword", e);
                  }}
                  onIconClick={(e) => {
                    filterHandler("keyword", e);
                  }}
                />
              </div>
            </div>
            {loading ? (
              <LoadingSpinner height={"200px"} />
            ) : (
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "10px",
                  },
                  {
                    title: "Questionnaire",
                    key: "title",
                    sort: true,
                    width: "400px",
                  },

                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  title: item?.title,
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() => editHandler(item)}
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Questionnaire"}
      />
    </div>
  );
}
