import React from "react";
import "./ModalContent.scss";

export default function ModalContent({
  header = "Header",
  children,
  className = "",
  width,
  height,
}) {
  return (
    <div
      id="ModalContent"
      className={className + " " + header.toLowerCase().split(" ").join("_")}
      style={{ width: width || "600px", height: height }}
    >
      <p className="header">{header}</p>
      <div className="modal_inner_content">
        {children &&
          (!Array.isArray(children) ? [children] : children).filter(
            (obj) => obj?.type?.displayName !== "Button"
          )}
        <div className="modal_buttons">
          {children &&
            (!Array.isArray(children) ? [children] : children).filter(
              (obj) => obj?.type?.displayName === "Button"
            )}
        </div>
      </div>
    </div>
  );
}
