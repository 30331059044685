import { Accordion } from "react-bootstrap";
import Select from "../../../commonComponents/Select/Select";
import DatePick from "../../../commonComponents/DatePicker/DatePick";
import Input from "../../../commonComponents/Input/Input";
import TextEditor from "../../../commonComponents/TextEditor/TextEditor";
import { editorConfigEn } from "../../../utils/editorConfig";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import SupportedFormats from "../../../commonComponents/SupportedFormats/SupportedFormats";
import Table from "../../../commonComponents/Table/Table";
import Actions from "../../../commonComponents/Actions/Actions";
import EditIcon from "../../../assets/icons/EDIT.svg";
import DeleteIcon from "../../../assets/icons/DELETE.svg";
import { useState } from "react";
import ItenararyDragAndDrop from "../ItenararyDragAndDrop";

const TravelItinerary = ({
  inputs,
  formPlug,
  errors,
  setErrors,
  inputHandler,
  activityData,
  attractionData,
  inputDataRemoveChangeHandler,
  removeImage,
  fileUploadHandler,
  documentList,
  onAddAdditionalItineraryData,
  itinerary,
  itineraryDataChangeHandler,
  onClearAdditionalItineraryData,
  onEditAttachment,
  onDeleteAttachment,
  reOrderItenarary,
  details
}) => {

  const [activeKey, setActiveKey] = useState(0);

  const hasError = (i) => {
    let flag = false;
    for (let key in errors) {
      if (
        key?.includes("itinerary") &&
        // key?.includes(String(i)) &&
        // key?.includes(`_${i}`) &&
        key?.endsWith(`_${i}`) &&
        errors[key]
      ) {
        flag = true;
      }
    }
    return flag;
  };

  console.log({itinerary})
  return (
    // console.log(errors,"errors"),
    <div className="card">
      <div className="card-body pt-0">
        <div className="tab-content">
          <div className="tab__header">
            <h2>Trip Confirm Dates</h2>
          </div>
          <div className="row mb-2">
            <div className="col-4">
              <div className="mb-3">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"Start Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.start_travel_date}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  error={errors?.start_travel_date}
                  inputSocket={formPlug}
                  required={{ message: "Please select start date" }}
                  field={"start_travel_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"End Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.end_travel_date}
                  onChange={(e, key) => {
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={inputs?.start_travel_date}
                  error={errors?.end_travel_date}
                  inputSocket={formPlug}
                  required={{ message: "Please select end date" }}
                  field={"end_travel_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <div className="tab__header">
            <h2>Itinerary</h2>
          </div>

          <Accordion defaultActiveKey={0} activeKey={activeKey}>
              <ItenararyDragAndDrop
                list={itinerary?.map((ele, i)=> ({...ele, id: `item-${i}`}))}
                hasError={hasError}
                setActiveKey={setActiveKey}
                activeKey={activeKey}
                formPlug={formPlug}
                itineraryDataChangeHandler={itineraryDataChangeHandler}
                errors={errors}
                activityData={activityData}
                inputDataRemoveChangeHandler={inputDataRemoveChangeHandler}
                attractionData={attractionData}
                removeImage={removeImage}
                setErrors={setErrors}
                fileUploadHandler={fileUploadHandler}
                SupportedFormats={SupportedFormats}
                documentList={documentList}
                onAddAdditionalItineraryData={onAddAdditionalItineraryData}
                onClearAdditionalItineraryData={onClearAdditionalItineraryData}
                onEditAttachment={onEditAttachment}
                onDeleteAttachment={onDeleteAttachment}
                reOrderItenarary={reOrderItenarary}
                details={details}
              />
          </Accordion>
        </div>
      </div>
    </div>
  );
};
export default TravelItinerary;
