import React, {  useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { deleteBlog, getBlog } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  blog_data,
  blog_loading,
  blog_page_details,
  blog_path,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import useFilter from "../../utils/Hooks/useFilter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import { DateConverter } from "../../utils/helpers";

export default function ManageBlogs() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(blog_data);
  const pageData = useSelector(blog_page_details);
  const path = useSelector(blog_path);
  const loading = useSelector(blog_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "createdAt",
    sortValue: -1,
    keyword: "",
  };

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

 
  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteBlog(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });

          let blodData = {
            page: filterData?.page - 1,
            sort_order: filterData.sortValue,
            perPage: filterData.perPage,
            sort_key: filterData.sortColumn,
            keyword: filterData?.keyword?.trim() ?? "",
            is_pagination: "1",
          };

          dispatch(getBlog(blodData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const getSearchDataHandler = (filter) => {
    const blodData = {
      page: filter.page,
      sort_order: filter.sortValue,
      perPage: filter.perPage,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
      is_pagination: "1",
    };

    dispatch(getBlog(blodData));
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="table__add border-style">
                <h5 className="card-title">Manage Blogs</h5>
                <Link to="/add-blog">
                  <button className="btn btn-primary">Add Blog</button>
                </Link>
              </div>
              <div className="search__head">
                <div className="count">
                  <EntriesCounter
                    value={filterData.perPage}
                    onChange={(e) => {
                      filterHandler("entries", e);
                    }}
                  />
                </div>
                <div className="search">
                  <Input
                    value={filterData.keyword}
                    type="text"
                    className="css-inp-esc2 search"
                    // icon={GenericSearchIcon}
                    placeholder={"Search Here"}
                    reverse
                    width="300px"
                    field="keyword"
                    showCloseBtn
                    onChange={(e) => !e && filterHandler("keyword", e)}
                    onSubmit={(e) => {
                      filterHandler("keyword", e);
                    }}
                    onIconClick={(e) => {
                      filterHandler("keyword", e);
                    }}
                  />
                </div>
              </div>
              {loading ? (
                <LoadingSpinner height={"200px"} />
              ) : (
                <Table
                  titles={[
                    {
                      title: "Sl.No",
                      key: "sl_no",
                      width: "10px",
                    },
                    {
                      title: "Thumbnail",
                      key: "image",
                      // sort: true,
                      width: "150px",
                    },
                    {
                      title: "Title",
                      key: "title",
                      sort: true,
                      width: "300px",
                    },
                    {
                      title: "Publish Date",
                      key: "date",
                      sort: true,
                      width: "100px",
                    },
                    {
                      title: "Status",
                      key:"status",
                      width: "100px",
                    },

                    { title: "Action", key: "action", width: "100px" },
                  ]}
                  content={data?.map((item, i) => ({
                    sl_no: filterData.page * filterData.perPage + (i + 1),
                    image: (
                      <img
                        src={`${IMAGE_BASE_URL}${path}/${item?.thumbnail}`}
                        alt={item?.thumbnail_alt}
                      />
                    ),
                    title: item?.title,
                    date:  DateConverter(item?.publish_date),
                    status:  (
                      <button
                        style={{
                          backgroundColor: item?.is_active ? "#28a745" : "#FF4545",
                          color: "#fff",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                        disabled
                      >
                        {item?.is_active ? "Active" : "Inactive"}
                      </button>
                    ),


                    action: (
                      <Actions
                        buttons={{
                          edit: EditIcon,
                          delete: DeleteIcon,
                        }}
                        onEdit={() => navigate(`/update-blogs/${item?._id}`)}
                        onDelete={() => showDeletePopup(item?._id)}
                      />
                    ),
                  }))}
                  onSort={(e) => filterHandler("sort", e)}
                  page={filterData.page}
                  pagesCount={pageData?.pages}
                  pageStatus={pageData?.status}
                  onPageChange={(e) => {
                    filterHandler("page", e.selected);
                  }}
                  sortValue={{
                    key: filterData.sortColumn,
                    value: filterData.sortValue,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Blog"}
      />
    </>
  );
}

