import React from "react";
import icon_info from "../assets/icons/icon.svg";

const Seo = ({ data, onChangeHandler }) => {
  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 80) {
      onChangeHandler("meta_title", value);
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 180) {
      onChangeHandler("meta_description", value);
    }
  };

  const handleKeywordsChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9,]*$/
    if (regex.test(value)) {
      onChangeHandler("meta_keyword", value);
    }
  };

  return (
    <>
      <h5 className="card-title border-style mb-3">SEO</h5>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label className="form-label">Meta Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              onChange={handleTitleChange}
              value={data?.meta_title}
            />
            <small>{data?.meta_title?.length || 0}/80</small>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label className="form-label">Meta Keywords</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              onChange={handleKeywordsChange}
              value={data?.meta_keyword}
            />
            
          </div>
          <p id="SupportedFormats"><img src={icon_info}  alt="good"/> No special characters allowed </p>
        </div>

        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">Meta Description</label>
            <textarea
              placeholder="Type here"
              className="form-control"
              value={data?.meta_description || ""}
              onChange={handleDescriptionChange}
              rows={5}
            />
            <small>{data?.meta_description?.length || 0}/180</small>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seo;
