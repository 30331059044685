import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import IconUploader from "../../components/media-uploader/IconUploader";
import CommonTitleCard from "../../components/CommonTitleCard";
import Seo from "../../components/Seo";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttractionsWithoutPagination,
  getHomeDetail,
  getReviewsWithoutPagination,
  insertHome,
  updateHome,
  uploadFile,
} from "../../redux/actionCreator";
import { Modal } from "react-bootstrap";
import {
  attraction_path,
  detail_home_loading,
  full_attraction_list,
  full_review_list,
  home_details,
  home_path,
  review_path,
  update_home_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import useForm from "../../utils/Hooks/useForm";
import Input from "../../commonComponents/Input/Input";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";
import AttractionsDragAndDrop from "./AttractionsDragAndDrop";

const Home = () => {
  const [invalidUpload, setInvalidUpload] = useState(false);
  const dispatch = useDispatch();
  const allAttractionList = useSelector(full_attraction_list);
  const allReviewList = useSelector(full_review_list);
  const reviewPath = useSelector(review_path);
  const attractionPath = useSelector(attraction_path);
  const submitLoading = useSelector(update_home_loading);
  const loading = useSelector(detail_home_loading);
  const details = useSelector(home_details);
  const path = useSelector(home_path);

  const [data, setData] = useState({
    banner_text: "",
    banner_video: "",
    banner_video_alt: "",
    about_title: "",
    about_tagline: "",
    about_image_alt: "",
    tagline_title: "",
    tagline_description: "",
    self_drive_title: "",
    self_drive_description: "",

    self_drive_image_alt: "",
    package_title: "",
    package_tagline: "",
    attraction_title: "",
    attraction_tagline: "",
    why_choose_title: "",
    why_choose_tagline: "",
    review_title: "",
    review_tagline: "",
    curated_title: "",
    curated_description: "",

    curated_image_alt: "",
    blog_title: "",
    blog_tagline: "",
    features: [
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
      { name: "", icon: "", icon_alt: "" },
    ],
    attractions: [{}, {}, {}, {}, {}, {}, {}, {}],
    list: [],
    reviews: [],
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
    list_index: "",
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [openListModal, setOpenListModal] = useState(false);
  const [openAttractionModal, setOpenAttractionModal] = useState(false);
  const [attractionIndex, setAttractionIndex] = useState("");
  const [selectedAttractionOption, setSelectedAttractionOption] = useState("");
  const [selectedReviewOption, setSelectedReviewOption] = useState("");
  const [openReviewModal, setOpenReviewModal] = useState(false);

  useEffect(() => {
    dispatch(getAttractionsWithoutPagination());
    dispatch(getReviewsWithoutPagination());
    dispatch(getHomeDetail());
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        banner_text: details?.banner_text,
        banner_video: details?.banner_video
          ? {
            preview: `${IMAGE_BASE_URL}${path}/${details?.banner_video}`,
            file: null,
            image: details?.banner_video,
            type: "video",
          }
          : "",
        banner_video_alt: details?.banner_video_alt,
        about_title: details?.about_title,
        about_tagline: details?.about_tagline,
        about_image_alt: details?.about_image_alt,
        tagline_title: details?.tagline_title,
        tagline_description: details?.tagline_description,
        self_drive_title: details?.self_drive_title,
        self_drive_description: details?.self_drive_description,
        self_drive_image_alt: details?.self_drive_image_alt,
        package_title: details?.package_title,
        package_tagline: details?.package_tagline,
        attraction_title: details?.attraction_title,
        attraction_tagline: details?.attraction_tagline,
        why_choose_title: details?.why_choose_title,
        why_choose_tagline: details?.why_choose_tagline,
        review_title: details?.review_title,
        review_tagline: details?.review_tagline,
        curated_title: details?.curated_title,
        curated_description: details?.curated_description,
        curated_image_alt: details?.curated_image_alt,
        blog_title: details?.blog_title,
        blog_tagline: details?.blog_tagline,
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
      };
      // if (details?.features?.length) {
      //   let arr = details?.features?.map((ele, i) => {
      //     let obj = {
      //       icon: ele?.icon
      //         ? {
      //           preview: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
      //           file: null,
      //           image: ele?.icon,
      //         }
      //         : "",
      //       name: `${ele?.name}`,
      //       icon_alt: `${ele?.icon_alt}`,
      //     };
      //     return obj;
      //   });
      //   result = {
      //     ...result,
      //     features: [...arr],
      //   };
      // }
      if (details?.list?.length) {
        let arr1 = details?.list?.map((ele, i) => {
          let obj = {
            // icon: ele?.icon
            //   ? {
            //       preview: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
            //       file: null,
            //       image: ele?.icon,
            //     }
            //   : "",
            icon: ele?.icon
              ? [
                {
                  type: ele?.icon?.split(".").pop(),
                  url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                  name: `${ele?.icon}`,
                  response: `${ele?.icon}`,
                },
              ]
              : "",
            name: `${ele?.name}`,
            icon_alt: `${ele?.icon_alt}`,
            description: `${ele?.description}`,
          };
          return obj;
        });
        result = {
          ...result,
          list: [...arr1],
        };
      }
      if (details?.attractions?.length && allAttractionList?.length) {
        let arr2 = allAttractionList?.filter((obj) =>
          details?.attractions?.includes(obj._id)
        );
        arr2 = arr2.sort((a, b) => {
          return (
            details?.attractions.indexOf(a?._id) -
            details?.attractions.indexOf(b?._id)
          );
        });
        arr2 = arr2.concat(Array(8 - arr2.length).fill({})).slice(0, 8);
        result = {
          ...result,
          attractions: [...arr2],
        };
      }
      if (details?.reviews?.length && allReviewList?.length) {
        let arr3 = allReviewList?.filter((obj) =>
          details?.reviews?.includes(obj._id)
        );
        result = {
          ...result,
          reviews: [...arr3],
        };
      }
      setData({ ...result });
      setInputs({
        ...inputs,
        about_image: details?.about_image
          ? [
            {
              type: details?.about_image?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.about_image}`,
              name: `${details?.about_image}`,
              response: `${details?.about_image}`,
            },
          ]
          : "",
        self_drive_image: details?.self_drive_image
          ? [
            {
              type: details?.self_drive_image?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.self_drive_image}`,
              name: `${details?.self_drive_image}`,
              response: `${details?.self_drive_image}`,
            },
          ]
          : "",
        curated_image: details?.curated_image
          ? [
            {
              type: details?.curated_image?.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.curated_image}`,
              name: `${details?.curated_image}`,
              response: `${details?.curated_image}`,
            },
          ]
          : "",
        features: details.features.map((ele) => ({
          icon: ele?.icon
            ? [
              {
                type: ele?.icon.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                name: ele?.icon,
                response: ele?.icon,
              },
            ]
            : "",
          icon_alt: ele?.icon_alt || "",
          name: ele?.name || "",
        })),
      });
    }
  }, [details, allAttractionList, allReviewList]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const removeImage = (type, field) => {
    if (type === "icon" || type === "banner_video") {
      setData((prevState) => ({
        ...prevState,
        [type]: "",
      }));
    }
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileSelectedHandler = (
    event,
    field,
    setFunction,
    type,
    index
  ) => {
    const file = event.target.files?.[0];
    setErr({
      ...err,
      [field]: "",
    });
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append(`file`, file);
    formData.append(`path`, "home");
    dispatch(
      uploadFile(formData, (res) => {
        setErr({
          ...err,
          [field]: "",
        });
        setFunction(field, file, res, type, index);
      })
    );
  };

  const fileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        [`${field}`]: message,
      });
    } else {
      setErrors({
        ...errors,
        [`${field}`]: message,
      });
      setInvalidUpload(false);
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "home");
        dispatch(
          uploadFile(formData, (res) => {
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  ...ele,
                  type: ele?.type,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            let tempValue = [...arr];
            setData((s) => ({
              ...s,
              [field]: tempValue,
            }));
          })
        );
      }
    }
  };

  const setSingleImageState = (field, file, res, type) => {
    setData((s) => ({
      ...s,
      [field]: {
        file: file,
        image: res?.data[0],
        preview: URL.createObjectURL(file),
        type: type,
      },
    }));
  };

  const setFeatureImageState = (field, file, res, type, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = {
      file: file,
      image: res?.data[0],
      preview: URL.createObjectURL(file),
      type: type,
    };
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const removeFeatureImage = (field, index) => {
    let dataList = { ...data };
    dataList.features[index][field] = "";
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const handleFeaturesChange = (index, value, field) => {
    let dataList = { ...data };
    dataList.features[index][field] = value;
    setData(dataList);
    setErr({
      ...err,
      [`features_${field}_${index}`]: "",
    });
  };

  const handleCloseListModal = (dataList) => {
    setOpenListModal(false);
    setData({
      ...dataList,
      name: "",
      description: "",
      icon: "",
      icon_alt: "",
      list_edit: false,
      list_index: "",
    });
  };

  const listValidate = () => {
    let temp = {};
    if (!data?.name?.trim()?.length) {
      temp = {
        ...temp,
        name: "Please enter name",
      };
    }
    if (!data?.description?.trim()?.length) {
      temp = {
        ...temp,
        description: "Please enter description",
      };
    }
    if (data?.icon == "") {
      temp = {
        ...temp,
        icon: "Please upload icon",
      };
    }
    // if (!data?.icon_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     icon_alt: "Please enter icon alt text",
    //   };
    // }

    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const handleDataList = () => {
    if (listValidate()) {
      let dataList = { ...data };
      let addObj = {
        name: data?.name,
        description: data?.description,
        icon: data?.icon,
        icon_alt: data?.icon_alt,
      };
      let addArr = [];
      if (data?.list_edit) {
        let list = [...dataList?.list];
        list[data.list_index] = addObj;
        addArr = [...list];
      } else {
        addArr = [...dataList?.list, addObj];
      }
      dataList = {
        ...dataList,
        list: addArr,
        list_edit: false,
        list_index: "",
      };
      setData(dataList);
      handleCloseListModal(dataList);
    }
  };

  const handleListEdit = (ele, index) => {
    setData({
      ...data,
      name: ele?.name,
      description: ele?.description,
      icon: ele?.icon,
      icon_alt: ele?.icon_alt,
      list_edit: true,
      list_index: index,
    });
    setOpenListModal(true);
  };

  const removeListData = (field, index) => {
    let temp = [...data?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele, i) => i !== index);
      setData({
        ...data,
        [field]: temp,
      });
    }
  };

  const handleCloseAttraction = () => {
    setAttractionIndex("");
    setSelectedAttractionOption("");
    setOpenAttractionModal(false);
  };

  const handleOpenAttraction = (index) => {
    setAttractionIndex(index);
    setOpenAttractionModal(true);
  };

  const handleDeleteAttraction = (index) => {
    const updatedAttractions = [...data.attractions];
    updatedAttractions.splice(index, 1);
    setData({ ...data, attractions: updatedAttractions });
  };

  const handleReorderAttraction = (newList) => {
    setData({ ...data, attractions: [...newList] });
  };

  const handleAddAttraction = () => {
    if (selectedAttractionOption == "") {
      setErr({
        ...err,
        selectedAttractionOption: "Please Select attraction",
      });
    } else {
      if (
        data?.attractions?.some(
          (ele) => ele._id == selectedAttractionOption?._id
        )
      ) {
        setErr({
          ...err,
          selectedAttractionOption: "This attraction is already selected",
        });
      } else {
        let dataList = { ...data };
        dataList.attractions[attractionIndex] = selectedAttractionOption;
        setData(dataList);
        setErr({
          ...err,
          [`attractions`]: "",
        });
        handleCloseAttraction();
      }
    }
  };

  const handleOpenReviews = () => setOpenReviewModal(true);

  const handleCloseReview = () => {
    setOpenReviewModal(false);
    setSelectedReviewOption("");
  };

  const handleAddReview = () => {
    if (selectedReviewOption == "") {
      setErr({
        ...err,
        selectedReviewOption: "Please Select review",
      });
    } else {
      if (data?.reviews?.some((ele) => ele._id == selectedReviewOption?._id)) {
        setErr({
          ...err,
          selectedReviewOption: "This review is already selected",
        });
      } else {
        let dataList = { ...data };
        dataList?.reviews?.push(selectedReviewOption);
        setData(dataList);
        setErr({
          ...err,
          [`selectedReviewOption`]: "",
        });
        handleCloseReview();
      }
    }
  };

  const validater = () => {
    let temp = {};
    if (!data?.banner_text?.trim()?.length) {
      temp = {
        ...temp,
        banner_text: "Please enter banner text",
      };
    }
    if (data?.banner_video === "") {
      temp = {
        ...temp,
        banner_video: "Please upload banner video",
      };
    }
    // if (!data?.banner_video_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     banner_video_alt: "Please enter banner alt",
    //   };
    // }
    if (!data?.about_title?.trim()?.length) {
      temp = {
        ...temp,
        about_title: "Please enter about title",
      };
    }
    if (!data?.about_tagline?.trim()?.length) {
      temp = {
        ...temp,
        about_tagline: "Please enter about description",
      };
    }
    // if (!data?.about_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     about_image_alt: "Please enter about image alt",
    //   };
    // }
    // if (data?.about_image == "") {
    //   temp = {
    //     ...temp,
    //     about_image: "Please upload about image",
    //   };
    // }
    if (!data?.tagline_title?.trim()?.length) {
      temp = {
        ...temp,
        tagline_title: "Please enter tagline title",
      };
    }
    if (!data?.tagline_description?.trim()?.length) {
      temp = {
        ...temp,
        tagline_description: "Please enter tagline description",
      };
    }
    if (!data?.self_drive_title?.trim()?.length) {
      temp = {
        ...temp,
        self_drive_title: "Please enter self drive title",
      };
    }
    if (!data?.self_drive_description?.trim()?.length) {
      temp = {
        ...temp,
        self_drive_description: "Please enter self drive description",
      };
    }
    // if (data?.self_drive_image == "") {
    //   temp = {
    //     ...temp,
    //     self_drive_image: "Please upload self drive image",
    //   };
    // }
    // if (!data?.self_drive_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     self_drive_image_alt: "Please enter self drive alt text",
    //   };
    // }
    if (!data?.package_title?.trim()?.length) {
      temp = {
        ...temp,
        package_title: "Please enter package title",
      };
    }
    if (!data?.package_tagline?.trim()?.length) {
      temp = {
        ...temp,
        package_tagline: "Please enter package tagline",
      };
    }
    if (!data?.attraction_title?.trim()?.length) {
      temp = {
        ...temp,
        attraction_title: "Please enter attraction title",
      };
    }
    if (!data?.attraction_tagline?.trim()?.length) {
      temp = {
        ...temp,
        attraction_tagline: "Please enter attraction tagline",
      };
    }
    if (!data?.why_choose_title?.trim()?.length) {
      temp = {
        ...temp,
        why_choose_title: "Please enter why choose title",
      };
    }
    if (!data?.why_choose_tagline?.trim()?.length) {
      temp = {
        ...temp,
        why_choose_tagline: "Please enter why choose tagline",
      };
    }
    if (!data?.review_title?.trim()?.length) {
      temp = {
        ...temp,
        review_title: "Please enter review title",
      };
    }
    if (!data?.review_tagline?.trim()?.length) {
      temp = {
        ...temp,
        review_tagline: "Please enter review tagline",
      };
    }
    if (!data?.curated_title?.trim()?.length) {
      temp = {
        ...temp,
        curated_title: "Please enter curated title",
      };
    }
    if (!data?.curated_description?.trim()?.length) {
      temp = {
        ...temp,
        curated_description: "Please enter curated description",
      };
    }
    // if (data?.curated_image == "") {
    //   temp = {
    //     ...temp,
    //     curated_image: "Please upload curated image",
    //   };
    // }
    // if (!data?.curated_image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     curated_image_alt: "Please enter curated alt text",
    //   };
    // }
    if (!data?.blog_title?.trim()?.length) {
      temp = {
        ...temp,
        blog_title: "Please enter blog title",
      };
    }
    if (!data?.blog_tagline?.trim()?.length) {
      temp = {
        ...temp,
        blog_tagline: "Please enter blog taglin",
      };
    }
    // data?.features?.forEach((ele, i) => {
    //   if (!ele.name?.trim()?.length) {
    //     temp[`features_name_${i}`] = "Please enter name";
    //   }
    //   // if (!ele.icon_alt?.trim()?.length) {
    //   //   temp[`features_icon_alt_${i}`] = "Please enter alt text";
    //   // }
    //   if (ele.icon == "") {
    //     temp[`features_icon_${i}`] = "Please upload icon";
    //   }
    // });
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const inputDataChangeHandler = (key, value, index, field) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
  };

  const removeIcon = (index, type) => {
    setInputs((prev) => {
      const updatedMedia = prev[type].map((item, idx) =>
        idx === index ? { ...item, icon: "" } : item
      );
      return {
        ...prev,
        [type]: updatedMedia,
      };
    });
  };
  const featureValidator = (error) => {
    let temp = {};
    if (inputs?.features?.length) {
      inputs?.features?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"features"}_${"icon"}_${i}`] = "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"features"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.name?.trim()?.length) {
          temp[`${"features"}_${"name"}_${i}`] = "Please enter name";
        }
      });
      if (Object.values(temp).length !== 0) {
        temp = { ...temp, ...error };
        return temp;
      } else {
        setErrors({ ...error });
        return error;
      }
    }
  };
  const singleFileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    mainField
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "home");
        dispatch(
          uploadFile(formData, (res) => {
            let tempValue = [
              {
                type: e[0].type,
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ];
            if (field === "icon") {
              setFunction(field, tempValue, index, mainField);
            } else {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: tempValue,
              }));
            }
          })
        );
      }
    }
  };
  const onSubmitHandler = async () => {
    let isValidate1 = false;
    let isValidated = await validate({
      returnErrors: true,
    });
    let temp = {
      ...isValidated.err,
      ...featureValidator(),
    };
    console.log(temp);

    if (Object.values(temp).every((value) => value === "")) {
      isValidate1 = true;
    } else {
      setErrors({ ...temp });
    }

    if (isValidated?.valid && !invalidUpload && isValidate1) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    if (validater()) {
      let raw = {
        banner_text: data?.banner_text?.trim(),
        banner_video: data?.banner_video?.image,
        banner_video_alt: data?.banner_video_alt?.trim(),
        about_title: data?.about_title?.trim(),
        about_tagline: data?.about_tagline,
        about_image: inputs?.about_image?.[0]?.response ?? "",
        about_image_alt: data?.about_image_alt?.trim(),
        tagline_title: data?.tagline_title?.trim(),
        tagline_description: data?.tagline_description?.trim(),
        self_drive_title: data?.self_drive_title?.trim(),
        self_drive_description: data?.self_drive_description?.trim(),
        self_drive_image: inputs?.self_drive_image?.[0]?.response ?? "",
        self_drive_image_alt: data?.self_drive_image_alt?.trim(),
        package_title: data?.package_title?.trim(),
        package_tagline: data?.package_tagline?.trim(),
        attraction_title: data?.attraction_title?.trim(),
        attraction_tagline: data?.attraction_tagline?.trim(),
        why_choose_title: data?.why_choose_title?.trim(),
        why_choose_tagline: data?.why_choose_tagline?.trim(),
        review_title: data?.review_title?.trim(),
        review_tagline: data?.review_tagline?.trim(),
        curated_title: data?.curated_title?.trim(),
        curated_description: data?.curated_description?.trim(),
        curated_image: inputs?.curated_image?.[0]?.response ?? "",
        curated_image_alt: data?.curated_image_alt?.trim(),
        blog_title: data?.blog_title?.trim(),
        blog_tagline: data?.blog_tagline?.trim(),
        list: data?.list?.map((item, i) => {
          return {
            [`icon`]: item.icon?.[0]?.response ?? "",
            [`name`]: item?.name?.trim(),
            [`description`]: item?.description?.trim(),
            [`icon_alt`]: item?.icon_alt?.trim(),
          };
        }),
        features: inputs.features.map((item) => ({
          icon: item.icon?.[0]?.response ?? "",
          icon_alt: item?.icon_alt,
          name: item?.name,
        })),
        reviews: data?.reviews?.map((item, i) => item?._id),
        attractions: data?.attractions?.map((item, i) => item?._id),
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
        path: "home",
      };
      console.log(raw);
      if (details?._id) {
        raw = {
          ...raw,
          id: details?._id,
        };
        dispatch(
          updateHome(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertHome(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      banner_text: "",
      banner_video: "",
      banner_video_alt: "",
      about_title: "",
      about_tagline: "",
      about_image: "",
      about_image_alt: "",
      tagline_title: "",
      tagline_description: "",
      self_drive_title: "",
      self_drive_description: "",
      self_drive_image: "",
      self_drive_image_alt: "",
      package_title: "",
      package_tagline: "",
      attraction_title: "",
      attraction_tagline: "",
      why_choose_title: "",
      why_choose_tagline: "",
      review_title: "",
      review_tagline: "",
      curated_title: "",
      curated_description: "",
      curated_image: "",
      curated_image_alt: "",
      blog_title: "",
      blog_tagline: "",
      // features: [
      //   { name: "", icon: "", icon_alt: "" },
      //   { name: "", icon: "", icon_alt: "" },
      //   { name: "", icon: "", icon_alt: "" },
      //   { name: "", icon: "", icon_alt: "" },
      //   { name: "", icon: "", icon_alt: "" },
      //   { name: "", icon: "", icon_alt: "" },
      // ],
      attractions: [{}, {}, {}, {}, {}, {}, {}, {}],
      list: [],
      reviews: [],
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    setInputs({});
    setErrors({});
    dispatch(getHomeDetail());
  };

  console.log({ data });

  return (
    /* Banner section*/
    loading ? (
      <LoadingSpinner height={"200px"} />
    ) : (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Banner</h5>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Intro Text <em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("banner_text", e?.target?.value)
                      }
                      value={data?.banner_text}
                    />
                    <small id="error" className="error">
                      {err?.banner_text}
                    </small>
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Banner Video (Recommended Size: 1920px * 1080px){" "}
                      <em>*</em>
                    </label>
                    <MediaUploader
                      media={data.banner_video}
                      onUpload={(e) => {
                        singleFileSelectedHandler(
                          e,
                          "banner_video",
                          setSingleImageState,
                          "video"
                        );
                        setErr((prev) => ({
                          ...prev,
                          banner_video: "",
                        }));
                      }}
                      onRemove={() => {
                        removeImage("banner_video");
                        setErr((prev) => ({
                          ...prev,
                          banner_video: "Please upload a video banner",
                        }));
                      }}
                      uploadType={["mp4", "mov", "avi"]}
                    />
                   
                  </div>
                  <SupportedFormats
                    formats={["mp4", "mov", "avi"]}
                  />
                  
                    <small id="error" className="error">
                      {err?.banner_video}
                    </small>
                    <div className="mb-3">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("banner_video_alt", e?.target?.value)
                      }
                      value={data?.banner_video_alt}
                    />
                    <small id="error" className="error">
                      {err?.banner_video_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Feature icons sections */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Feature List</h5>
              <div className="row">
                {inputs?.features?.map((ele, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-4">
                      <div className="mb-3">
                        <FileUpload
                          style={{
                            marginRight: 5,
                            marginTop: 15,
                          }}
                          className="css-atc-esc5 image2 mt-3"
                          label={
                            <p>
                              Icon<em>*</em>
                            </p>
                          }
                          accept={["jpg", "jpeg", "png", "svg"]}
                          icon={"/assets/img/icons/plus_black.svg"}
                          closeIcon={
                            <AiOutlineClose
                              size={30}
                              className="remove-icon"
                              onClick={() => removeIcon(index, "features")}
                            />
                          }
                          onChange={(e, isInvalid, message, isClosing) => {
                            setErrors((s) => ({
                              ...s,
                              icon: "",
                            }));
                            singleFileUploadHandler(
                              e,
                              isInvalid,
                              message,
                              isClosing,
                              "icon",
                              inputDataChangeHandler,
                              index,
                              "features"
                            );
                          }}
                          error={errors[`${"features"}_${"icon"}_${index}`]}
                          width={28}
                          field={"icon"}
                          id="icon"
                          maxFileSizeMB={50}
                          multiple={false}
                          showPreview
                          files={ele?.icon}
                          inputSocket={formPlug}
                        // required={{message:"Please upload image"}}
                        />
                     
                      </div>
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png", "svg"]}
                  />
                    </div>
                    <div className="col-12 col-md-8 mt-3">
                      <Input
                        className="css-inp-esc1 mb-3"
                        label={`${index + 1}. Features Name`}
                        placeholder="Type here"
                        required={{ message: "Please enter Features name" }}
                        field={"name"}
                        value={ele?.name}
                        inputSocket={() => { }}
                        onChange={(e, key) => {
                          inputDataChangeHandler(key, e, index, "features");
                        }}
                        error={errors[`${"features"}_${"name"}_${index}`]}
                      />
                      <div className="mb-4">
                        <Input
                          className="css-inp-esc1 mb-3"
                          label={`${index + 1}. Alt Text`}
                          placeholder="Type here"
                          field={"icon_alt"}
                          value={ele?.icon_alt}
                          inputSocket={() => { }}
                          onChange={(e, key) => {
                            inputDataChangeHandler(key, e, index, "features");
                          }}
                        // error={
                        //   errors[`${"features"}_${"icon_alt"}_${index}`]
                        // }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          {/* About section */}

          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">About</h5>
              <div className="row">
                <div className="col-5">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 0,
                    }}
                    className="css-atc-esc5 mt-3 "
                    label="About Image (Recommended Size:567px * 585px) "
                    accept={["jpg", "jpeg", "png"]}
                    icon={"/assets/img/icons/plus_black.svg"}
                    closeIcon={
                      <AiOutlineClose
                        size={30}
                        className="remove-icon"
                        onClick={() => removeImage("about_image")}
                      />
                    }
                    onChange={(e, isInvalid, message, isClosing) => {
                      setErrors((s) => ({
                        ...s,
                        about_image: "",
                      }));
                      singleFileUploadHandler(
                        e,
                        isInvalid,
                        message,
                        isClosing,
                        "about_image"
                      );
                    }}
                    error={errors.about_image}
                    width={28}
                    field={"about_image"}
                    id="about_image"
                    maxFileSizeMB={50}
                    multiple={false}
                    showPreview
                    files={inputs?.about_image}
                    required={{ message: "Please upload image" }}
                    inputSocket={formPlug}
                  />
                       <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                  />
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("about_image_alt", e?.target?.value)
                      }
                      value={data?.about_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.about_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading <em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("about_title", e?.target?.value)
                      }
                      value={data?.about_title}
                    />
                    <small id="error" className="error">
                      {err?.about_title}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Short description <em>*</em>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("about_tagline", e?.target?.value)
                      }
                      value={data?.about_tagline}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.about_tagline}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* tagline section */}

          <CommonTitleCard
            MainHeading="Tagline"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"tagline_title"}
            fieldTagline={"tagline_description"}
          />

          {/* self drive tours */}

          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">
                Self Drive Tours{" "}
              </h5>
              <div className="row">
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading<em>*</em>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("self_drive_title", e?.target?.value)
                      }
                      value={data?.self_drive_title}
                    />
                    <small id="error" className="error">
                      {err?.self_drive_title}
                    </small>
                  </div>
                </div>

                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">
                      Short Description<em>*</em>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler(
                          "self_drive_description",
                          e?.target?.value
                        )
                      }
                      value={data?.self_drive_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.self_drive_description}
                    </small>
                  </div>
                </div>

                <div className="col-12 ">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5 mt-3"
                      label="Background Image (Recommended Size:630px * 1260px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("self_drive_image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          self_drive_image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "self_drive_image"
                        );
                      }}
                      error={errors.self_drive_image}
                      width={28}
                      field={"self_drive_image"}
                      id="self_drive_image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.self_drive_image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                  />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler(
                          "self_drive_image_alt",
                          e?.target?.value
                        )
                      }
                      value={data?.self_drive_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.self_drive_image_alt}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* popular packages  */}

          <CommonTitleCard
            MainHeading="Popular Packages"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"package_title"}
            fieldTagline={"package_tagline"}
          />

          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">{"Attractions"}</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("attraction_title", e?.target?.value)
                      }
                      value={data?.attraction_title}
                    />
                    <small id="error" className="error">
                      {err?.attraction_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Tag Line<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("attraction_tagline", e?.target?.value)
                      }
                      value={data?.attraction_tagline}
                    />
                    <small id="error" className="error">
                      {err?.attraction_tagline}
                    </small>
                  </div>
                </div>
              </div>
              <label className="form-label mb-3">
                Adding Attractions<em>*</em>
              </label>

              <div className="d-flex">
                <AttractionsDragAndDrop
                  data={data}
                  list={data?.attractions}
                  attractionPath={attractionPath}
                  handleDeleteAttraction={handleDeleteAttraction}
                  handleReorderAttraction={handleReorderAttraction}
                  handleOpenAttraction={handleOpenAttraction}
                />
              </div>

              {/* <div className="row row-cols-6">
                {Array.from({ length: 8 }).map((_, index) => (
                  <div className="col mb-3" key={index}>
                    <div
                      className="uploader-attractions mb-3"
                      style={{ position: "relative" }}
                    >
                      {data?.attractions?.[index]?._id ? (
                        <>
                          <img
                            src={`${IMAGE_BASE_URL}${attractionPath}/${data.attractions[index]?.image}`}
                            alt=""
                            height={"70%"}
                            width={"100%"}
                            style={{ display: "block" }}
                          />
                          <p className="text-center">
                            {data.attractions[index]?.title}
                          </p>
                          <AiOutlineDelete
                            size={20}
                            className="delete-icon"
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              cursor: "pointer",
                              color: "white",
                              padding: "3px",
                              background: "red",
                              borderRadius: "10px",
                            }}
                            onClick={() => handleDeleteAttraction(index)}
                          />
                        </>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "120px" }}
                        >
                          <AiOutlinePlus
                            size={30}
                            className="plus-icon"
                            onClick={() => handleOpenAttraction(index)}
                          />
                        </div>
                      )}

                      <div className="index_number">
                        <p>{index + 1}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>

          {/* why choose us     */}

          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Why Choose Us</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("why_choose_title", e?.target?.value)
                      }
                      value={data?.why_choose_title}
                    />
                    <small id="error" className="error">
                      {err?.why_choose_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Tag Line<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("why_choose_tagline", e?.target?.value)
                      }
                      value={data?.why_choose_tagline}
                    />
                    <small id="error" className="error">
                      {err?.why_choose_tagline}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      List<em>*</em>
                    </label>
                    <div className="row">
                      {data?.list?.length > 0 && (
                        <>
                          {data?.list?.map((item, index) => (
                            <div key={index} className="col-12 col-md-3 mb-3">
                              <div className="card card_layout">
                                <div className="card-body">
                                  <h6
                                    onClick={() => {
                                      handleListEdit(item, index);
                                    }}
                                  >
                                    {item.name}
                                  </h6>
                                  <AiOutlineClose
                                    size={20}
                                    className="remove-icon"
                                    onClick={() =>
                                      removeListData("list", index)
                                    }
                                  />
                                  {/* <MdModeEditOutline 
                                size={20}
                                className="edit-icon" 
                                onClick={editListData(item._id)}
                                /> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      <div className="col-12 col-md-3">
                        <div className="add_list mb-3">
                          <label className="upload-label">
                            <AiOutlinePlus
                              size={40}
                              className="plus-icon"
                              onClick={() => setOpenListModal(true)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Review section */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title border-style mb-3">Reviews</h5>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("review_title", e?.target?.value)
                      }
                      value={data?.review_title}
                    />
                    <small id="error" className="error">
                      {err?.review_title}
                    </small>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Tag Line<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("review_tagline", e?.target?.value)
                      }
                      value={data?.review_tagline}
                    />
                    <small id="error" className="error">
                      {err?.review_tagline}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Add Reviews<em>*</em>
                    </label>
                    <div className="row row-cols-4 mt-3">
                      {data?.reviews?.length > 0 && (
                        <>
                          {data?.reviews?.map((item, index) => (
                            <div key={index} className="col mb-3 pe-0">
                              <div className="card card_layout">
                                <div className="card-body">
                                  <h6>{item.review}</h6>
                                  <AiOutlineClose
                                    size={30}
                                    className="remove-icon"
                                    onClick={() =>
                                      removeListData("reviews", index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <div className="col-12 col-md-3">
                      <div className="add_list mb-3">
                        <label className="upload-label">
                          <AiOutlinePlus
                            size={40}
                            className="plus-icon"
                            onClick={handleOpenReviews}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* curated section */}
          <div className="card">
            <div className="card-body ">
              <h5 className="card-title border-style mb-3">Curated</h5>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="mb-3">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 0,
                      }}
                      className="css-atc-esc5 mt-3"
                      label="Curated Image (Recommended Size:668px * 342px)"
                      accept={["jpg", "jpeg", "png"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeImage("curated_image")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          curated_image: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "curated_image"
                        );
                      }}
                      error={errors.curated_image}
                      width={28}
                      field={"curated_image"}
                      id="curated_image"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={inputs?.curated_image}
                      required={{ message: "Please upload image" }}
                      inputSocket={formPlug}
                    />
                      <SupportedFormats
                    formats={["jpg", "jpeg", "png"]}
                  />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Type alt text "
                      onChange={(e) =>
                        onChangeHandler("curated_image_alt", e?.target?.value)
                      }
                      value={data?.curated_image_alt}
                    />
                    <small id="error" className="error">
                      {err?.curated_image_alt}
                    </small>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-3">
                    <label className="form-label">
                      Main Heading<em>*</em>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      rows={2}
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("curated_title", e?.target?.value)
                      }
                      value={data?.curated_title}
                    />
                    <small id="error" className="error">
                      {err?.curated_title}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Short description<em>*</em>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Type here"
                      onChange={(e) =>
                        onChangeHandler("curated_description", e?.target?.value)
                      }
                      value={data?.curated_description}
                    ></textarea>
                    <small id="error" className="error">
                      {err?.curated_description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* blog section */}
          <CommonTitleCard
            MainHeading="Blog"
            data={data}
            onChangeHandler={onChangeHandler}
            err={err}
            fieldHeading={"blog_title"}
            fieldTagline={"blog_tagline"}
          />
          <div className="card">
            <div className="card-body">
              <Seo data={data} onChangeHandler={onChangeHandler} />
            </div>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary me-3"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* attraction modal */}
        <Modal
          show={openAttractionModal}
          onHide={handleCloseAttraction}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="modal_design">
            <Modal.Title>Choose Attractions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-5 mt-4">
            <Select
              defaultValue={selectedAttractionOption}
              options={allAttractionList}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setSelectedAttractionOption(e);
                setErr({
                  ...err,
                  selectedAttractionOption: "",
                });
              }}
            />
            <small id="error" className="error">
              {err?.selectedAttractionOption}
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAddAttraction}>Add</Button>
            <Button onClick={handleCloseAttraction}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        {/* Why choose us modal */}
        <Modal
          show={openListModal}
          onHide={() => handleCloseListModal(data)}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="">
            <Modal.Title>
              {data?.list_edit ? "Update" : "Add"} why choose us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-4">
            <div className="col-12 ">
              <div className="mb-3">
                <label className="form-label">Title <em>*</em></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("name", e?.target?.value)}
                  value={data?.name}
                />
                <small id="error" className="error">
                  {err?.name}
                </small>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Short Description <em>*</em></label>
                <textarea
                  rows={4}
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) =>
                    onChangeHandler("description", e?.target?.value)
                  }
                  value={data?.description}
                ></textarea>
                <small id="error" className="error">
                  {err?.description}
                </small>
              </div>
            </div>
            <div className="col-12 ">
              <div>
                <div className="d-flex gap-3">
                  <FileUpload
                    style={{
                      marginRight: 5,
                      marginTop: 15,
                    }}
                    className="css-atc-esc7  mt-3 mb-3"
                    label="Icon"
                    accept={["svg", "jpg", "jpeg", "png"]}
                    icon={"/assets/img/icons/plus_black.svg"}
                    closeIcon={
                      <AiOutlineClose
                        size={30}
                        className="remove-icon"
                        onClick={() => removeImage("icon")}
                      />
                    }
                    onChange={(e, isInvalid, message, isClosing) => {
                      if (isInvalid) {
                        setErr((s) => ({
                          ...s,
                          icon: "invalid Image",
                        }));
                      } else {
                        fileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "icon",
                          setSingleImageState
                        );
                      }
                    }}
                    error={err?.icon}
                    width={28}
                    field={"icon"}
                    id="icon"
                    maxFileSizeMB={50}
                    multiple={false}
                    showPreview
                    files={data?.icon}
                    inputSocket={formPlug}
                  />
                </div>
                <SupportedFormats
                  formats={["svg", "jpg", "jpeg", "png"]}
                  maxSize={50}
                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="mb-5">
                {/* <label className="form-label">Icon</label>
                <IconUploader
                  media={data.icon}
                  onUpload={(e) =>
                    singleFileSelectedHandler(
                      e,
                      "icon",
                      setSingleImageState,
                      "image"
                    )
                  }
                  onRemove={() => removeImage("icon")}
                />
                <small id="error" className="error">
                  {err?.icon}
                </small> */}
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Type alt text "
                  onChange={(e) =>
                    onChangeHandler("icon_alt", e?.target?.value)
                  }
                  value={data?.icon_alt}
                />
                <small id="error" className="error">
                  {err?.icon_alt}
                </small>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDataList}>
              {data?.list_edit ? "Update" : "Add"}
            </Button>
            <Button onClick={() => handleCloseListModal(data)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        {/* review modal */}
        <Modal
          show={openReviewModal}
          onHide={handleCloseReview}
          centered
          backdrop="static"
          keyboard={false}
          className="modal-open"
        >
          <Modal.Header closeButton className="modal_design">
            <Modal.Title>Choose Reviews</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-5 mt-4">
            <Select
              defaultValue={selectedReviewOption}
              options={allReviewList}
              getOptionLabel={(option) => option.review}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setSelectedReviewOption(e);
                setErr({
                  ...err,
                  selectedReviewOption: "",
                });
              }}
            />
            <small id="error" className="error">
              {err?.selectedReviewOption}
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAddReview}>Add</Button>
            <Button onClick={handleCloseReview}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default Home;
