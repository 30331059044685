import { useNavigate } from "react-router-dom";
import { FaSlidersH } from "react-icons/fa";
import {
  createBooking,
  getAirportCodes,
  getManageBooking,
  getPackage,
  getPackageList,
  getStatusList,
  updateStatusBooking,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  status_list,
  booking_data,
  booking_page,
  airport_codes,
  package_list,
  add_booking_loading,
} from "../../redux/commonReducer";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import useFilter from "../../utils/Hooks/useFilter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/EDIT.svg";

import { Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";

import Select from "../../commonComponents/Select/Select";
import { DateConverter, ThirtyDayFromStartDate } from "../../utils/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import Button from "../../commonComponents/Button/Button";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DatePick from "../../commonComponents/DatePicker/DatePick";

const ManageEnquiries = () => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [matchedStatus, setMatchedStatus] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(booking_data);
  const pageData = useSelector(booking_page);
  const statusData = useSelector(status_list);
  const airportCodesList = useSelector(airport_codes);
  const packageList = useSelector(package_list);
  const addLoader = useSelector(add_booking_loading);

  const processedAirportCodesList = airportCodesList?.map((airport) => ({
    ...airport,
    airport_name: String(airport.airport_name),
    airport_code: String(airport.airport_code),
  }));

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updatedAt",
    sortValue: -1,
    keyword: "",
    customer_name: "",
    email: "",
    booking_ref_id: "",
    status: "",
    dateRange: [null, null],
  };

  const [showAddModal, setShowAddModal] = useState(false);

  const [addData, setAddData] = useState({
    departure: [],
    package: "",
    start_date: "",
    end_date: "",
  });
  const [guestData, setGuestData] = useState([
    {
      title: "Adults",
      ageRange: "Ages 12 or above",
      key: "adults",
      count: 1,
    },
    { title: "Children", ageRange: "Ages 5–12", key: "age_5_12", count: 0 },
    { title: "Children", ageRange: "Ages 2–5", key: "age_2_5", count: 0 },
    { title: "Infants", ageRange: "Under 2", key: "age_less_2", count: 0 },
  ]);
  const [err, setErr] = useState({});

  const maxEndDate = ThirtyDayFromStartDate(new Date(addData?.start_date));
  const startDate = ThirtyDayFromStartDate(new Date());

  useEffect(() => {
    let statusKey = {
      key: "booking_management",
    };
    dispatch(getStatusList(statusKey));
    dispatch(getPackageList());
  }, []);

  const [filterData, setFilterData] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "customer_name":
        filter = { ...filterData, customer_name: value, page: 0 };
        break;
      case "booking_ref_id":
        filter = { ...filterData, booking_ref_id: value, page: 0 };
        break;
      case "email":
        filter = { ...filterData, email: value, page: 0 };
        break;
      case "status":
        filter = { ...filterData, status: value, page: 0 };
        break;
      case "dateRange":
        filter = { ...filterData, dateRange: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;

    setFilterData(filter);

    if (
      key !== "submission_date" &&
      key !== "enquiry_type" &&
      key !== "status"
    ) {
      getSearchDataHandler(filter);
    }
  }

  const getStatusColor = (status) => {
    if (status === "New") return "#0a7af2";
    if (status === "In Progress") return "#f2d70a";
    if (status === "Engagement Completed") return "#e65f0b";
    if (status === "Cancel Booking") return "#e60b2c";
    if (status === "Passenger Info Updated") return "#6c757d";
    if (status === "Itinerary Updated") return "#8378e3";
    if (status === "Quotation Submitted") return "#0e196e";
    if (status === "Payment Completed") return "#094201";
    if (status === "Partial Payment Completed") return "#8ded80";
    if (status === "Trip Completed") return "#0bdb20";
    return "#6c757d";
  };

  const handleStatusChange = (index, status_name, statusId, dataId) => {
    setMatchedStatus({ [index]: status_name });
    const row = {
      id: dataId,
      status: statusId,
    };
    dispatch(
      updateStatusBooking(row, (res) => {
        if (res) {
          // getSearchDataHandler(filterData);
        }
      })
    );
  };

  const getSearchDataHandler = (filter) => {
    if (filter?.dateRange?.[0] && !filter?.dateRange?.[1]) {
      setErrors({ ...errors, dateRange: "End date is required." });
      return;
    } else {
      setErrors({ ...errors, dateRange: "" });
    }

    const enquiriesData = {
      page: filter.page,
      sort_order: filter.sortValue,
      perPage: filter.perPage,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
      is_pagination: "1",
      email: filter?.email,
      booking_ref_id: filter?.booking_ref_id,
      customer_name: filter?.customer_name,
      status: filter?.status?._id,
      start_date: filter?.dateRange?.[0]
        ? moment(filter.dateRange[0]).format("YYYY-MM-DD")
        : null,
      end_date: filter?.dateRange?.[1]
        ? moment(filter.dateRange[1]).format("YYYY-MM-DD")
        : null,
    };

    dispatch(getManageBooking(enquiriesData));
  };

  const columns = [
    { title: "Sl.No", key: "sl_no", width: "10px" },
    {
      title: "Booking Reference ID",
      key: "booking_ref_id",
      sort: true,
      width: "200px",
    },
    {
      title: "Date",
      key: "date",
      sort: true,
      width: "120px",
    },
    {
      title: "Customer Name",
      key: "customer_name",
      sort: true,
      width: "140px",
    },
    { title: "Phone ", key: "phone", sort: true, width: "120px" },
    {
      title: "Choosen Package",
      key: "choosen_package",
      sort: true,
      width: "160px",
    },
    {
      title: "Duration Of Travel",
      key: "duration",
      width: "80px",
      sort: true,
    },
    { title: "Status", key: "status", width: "50px" },
    { title: "Action", key: "action", width: "10px" },
  ];

  const content = data?.map((item, i) => {
    const status =
      matchedStatus?.[i] ||
      statusData?.find((s) => s._id === item.status)?.name;
    return {
      sl_no: filterData.page * filterData.perPage + (i + 1),
      booking_ref_id: item?.booking_ref_id,
      date: item?.created_date ? DateConverter(item?.created_date) : "N/A",
      customer_name: item?.traveller_info?.first_name
        ? item?.traveller_info?.first_name +
          " " +
          item?.traveller_info?.last_name
        : "--",
      phone: item?.traveller_info?.phone ?? "--",
      choosen_package: item?.bookingitineraries?.package_name,
      duration: item?.travel_duration,
      status: (
        <Dropdown>
          <Dropdown.Toggle
            style={{ backgroundColor: getStatusColor(status), color: "#fff" }}
          >
            {status}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {statusData
              ?.filter((s) => s._id !== item.status && s.name !== "New Lead")
              .map((s) => (
                <Dropdown.Item
                  key={s._id}
                  onClick={() => handleStatusChange(i, s.name, s._id, item._id)}
                >
                  {s.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      action: (
        <Actions
          buttons={{
            edit: EditIcon,
          }}
          onEdit={() => navigate(`/view-booking/${item._id}`)}
        />
      ),
    };
  });

  const handleSearch = (searchTerm) => {
    let raw = {
      keyword: searchTerm,
    };
    dispatch(getAirportCodes(raw));
  };

  const onChangeHandler = (key, value) => {
    setAddData({
      ...addData,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const CounterItem = ({ index, item, onCountChangeHandler, guestData }) => {
    const totalCount = guestData?.reduce(
      (sum, person) => sum + person.count,
      0
    );
    return (
      <div className="Item" key={index}>
        <div className="category">
          <h5>{item?.title}</h5>
          <p>{item?.ageRange}</p>
        </div>
        <div className="add-btn">
          <button
            type="button"
            onClick={() => onCountChangeHandler("sub", item, index)}
            disabled={item?.count === 0 || totalCount == 1}
          >
            <img src="/assets/img/icons/subtract-minus.svg" alt="Subtract" />
          </button>
          <span>{item?.count}</span>
          <button
            type="button"
            onClick={() => onCountChangeHandler("add", item, index)}
            disabled={item?.count === 9 || totalCount >= 9}
          >
            <img src="/assets/img/icons/add-plus.svg" alt="Add" />
          </button>
        </div>
      </div>
    );
  };

  const onCountChangeHandler = (type, item, index) => {
    let arr = [...guestData];
    let obj = { ...arr[index] };
    obj.count =
      type == "add"
        ? Number(item?.count) + 1
        : Number(item?.count) > 0
        ? Number(item?.count) - 1
        : 0;
    arr[index] = obj;
    setGuestData(arr);
  };

  const validate = () => {
    let temp = {};
    if (addData?.start_date == "") {
      temp = {
        ...temp,
        start_date: "Please select start date",
      };
    }
    if (addData?.end_date == "") {
      temp = {
        ...temp,
        end_date: "Please select end date",
      };
    }
    if (guestData?.reduce((sum, person) => sum + person.count, 0) == 0) {
      temp = {
        ...temp,
        guestData: "Please select person count",
      };
    }
    if (addData?.package == "") {
      temp = {
        ...temp,
        package: "Please select package",
      };
    }
    if (addData?.departure?.length == 0) {
      temp = {
        ...temp,
        departure: "Please select departure",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onAddHandler = () => {
    if (validate()) {
      let raw = {
        point_of_origin: addData?.departure?.[0]?._id ?? "",
        number_of_guest: {
          adults: guestData?.find((ele) => ele.key === "adults")?.count,
          age_5_12: guestData?.find((ele) => ele.key === "age_5_12")?.count,
          age_2_5: guestData?.find((ele) => ele.key === "age_2_5")?.count,
          age_less_2: guestData?.find((ele) => ele.key === "age_less_2")?.count,
        },
        from_date: addData?.start_date
          ? moment(addData?.start_date).format("YYYY-MM-DD")
          : "",
        to_date: addData?.end_date
          ? moment(addData?.end_date).format("YYYY-MM-DD")
          : "",
        package: addData?.package?._id,
        is_oneday_travel : addData.departure[0]?.is_oneday_travel,
      };
      console.log(raw);
      dispatch(
        createBooking(raw, () => {
          getSearchDataHandler(filterData);
          handleCloseAddModal();
        })
      );
    }
  };

  const handleCloseAddModal = () => {
    setAddData({
      departure: [],
      package: "",
      start_date: "",
      end_date: "",
    });
    setGuestData([
      {
        title: "Adults",
        ageRange: "Ages 12 or above",
        key: "adults",
        count: 1,
      },
      {
        title: "Children",
        ageRange: "Ages 5–12",
        key: "age_5_12",
        count: 0,
      },
      {
        title: "Children",
        ageRange: "Ages 2–5",
        key: "age_2_5",
        count: 0,
      },
      {
        title: "Infants",
        ageRange: "Under 2",
        key: "age_less_2",
        count: 0,
      },
    ]);
    setShowAddModal(false);
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="table__add border-style mb-3">
              <h5 className="card-title">Manage Bookings</h5>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAddModal(true)}
                >
                  Add Booking
                </button>
                <div className="mt-2">
                  <button className=" filter_btn">
                    <FaSlidersH onClick={() => setOpen(!open)} size={30} />
                  </button>
                </div>
              </div>
            </div>
            {open && (
              <div className="col">
                <div className="row row-cols-4 mb-4">
                  <div className="col pe-0">
                    <Input
                      value={filterData?.booking_ref_id}
                      type="text"
                      className="css-inp-esc2 search mb-1"
                      label="Booking Ref ID"
                      placeholder={"Type Here"}
                      field="booking_ref_id"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, booking_ref_id: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("booking_ref_id", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <Input
                      value={filterData?.customer_name}
                      type="text"
                      className="css-inp-esc2 search mb-1"
                      label="Customer Name"
                      placeholder={"Type Here"}
                      field="customer_name"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, customer_name: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("customer_name", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <Input
                      value={filterData?.email}
                      type="text"
                      className="css-inp-esc2 search mb-1"
                      label="Email"
                      placeholder={"Type Here"}
                      field="email"
                      showCloseBtn
                      onChange={(e) =>
                        setFilterData({ ...filterData, email: e })
                      }
                      onIconClick={(e) => {
                        filterHandler("email", e);
                      }}
                    />
                  </div>
                  <div className="col pe-0">
                    <label className="form-label">Booking Dates</label>
                    <DatePicker
                      selectsRange
                      startDate={filterData?.dateRange?.[0]}
                      endDate={filterData?.dateRange?.[1]}
                      onChange={(dates) => {
                        setFilterData({ ...filterData, dateRange: dates });
                        if (dates?.[0] && !dates?.[1]) {
                          setErrors({
                            ...errors,
                            dateRange: "End date is required.",
                          });
                        } else {
                          setErrors({ ...errors, dateRange: "" });
                        }
                      }}
                      className={`select_range ${
                        errors?.dateRange ? "is-invalid" : ""
                      }`}
                      placeholderText="Select Date Range"
                      dateFormat={"dd/MM/yyyy"}
                    />
                    {errors?.dateRange && (
                      <div className="error">{errors?.dateRange}</div>
                    )}
                  </div>

                  <div className="col pe-0 mt-1">
                    <label className="form-label ">Status</label>
                    <Select
                      className="css-slc-esc1 mb-3"
                      placeholder={"Select"}
                      options={statusData}
                      labelSetter={(o) => o?.name}
                      valueSetter={(option) => option?._id}
                      onChange={(e) =>
                        setFilterData({ ...filterData, status: e })
                      }
                      value={filterData.status}
                    />
                  </div>
                  <div className="col-2 preview-imagemargin pe-0 ">
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => filterHandler("searchBox", filterData)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => filterHandler("clearSearch")}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="search__head">
              <div className="count">
                <EntriesCounter
                  value={filterData.perPage}
                  onChange={(e) => filterHandler("entries", e)}
                />
              </div>
              <div className="search">
                <Input
                  value={filterData.keyword}
                  type="text"
                  className="css-inp-esc2 search"
                  placeholder={"Search Here"}
                  reverse
                  width="300px"
                  field="keyword"
                  showCloseBtn
                  onChange={(e) => setFilterData({ ...filterData, keyword: e })}
                  onSubmit={(e) => filterHandler("keyword", e)}
                  onIconClick={(e) => filterHandler("keyword", e)}
                />
              </div>
            </div>

            <Table
              titles={columns}
              content={content}
              onSort={(e) => filterHandler("sort", e)}
              page={filterData.page}
              pagesCount={pageData?.pages}
              pageStatus={pageData?.status}
              onPageChange={(e) => {
                filterHandler("page", e.selected);
              }}
              sortValue={{
                key: filterData.sortColumn,
                value: filterData.sortValue,
              }}
            />
          </div>
        </div>
      </div>
      <Modal show={showAddModal} setShow={() => handleCloseAddModal()}>
        <ModalContent header={"Add Booking"} className="add_booking">
          <div className="row">
            <div className="col-12 p-0">
              <Select
                title="Choose Package"
                className="css-slc-esc1 mb-3"
                placeholder={"Select"}
                options={packageList}
                labelSetter={(o) => o?.package_name}
                valueSetter={(option) => option._id}
                field={"package"}
                error={err.package}
                value={addData.package}
                onChange={(e) => onChangeHandler("package", e)}
                inputSocket={() => {}}
              />
            </div>
            <div className="col-12 p-0">
              <label className="form-label">Select departure</label>
              <AsyncTypeahead
                id="flight-search"
                labelKey={(option) =>
                  `${option.airport_name} (${option.airport_code})`
                }
                renderMenuItemChildren={(option) => (
                  <div>{`${option.airport_name} (${option.airport_code})`}</div>
                )}
                minLength={2}
                options={processedAirportCodesList} // Use preprocessed list
                onSearch={handleSearch}
                selected={addData?.departure}
                value={addData?.departure}
                onChange={(e) => onChangeHandler("departure", e)}
                placeholder="Flying from"
                className="flight_search mb-3"
              />
              <small id="error" className="error">
                {err?.departure}
              </small>
            </div>
            <div className="col-6 p-0">
              <DatePick
                className="css-dtp-esc2 mb-3"
                title={"Start Date"}
                placeholder="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                field={"date"}
                inputSocket={() => {}}
                selected={addData?.start_date}
                onChange={(date) => onChangeHandler("start_date", date)}
                minDate={startDate}
                endDate={addData?.end_date}
                error={err.start_date}
              />
            </div>
            <div className="col-6 pe-0">
              <DatePick
                className="css-dtp-esc2 mb-3"
                title={"End Date"}
                placeholder="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                field={"date"}
                inputSocket={() => {}}
                selected={addData?.end_date}
                onChange={(date) => onChangeHandler("end_date", date)}
                minDate={
                  addData?.start_date != "" ? addData?.start_date : startDate
                }
                startDate={addData?.start_date}
                maxDate={maxEndDate}
                disabled={addData?.start_date == ""}
                error={err.end_date}
              />
            </div>
            <div className="col-12">
              <div className="guests">
                {guestData.map((item, index) => (
                  <CounterItem
                    index={index}
                    item={item}
                    onCountChangeHandler={onCountChangeHandler}
                    guestData={guestData}
                  />
                ))}
              </div>
            </div>
          </div>
          <Button
            className="css-btn-esc3"
            content={"Confirm"}
            onClick={onAddHandler}
            disabled={addLoader}
            loader={addLoader}
          />
          <Button
            className="css-btn-esc3"
            content={"Cancel"}
            disabled={addLoader}
            loader={addLoader}
            onClick={handleCloseAddModal}
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManageEnquiries;
