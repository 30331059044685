import { useEffect, useState } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";
import SortableList from "../../commonComponents/sortFiles/SortableList";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleInfoDetail,
  insertVehicleInfo,
  updateVehicleInfo,
  uploadFile,
} from "../../redux/actionCreator";
import {
  detail_vehicle_loading,
  fileUploadLoader,
  update_vehicle_loading,
  vehicle_details,
  vehicle_path,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import MediaUploader from "../../components/media-uploader/MediaUploader";
import LoadingSpinner from "../../commonComponents/LoadingSpinner/LoadingSpinner";

const AddVehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const uploadLoading = useSelector(fileUploadLoader);
  const submitLoading = useSelector(update_vehicle_loading);
  const loading = useSelector(detail_vehicle_loading)
  const details = useSelector(vehicle_details);
  const path = useSelector(vehicle_path);

  const [data, setData] = useState({
    title: "",
    is_package_cost_included: "",
    image: [],
  });
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [delFile, setDelFile] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getVehicleInfoDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        is_package_cost_included: details?.is_package_cost_included,
        image_alt: details?.image_alt,
        image: details?.image
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.image}`,
              file: null,
              image: details?.image,
            }
          : "",
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  // const handleDelete = (file, field) => {
  //   console.log(file);
  //   let temp = [...data?.[field]];
  //   if (temp?.length) {
  //     temp = temp?.filter((ele) => ele?.id !== file?.id);
  //     setData({
  //       ...data,
  //       [field]: temp,
  //     });
  //     setDelFile([...delFile, file?.response]);
  //   }
  // };

  // const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
  //   if (isInvalid) {
  //     setInvalidUpload(true);
  //     setErr({
  //       ...err,
  //       [field]: message,
  //     });
  //   } else {
  //     setInvalidUpload(false);
  //     setErr({
  //       ...err,
  //       [field]: "",
  //     });
  //     if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
  //       setImageLoader(true);
  //       let formData = new FormData();
  //       if (trackId?.length == 0) {
  //         let track_id = Math.floor(1000 + Math.random() * 9000).toString();
  //         setTrackId(track_id);
  //         formData.append("track_id", track_id);
  //       } else {
  //         formData.append("track_id", trackId);
  //       }
  //       e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
  //         formData.append(`file`, item?.metaFile)
  //       );
  //       dispatch(
  //         uploadFile(
  //           formData,
  //           (res) => {
  //             let oldFiles = e?.filter((el) => el?.metaFile == undefined);
  //             let arr = e
  //               ?.filter((el) => el?.metaFile != undefined)
  //               ?.map((ele, i) => {
  //                 return {
  //                   // ...ele,
  //                   url: ele?.url,
  //                   name: ele?.name,
  //                   response: res?.data[i],
  //                   image: res?.data[i],
  //                   id: res?.data[i] + i + 1,
  //                   image_alt: "",
  //                 };
  //               });
  //             setErr({
  //               ...err,
  //               [field]: "",
  //             });
  //             setData((s) => ({
  //               ...s,
  //               [field]: [...oldFiles, ...arr],
  //             }));
  //             setImageLoader(false);
  //           },
  //           (progress) => {
  //             setAddAttachmentProgress(progress);
  //           }
  //         )
  //       );
  //     }
  //   }
  // };
  // const altChangeHandler = (field, value, index) => {
  //   let dataList = { ...data };
  //   dataList.image[index][field] = value;
  //   setData(dataList);
  //   setErr({
  //     ...err,
  //     [`${field}_${index}`]: "",
  //   });
  // };

  const handleImageUpload = (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      setData((prevState) => ({
        ...prevState,
        image_alt: prevState.title || "",
        [type]: { file, preview: URL.createObjectURL(file) },
      }));
    }
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const validate = () => {
    let temp = {};
    if (!data?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter title",
      };
    }
    // if (!data?.image_alt?.trim()?.length) {
    //   temp = {
    //     ...temp,
    //     image_alt: "Please enter alt text",
    //   };
    // }
    if (data?.image == "") {
      temp = {
        ...temp,
        image: "Please upload image",
      };
    }
    if (data?.is_package_cost_included == "") {
      temp = {
        ...temp,
        is_package_cost_included: "Please choose package cost included value",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    
    if (validate()) {
      let formData = new FormData();
      formData.append("title", data?.title?.trim());
      formData.append("image_alt", data?.image_alt?.trim());
      formData.append(
        "is_package_cost_included",
        data?.is_package_cost_included
      );
      formData.append("image", data?.image?.file ?? data?.image?.image);
      console.log(formData);
      if (id) {
        formData.append("id", id);
        dispatch(
          updateVehicleInfo(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertVehicleInfo(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      is_package_cost_included: "",
      image: [],
    });
    navigate("/vehicle-info");
  };

  return (
    loading? (<LoadingSpinner height={"200px"}/>):
    (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title border-style mb-3">Add Vehicle</h5>
          <div className="row mt-3">
            <div className="col-6">
              <div className="mb-3">
                <label className="form-label">Add Vehicle Name <em>*</em></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("title", e?.target?.value)}
                  value={data?.title}
                />
                <small id="error" className="error">
                  {err?.title}
                </small>
              </div>
            </div>
            <div className="col-6">
              <label className="form-label">Included in package cost <em>*</em></label>
              <fieldset className="row mb-3">
                <div
                  className="col-sm-10 d-flex gap-10"
                  style={{ gap: "20px" }}
                >
                  <Form.Check
                    type={"radio"}
                    id={`default-radio`}
                    label={"Yes"}
                    onClick={(e) =>
                      onChangeHandler(
                        "is_package_cost_included",
                        e.target.value
                      )
                    }
                    value={"1"}
                    checked={data?.is_package_cost_included == "1"}
                  />
                  <Form.Check
                    type={"radio"}
                    id={`default-radio`}
                    label={"No"}
                    onClick={(e) =>
                      onChangeHandler(
                        "is_package_cost_included",
                        e.target.value
                      )
                    }
                    value={"0"}
                    checked={data?.is_package_cost_included == "0"}
                  />
                </div>
                <small id="error" className="error">
                  {err?.is_package_cost_included}
                </small>
              </fieldset>
            </div>

            {/* <div>
            <div className="d-flex gap-3">
              <FileUpload
                style={{
                  marginRight: 5,
                  marginTop: 15,
                }}
                className="css-atc-esc2  mt-3"
                label="Add Vehicle Image"
                noLabel
                buttonLabel="Choose File"
                accept={["jpg", "jpeg", "png", "svg"]}
                onChange={(e, isInvalid, message, isClosing) => {
                  setErr((s) => ({
                    ...s,
                    image: "",
                  }));
                  fileSelectedHandler(
                    e,
                    isInvalid,
                    message,
                    isClosing,
                    "image"
                  );
                }}
                field={"image"}
                loader={imageLoader}
                error={err.image}
                id="image"
                maxFileSizeMB={100}
                multiple={false}
                files={data?.image}
                inputSocket={() => {}}
              />
              <ProgressBar progress={addAttachmentProgress} width={350} />
            </div>
            <SupportedFormats
              formats={["jpg", "jpeg", "png", "svg"]}
              maxSize={100}
            />
          </div>
          {data?.image?.length ? (
            <SortableList
              setInputs={setData}
              inputs={data}
              field="image"
              handleDelete={handleDelete}
              randomTrackID={trackId ?? ""}
              err={err}
              altChangeHandler={altChangeHandler}
            />
          ) : (
            <></>
          )} */}
            <div className="col-6">
              <div className="mb-3">
                <label className="form-label">Add Vehicle Image (Recommended Size:413px * 243px) <em>*</em></label>
                <MediaUploader
                  media={data.image}
                  onUpload={(e) => handleImageUpload(e, "image")}
                  onRemove={() => removeImage("image")}
                  uploadType = {["jpg","jpeg","png","svg"]}
                />
                <small id="error" className="error">
                  {err?.image}
                </small>
              </div>
              <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label className="form-label">Add Alt Text</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  onChange={(e) => onChangeHandler("image_alt", e?.target?.value)}
                  value={data?.image_alt}
                />
                <small id="error" className="error">
                  {err?.image_alt}
                </small>
              </div>
            </div>
            <div className="col-12 mt-5">
              <button
                type="submit"
                className="btn btn-primary m-2"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <Link to="/vehicle-info">
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AddVehicle;
