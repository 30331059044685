import React, { useCallback, useEffect, useState } from "react";
import MediaUploader from "../components/media-uploader/MediaUploader";
import IconUploader from "../components/media-uploader/IconUploader";
import PackageFooter from "../components/PackageFooter";
import Input from "../commonComponents/Input/Input";
import useForm from "../utils/Hooks/useForm";
import TextArea from "../commonComponents/TextArea/TextArea";
import {
  getPackagePageDetails,
  insertPackagePage,
  uploadFile,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import { AiOutlineClose } from "react-icons/ai";
import { package_page_path, packagepage_details } from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";
import { MdEdgesensorHigh } from "react-icons/md";
import SupportedFormats from "../commonComponents/SupportedFormats/SupportedFormats";

const PackagePage = () => {
  const [trackId, setTrackId] = useState("");
  const [invalidUpload, setInvalidUpload] = useState(false);
  const dispatch = useDispatch();
  const details = useSelector(packagepage_details);
  const path = useSelector(package_page_path);

  useEffect(() => {
    dispatch(getPackagePageDetails());
  }, []);

  useEffect(() => {
    if (details) {
      let result = {
        banner_title: details?.banner_title || "",
        banner_description: details?.banner_description || "",
        banner_image_alt: details?.banner_image_alt || "",

        search_content: details?.search_content || "",
        banner_image: details?.banner_image
          ? [
            {
              type: details?.banner_image.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.banner_image}`,
              name: details?.banner_image,
              response: details?.banner_image,
            },
          ]
          : "",
        footer_logo: details?.footer_logo
          ? [
            {
              type: details?.footer_logo.split(".").pop(),
              url: `${IMAGE_BASE_URL}${path}/${details?.footer_logo}`,
              name: details?.footer_logo,
              response: details?.footer_logo,
            },
          ]
          : "",
        footer_logo_alt: details?.footer_logo_alt || "",
        package_footer_title: details?.package_footer_title || "",
        package_short_title: details?.package_short_title || "",
        package_short_description: details?.package_short_description || "",
        flight_footer_title: details?.flight_footer_title || "",
        flight_short_description: details?.flight_short_description || "",
        flight_short_title: details?.flight_short_title || "",
        tool_tip: details?.tool_tip || ""
      };

      if (details?.package_included?.length) {
        const packagePageArr = details.package_included.map((ele) => ({
          icon: ele?.icon
            ? [
              {
                type: ele?.icon.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                name: ele?.icon,
                response: ele?.icon,
              },
            ]
            : "",
          icon_alt: ele?.icon_alt || "",
          title: ele?.title || "",
        }));

        result = {
          ...result,
          package_included: packagePageArr,
        };
      }

      if (details?.package_footer_list?.length) {
        const packagePageArr = details.package_footer_list.map((ele) => ({
          icon: ele?.icon
            ? [
              {
                type: ele?.icon.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                name: ele?.icon,
                response: ele?.icon,
              },
            ]
            : "",
          icon_alt: ele?.icon_alt || "",
          title: ele?.title || "",
          description: ele?.description || "",
        }));

        result = {
          ...result,
          package_footer_list: packagePageArr,
        };
      }

      if (details?.what_is_next?.length) {
        const packagePageArr = details?.what_is_next?.map((ele) => ({
          icon: ele?.icon
            ? [
              {
                type: ele?.icon.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                name: ele?.icon,
                response: ele?.icon,
              },
            ]
            : "",
          icon_alt: ele?.icon_alt || "",
          title: ele?.title || "",
          description: ele?.description || "",
        }));

        result = {
          ...result,
          what_is_next: packagePageArr,
        };
      }

      if (details?.flight_footer_list?.length) {
        const packagePageArr = details.flight_footer_list.map((ele) => ({
          icon: ele?.icon
            ? [
              {
                type: ele?.icon.split(".").pop(),
                url: `${IMAGE_BASE_URL}${path}/${ele?.icon}`,
                name: ele?.icon,
                response: ele?.icon,
              },
            ]
            : "",
          icon_alt: ele?.icon_alt || "",
          title: ele?.title || "",
          description: ele?.description || "",
        }));

        result = {
          ...result,
          flight_footer_list: packagePageArr,
        };
      }

      setInputs({ ...result });
    }
  }, [details, path]);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({
    package_included: [
      {
        title: "",
        icon_alt: "",
        icon: "",
      },
    ],
    what_is_next: [
      {
        title: "",
        icon_alt: "",
        icon: "",
        description: "",
      },
    ],

    package_footer_list: [
      {
        title: "",
        icon_alt: "",
        icon: "",
        description: "",
      },
    ],
    flight_footer_list: [
      {
        title: "",
        icon_alt: "",
        icon: "",
        description: "",
      },
    ],
  });

  const packageIncludeValidator = (error) => {
    let temp = {};
    if (inputs?.package_included?.length) {
      inputs?.package_included?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"package_included"}_${"icon"}_${i}`] = "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"package_included"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.title?.trim()?.length) {
          temp[`${"package_included"}_${"title"}_${i}`] = "Please enter title";
        }
      });
    }

    if (Object.values(temp).length !== 0) {
      temp = { ...temp, ...error };
      return temp;
    } else {
      setErrors({ ...error });
      return error;
    }
  };

  const packageFooterValidator = (error) => {
    let temp = {};

    if (inputs?.package_footer_list?.length) {
      inputs?.package_footer_list?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"package_footer_list"}_${"icon"}_${i}`] =
            "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"package_footer_list"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.title?.trim()?.length) {
          temp[`${"package_footer_list"}_${"title"}_${i}`] =
            "Please enter title";
        }
        if (!ele?.description?.trim()?.length) {
          temp[`${"package_footer_list"}_${"description"}_${i}`] =
            "Please enter description";
        }
      });
    }

    if (Object.values(temp).length !== 0) {
      temp = { ...temp, ...error };
      return temp;
    } else {
      setErrors({ ...error });
      return error;
    }
  };
  const whatIsNextValidator = (error) => {
    let temp = {};

    if (inputs?.what_is_next?.length) {
      inputs?.what_is_next?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"what_is_next"}_${"icon"}_${i}`] =
            "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"package_footer_list"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.title?.trim()?.length) {
          temp[`${"what_is_next"}_${"title"}_${i}`] =
            "Please enter title";
        }
        if (!ele?.description?.trim()?.length) {
          temp[`${"what_is_next"}_${"description"}_${i}`] =
            "Please enter description";
        }
      });
    }

    if (Object.values(temp).length !== 0) {
      temp = { ...temp, ...error };
      return temp;
    } else {
      setErrors({ ...error });
      return error;
    }
  };


  const flightFooterValidator = (error) => {
    let temp = {};

    if (inputs?.flight_footer_list?.length) {
      inputs?.flight_footer_list?.forEach((ele, i) => {
        if (ele?.icon === "") {
          temp[`${"flight_footer_list"}_${"icon"}_${i}`] = "Please upload icon";
        }
        // if (!ele?.icon_alt?.trim()?.length) {
        //   temp[`${"flight_footer_list"}_${"icon_alt"}_${i}`] =
        //     "Please enter alt text";
        // }
        if (!ele?.title?.trim()?.length) {
          temp[`${"flight_footer_list"}_${"title"}_${i}`] =
            "Please enter title";
        }
        if (!ele?.description?.trim()?.length) {
          temp[`${"flight_footer_list"}_${"description"}_${i}`] =
            "Please enter description";
        }
      });
    }

    if (Object.values(temp).length != 0) {
      temp = { ...temp, ...error };
      return temp;
    } else {
      setErrors({ ...error });
      return error;
    }
  };

  const inputDataChangeHandler = (key, value, index, field) => {
    let dataList = { ...inputs };
    dataList[field][index][key] = value;
    setInputs(dataList);
    setErrors({
      ...errors,
      [`${field}_${key}_${index}`]: "",
    });
    console.log(inputs);
  };

  const handleRemoveField = (index, type) => {
    setInputs((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));
  };

  const handleAddField = useCallback((type) => {
    setInputs((prev) => {
      if (type === "package_included") {
        return {
          ...prev,
          package_included: [
            ...prev.package_included,
            { title: "", icon_alt: "", icon: "" },
          ],
        };
      } else {
        return {
          ...prev,
          [type]: [
            ...(prev[type] || []),
            { title: "", icon_alt: "", icon: "", description: "" },
          ],
        };
      }
    });
  }, []);

  const removeImage = (type) => {
    setInputs((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const removeIcon = (index, type) => {
    setInputs((prev) => {
      const updatedMedia = prev[type].map((item, idx) =>
        idx === index ? { ...item, icon: "" } : item
      );
      return {
        ...prev,
        [type]: updatedMedia,
      };
    });
  };

  const singleFileUploadHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    mainField
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        formData.append(`path`, "cms");
        dispatch(
          uploadFile(formData, (res) => {
            let tempValue = [
              {
                type: e[0].type,
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ];
            if (field === "icon") {
              setFunction(field, tempValue, index, mainField);
            } else {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: tempValue,
              }));
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setInputs({});
    setErrors({});
    dispatch(getPackagePageDetails());
  };
  console.log("errors", errors);

  const onSubmitHandler = async () => {
    let isValidate1 = false;

    let isValidated = await validate({
      returnErrors: true,
    });

    let temp = {
      ...isValidated.err,
      ...packageIncludeValidator(),
      ...flightFooterValidator(),
      ...packageFooterValidator(),
      ...whatIsNextValidator()
    };
    console.log(temp);

    if (Object.values(temp).every((value) => value === "")) {
      isValidate1 = true;
    } else {
      setErrors({ ...temp });
    }

    console.log(isValidated?.valid, !invalidUpload, isValidate1);
    if (isValidated?.valid && !invalidUpload && isValidate1) {
      finalSubmitHandler();
    }
  };
  const finalSubmitHandler = () => {
    let raw = {
      banner_title: inputs?.banner_title,
      banner_description: inputs?.banner_description,
      banner_image: inputs?.banner_image?.[0]?.response ?? "",
      banner_image_alt: inputs?.banner_image_alt,
      search_content: inputs?.search_content,
      package_included: inputs.package_included.map((item) => ({
        icon: item.icon?.[0]?.response ?? "",
        icon_alt: item.icon_alt,
        title: item.title,
      })),
      package_footer_list: inputs?.package_footer_list.map((item) => ({
        icon: item.icon?.[0]?.response ?? "",
        icon_alt: item.icon_alt,
        title: item.title,
        description: item.description,
      })),
      flight_footer_list: inputs?.flight_footer_list.map((item) => ({
        icon: item.icon?.[0]?.response ?? "",
        icon_alt: item.icon_alt,
        title: item.title,
        description: item.description,
      })),
      what_is_next: inputs?.what_is_next.map((item) => ({
        icon: item.icon?.[0]?.response ?? "",
        icon_alt: item.icon_alt,
        title: item.title,
        description: item.description,
      })),
      flight_footer_title: inputs?.flight_footer_title,
      flight_short_description: inputs?.flight_short_description,
      flight_short_title: inputs?.flight_short_title,
      package_footer_title: inputs?.package_footer_title,
      package_short_description: inputs?.package_short_description,
      package_short_title: inputs?.package_short_title,
      tool_tip :inputs?.tool_tip,
      track_id: trackId,
      path: "cms"
    };
    console.log(raw);
    dispatch(
      insertPackagePage(raw, (res) => {
        if (res) {
          onCancelHandler();
        }
      })
    );
  };
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Banner Section</h5>

            <div className="row">
              <div className="col-12">
                <Input
                  className="css-inp-esc1 mb-3"
                  label=" Title"
                  placeholder="Type here"
                  required={{ message: "Please Enter Title" }}
                  field={"banner_title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>
              <div className="col-12  mb-3">
                <TextArea
                  label={"Short Description"}
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  field={"banner_description"}
                  value={inputs.banner_description}
                  error={errors.banner_description}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{ message: "Please enter comment" }}
                />
              </div>

              <div className="col-12 col-md-4 mb-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                  }}
                  className="css-atc-esc5 "
                  label="Banner Image (Recommended Size:1920px * 630px)"
                  accept={["jpg", "jpeg", "png"]}
                  icon={"/assets/img/icons/plus_black.svg"}
                  closeIcon={
                    <AiOutlineClose
                      size={30}
                      className="remove-icon"
                      onClick={() => removeImage("banner_image")}
                    />
                  }
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      banner_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "banner_image"
                    );
                  }}
                  error={errors.banner_image}
                  width={28}
                  field={"banner_image"}
                  id="banner_image"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.banner_image}
                  required={{ message: "Please Upload Image" }}
                  inputSocket={formPlug}
                />
                <SupportedFormats
                      formats={["jpg", "jpeg", "png"]}
                    />
              </div>
              <div className="col-12 col-md-8 mb-3">
                <Input
                  className="css-inp-esc1 mb-3"
                  label=" Image Alt"
                  placeholder="Type here"
                  // required={{ message: "Please Enter Image Alt" }}
                  field={"banner_image_alt"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              <div className="col-12  mb-3">
                <TextArea
                  label={"Search Content"}
                  className={"css-txt-esc1"}
                  placeholder={"Type here"}
                  field={"search_content"}
                  value={inputs.search_content}
                  error={errors.search_content}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                  required={{ message: "Please Enter Short Content" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Package Included </h5>
            <div className="row">
              {inputs?.package_included?.length>0 ? (
              inputs?.package_included?.map((ele, index) => (
                <div key={ele._id} className="row mb-2  align-items-center">
                  <div className="col-4">
                    <FileUpload
                      style={{
                        marginRight: 5,
                        marginTop: 15,
                      }}
                      className="css-atc-esc5 image2 mt-3"
                      label={<p>Icon<em>*</em></p>}
                      accept={["jpg", "jpeg", "png", "svg"]}
                      icon={"/assets/img/icons/plus_black.svg"}
                      closeIcon={
                        <AiOutlineClose
                          size={30}
                          className="remove-icon"
                          onClick={() => removeIcon(index, "package_included")}
                        />
                      }
                      onChange={(e, isInvalid, message, isClosing) => {
                        setErrors((s) => ({
                          ...s,
                          icon: "",
                        }));
                        singleFileUploadHandler(
                          e,
                          isInvalid,
                          message,
                          isClosing,
                          "icon",
                          inputDataChangeHandler,
                          index,
                          "package_included"
                        );
                      }}
                      error={errors[`${"package_included"}_${"icon"}_${index}`]}
                      width={28}
                      field={"icon"}
                      id="icon"
                      maxFileSizeMB={50}
                      multiple={false}
                      showPreview
                      files={ele?.icon}
                      inputSocket={formPlug}
                    // required={{message:"Please upload image"}}
                    />
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                  </div>
                  <div className="col-12 ">
                    <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1}. Title`}
                      placeholder="Type here"
                      // required={{ message: "Please enter Title" }}
                      field={"title"}
                      value={ele?.title}
                      inputSocket={() => { }}
                      onChange={(e, key) => {
                        inputDataChangeHandler(
                          key,
                          e,
                          index,
                          "package_included"
                        );
                      }}
                      error={
                        errors[`${"package_included"}_${"title"}_${index}`]
                      }
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      className="css-inp-esc1 mb-3"
                      label={`${index + 1}. Icon Alt`}
                      placeholder="Type here"

                      field={"icon_alt"}
                      inputSocket={() => { }}
                      value={ele?.icon_alt}
                      onChange={(e, key) => {
                        inputDataChangeHandler(
                          key,
                          e,
                          index,
                          "package_included"
                        );
                      }}
                      error={
                        errors[`${"package_included"}_${"icon_alt"}_${index}`]
                      }
                    />
                  </div>
                  <div className=" col-12 d-flex justify-content-end ">
                    {index === inputs.package_included.length - 1 ? (
                      <button
                        className="btn btn-primary "
                        onClick={() => handleAddField("package_included")}
                      >
                        Add
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btn btn-danger ms-3"
                      onClick={() =>
                        handleRemoveField(index, "package_included")
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            ):(
              <div className="col-4">
                <button
                  className="btn btn-primary "
                  style={{"width":"150px"}}
                  onClick={() => handleAddField("package_included")}
                >
                  Add
                </button>
                </div>
            )}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <PackageFooter
              header_title={"Package Page Footer"}
              title={"package_footer_title"}
              short_description={"package_short_description"}
              short_title={"package_short_title"}
              errors={errors}
              inputs={inputs}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              field={"package_footer_list"}
              data={inputs?.package_footer_list}
              inputDataChangeHandler={inputDataChangeHandler}
              inputHandler={inputHandler}
              formPlug={formPlug}
              setErrors={setErrors}
              singleFileUploadHandler={singleFileUploadHandler}
              removeIcon={removeIcon}
            />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <PackageFooter
              header_title={"Flight Page Footer"}
              title={"flight_footer_title"}
              short_description={"flight_short_description"}
              short_title={"flight_short_title"}
              errors={errors}
              inputs={inputs}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              field={"flight_footer_list"}
              data={inputs?.flight_footer_list}
              inputDataChangeHandler={inputDataChangeHandler}
              inputHandler={inputHandler}
              formPlug={formPlug}
              setErrors={setErrors}
              singleFileUploadHandler={singleFileUploadHandler}
              removeIcon={removeIcon}
            />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Tool Tip Info </h5>
            <div className="col-12">
              <Input
                className="css-inp-esc1 mb-3"
                label=" Package Information Tool tip"
                placeholder="Type here"
                required={{ message: "Please Enter field" }}
                field={"tool_tip"}
                value={inputs}
                error={errors}
                inputSocket={formPlug}
                onChange={inputHandler}
              />
            </div>
          </div>
        </div>


        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">What Is Next (Payment Successfull Page) </h5>
            <div className="row">
              {inputs?.what_is_next?.length > 0 ? (
                inputs.what_is_next.map((ele, index) => (
                  <div key={ele._id} className="row mb-2 align-items-center">
                    <div className="col-4">
                      <FileUpload
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                        }}
                        className="css-atc-esc5 image2 mt-3"
                        label={<p>Icon <em>*</em></p>}
                        accept={["jpg", "jpeg", "png", "svg"]}
                        icon={"/assets/img/icons/plus_black.svg"}
                        closeIcon={
                          <AiOutlineClose
                            size={30}
                            className="remove-icon"
                            onClick={() => removeIcon(index, "what_is_next")}
                          />
                        }
                        onChange={(e, isInvalid, message, isClosing) => {
                          setErrors((s) => ({
                            ...s,
                            icon: "",
                          }));
                          singleFileUploadHandler(
                            e,
                            isInvalid,
                            message,
                            isClosing,
                            "icon",
                            inputDataChangeHandler,
                            index,
                            "what_is_next"
                          );
                        }}
                        error={errors[`what_is_next_icon_${index}`]}
                        width={28}
                        field={"icon"}
                        id="icon"
                        maxFileSizeMB={50}
                        multiple={false}
                        showPreview
                        files={ele?.icon}
                        inputSocket={formPlug}
                      />
                      <SupportedFormats
                      formats={["jpg", "jpeg", "png","svg"]}
                    />
                    </div>
                    <div className="col-12">
                      <Input
                        className="css-inp-esc1 mb-3"
                        label={`${index + 1}. Title`}
                        placeholder="Type here"
                        required={{ message: "Please enter Title" }}
                        field={"title"}
                        value={ele?.title}
                        inputSocket={() => { }}
                        onChange={(e, key) => {
                          inputDataChangeHandler(key, e, index, "what_is_next");
                        }}
                        error={errors[`what_is_next_title_${index}`]}
                      />
                    </div>
                    <div className="col-12">
                      <Input
                        className="css-inp-esc1 mb-3"
                        label={`${index + 1}. Icon Alt`}
                        placeholder="Type here"
                        field={"icon_alt"}
                        inputSocket={() => { }}
                        value={ele?.icon_alt}
                        onChange={(e, key) => {
                          inputDataChangeHandler(key, e, index, "what_is_next");
                        }}
                        error={errors[`what_is_next_icon_alt_${index}`]}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <TextArea
                        label={<p>Short Description <em>*</em></p>}
                        className={"css-txt-esc1"}
                        placeholder={"Type here"}
                        
                        field={"description"}
                        inputSocket={formPlug}
                        value={ele?.description}
                        onChange={(e, key) => {
                          inputDataChangeHandler(key, e, index, "what_is_next");
                        }}
                        error={errors[`what_is_next_description_${index}`]}
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      {index === inputs.what_is_next.length - 1 ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddField("what_is_next")}
                          disabled={inputs.what_is_next.length > 4}
                        >
                          Add
                        </button>
                      ) : null}
                      <button
                        className="btn btn-danger ms-3"
                        onClick={() => handleRemoveField(index, "what_is_next")}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-4">
                <button
                  className="btn btn-primary "
                  style={{"width":"150px"}}
                  onClick={() => handleAddField("what_is_next")}
                >
                  Add
                </button>
                </div>
              )}
            </div>

          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-3"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
